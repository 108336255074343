import { serverUrl } from './helpers'
const { v4: uuidv4 } = require('uuid')

const uniq = (x) => {
  return [...new Set(x)]
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const setGetCookie = () => {
  let newUuid = getCookie('russwar_uuid')
  if (window.__clipara?.noCookies) {
    if (window.__clipara?.uuid) {
      return window.__clipara?.uuid
    }
    newUuid = uuidv4()
    window.__clipara.uuid = newUuid
    return newUuid
  }

  if (!newUuid) {
    newUuid = uuidv4()
    const expiry = new Date()
    expiry.setMonth(new Date().getMonth() + 6)
    document.cookie = `russwar_uuid=${newUuid}; expires=${expiry.toUTCString()}`
  }
  return newUuid
}

const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
export const deviceType = screenWidth < 600 ? 'MOBILE' : screenWidth >= 1050 ? 'DESKTOP' : 'TABLET'

let itemsViewed = []
let productsAddedToCart = []
let campaignsViewed = []
let videosViewed = []

export const trackEvent = async (payload) => {
  const uuid = setGetCookie()
  if (payload.type === 'PLAY' && window.Shopify) {
    try {
      itemsViewed = uniq(itemsViewed.concat(payload.productIds))
      campaignsViewed = uniq(campaignsViewed.concat(payload.campaignId))
      videosViewed = uniq(videosViewed.concat(payload.videoId))
      fetch(window.Shopify.routes.root + 'cart/update.js', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          attributes: {
            clipara_views: itemsViewed.join(','),
            clipara_id: uuid,
            clipara_campaigns_viewed: campaignsViewed.join(','),
            clipara_videos_viewed: videosViewed.join(',')
          }
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  if (payload.type === 'ADD_TO_CART' && window.Shopify) {
    try {
      productsAddedToCart = uniq(productsAddedToCart.concat(payload.productIds))
      fetch(window.Shopify.routes.root + 'cart/update.js', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          attributes: {
            clipara_add_to_cart: productsAddedToCart.join(',')
          }
        })
      })
    } catch (e) {
      console.log(e)
    }
  }
  return fetch(`${serverUrl}/tracking/event`, {
    method: 'POST',
    body: JSON.stringify({ ...payload, uuid, deviceType })
  })
}

export const gaEvent = async ({ isGaEnabled, name, data }) => {
  if (!isGaEnabled) {
    return
  }
  try {
    if (window.gtag) {
      return window.gtag('event', name, data)
    }
    if (window.dataLayer) {
      return window.dataLayer.push({
        event: name,
        ...data
      })
    }
    if (window.ga) {
      return window.ga('send', name)
    }
  } catch (e) {
    console.log(e)
  }
}
