import React from 'react'
import styled from 'styled-components'

const StyledSvg = styled.svg`
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : '')}
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  animation: spin 1.5s ease-in-out infinite;
`

const Spinner = ({ zIndex }) => (
  <StyledSvg
    zIndex={zIndex}
    id="myspinner"
    width="40"
    height="40"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9067 10.0755H20.033V0.235352H10.5707C10.5707 5.67153 6.18243 10.0755 0.765625 10.0755V19.5716H10.5707V29.4118H20.033C20.033 23.9756 24.4899 19.5716 29.9067 19.5716V10.0755Z"
      fill="white"
    />
  </StyledSvg>
)

const DarkBlockSpinnerComp = styled.svg`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  margin-left: -3px;
  box-sizing: border-box;
  animation: spin 1.5s ease-in-out infinite;
`

export const DarkBlockSpinner = ({ zIndex, color }) => (
  <DarkBlockSpinnerComp
    zIndex={zIndex}
    id="myspinner"
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9067 10.0755H20.033V0.235352H10.5707C10.5707 5.67153 6.18243 10.0755 0.765625 10.0755V19.5716H10.5707V29.4118H20.033C20.033 23.9756 24.4899 19.5716 29.9067 19.5716V10.0755Z"
      fill={color || '#425466'}
    />
  </DarkBlockSpinnerComp>
)

export default Spinner
