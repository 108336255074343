import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from './ui/Layout'
import Spinner from './ui/Spinner'
import { baseUrl } from './helpers.js'

const PreviewText = styled.p`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-family: Inter, sans-serif;
  margin-bottom: 2px;
  word-break: break-word;
  z-index: 3;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: 100%;
  border-radius: 16px !important;
  text-shadow: 0px 0px 2px black;
`

const LinearGradient = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 16px;
  ${(props) =>
    props.border &&
    `width: calc(100% - 12px);
  left: 6px;
  border-radius: 11px;
  bottom: 6px;`}
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.5) 100%);
`

const PreviewContainer = styled(Box)`
  ${(props) => `${props.LR}:0px;`}
  cursor: pointer;
  transition: width 0.3s, height 0.3s, left 0.5s, right 0.5s;
  position: absolute;
  bottom: 0px;
  // background-color: black;
  border-radius: 16px !important;
  z-index: 100002;
`

const ButtonContainer = styled(Flex)`
  ${(props) => `${props.LR}:16px;`}
  cursor: pointer;
  transition: width 0.3s, height 0.3s, left 0.5s, right 0.5s;
  position: fixed;
  bottom: 16px;
  background-color: white;
  z-index: 100002;
  width: 137px;
  height: 40px;
  border-radius: 90px;
  box-shadow: 0px 4px 4px rgba(220, 217, 217, 0.25), 0px 0px 1px rgba(12, 26, 75, 0.35), 0px 3px 8px -1px rgba(50, 50, 71, 0.15);
  align-items: center;
  justify-content: center;
`

const ButtonLabel = styled.p`
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
`

const PlayIcon = styled.img`
  width: 16px;
  height: 15px;
  margin-right: 8px;
`

const PreviewImage = styled.img`
  height: 100% !important;
  position: relative;
  border-radius: 16px !important;
  object-fit: cover;
  z-index: 1;
  overflow: hidden;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  ${(props) => props.border && `border: 6px solid ${props.borderColor || 'black'};border-radius: 16px;`}
`

const ClosePreview = styled.img`
  position: absolute;
  z-index: 100001;
  top: -10px;
  right: -12px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(220, 217, 217, 0.25), 0px 0px 1px rgba(12, 26, 75, 0.35), 0px 3px 8px -1px rgba(50, 50, 71, 0.15);
`

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const Preview = ({
  setSlideIn,
  previewClick,
  showPreview,
  setShowPreview,
  campaign,
  previewMinimised,
  setPreviewMinimised,
  config: { gifUrl, LR, isLandscape, previewWidth, previewHeight, w }
}) => {
  if (!showPreview) {
    return null
  }
  if (previewMinimised) {
    return (
      <ButtonContainer
        onClick={() => {
          setPreviewMinimised(false)
          setSlideIn(true)
          previewClick()
        }}
        LR={LR}
      >
        <ClosePreview
          onClick={(e) => {
            e.stopPropagation()
            setPreviewMinimised(false)
          }}
          LR={LR}
          width="25px"
          height="25px"
          alt="close preview"
          src={`${baseUrl}/__clipara-preview-close.svg`}
        />
        <ButtonLabel>Watch video</ButtonLabel>
        <PlayIcon alt="play video" src={`${baseUrl}/__clipara-play-arrow-black.svg`} />
      </ButtonContainer>
    )
  }

  const video = campaign.videos[0]
  const isVideo = video.type === 'VIDEO'
  return (
    <PreviewContainer onClick={previewClick} id="__clipara-preview" height={previewHeight} width={previewWidth} LR={LR}>
      {campaign.text ? <LinearGradient border={campaign.border} /> : null}
      <ClosePreview
        onClick={(e) => {
          e.stopPropagation()
          setSlideIn(false)
          setPreviewMinimised(true)
        }}
        LR={LR}
        width="25px"
        height="25px"
        alt="close preview"
        src={`${baseUrl}/__clipara-preview-close.svg`}
      />
      {isVideo ? (
        <picture>
          <source srcset={gifUrl.replace('.gif', '.webp')} type="image/webp" />
          <source srcset={gifUrl} type="image/gif" />
          <PreviewImage
            height={previewHeight}
            width={previewWidth}
            draggable="false"
            src={gifUrl}
            alt="clipara preview"
            border={campaign.border}
            borderColor={campaign.borderColor}
          />
        </picture>
      ) : (
        <PreviewImage
          height={previewHeight}
          width={previewWidth}
          draggable="false"
          src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${Math.round(previewWidth)}x${Math.round(
            previewHeight
          )}&width=${Math.round(previewWidth * 2)}`}
          alt="clipara preview"
          border={campaign.border}
          borderColor={campaign.borderColor}
        />
      )}
      <PlayButton src={`${baseUrl}/__clipara-preview-play-button.svg`} alt="play button" />
      {campaign.text ? <PreviewText>{campaign.text}</PreviewText> : null}
      <Spinner />
    </PreviewContainer>
  )
}

export default Preview
