import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import {
  loadVideoLite,
  baseUrl,
  formatSeconds,
  getIsDocumentHidden,
  getBrowserVisibilityProp,
  getContainerElement,
  loadVideoMP4,
  loadSpecificVideoMP4
} from '../helpers'
import { gaEvent, trackEvent } from '../tracking'
import { getAspectRatio } from './EmbedCarousel'
import { ProgressBar } from './ui/ProgressBar'
import Spinner from '../ui/Spinner'

export const pauseOtherInlines = (playerId) => {
  // PAUSE OTHER INLINES x2
  try {
    Array.from(document.querySelectorAll('video[id^=__clipara-inline-carousel]'))
      .filter((v) => !v.id.includes('-clip') && v.id !== playerId)
      .forEach((v) => v.pause())
    Array.from(document.querySelectorAll('video[id^=__clipara-single-embed]'))
      .filter((v) => !v.id.includes('-clip') && v.id !== playerId)
      .forEach((v) => v.pause())
  } catch (e) {
    console.log(e)
  }
}

const End = styled.img`
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 100001 !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  cursor: pointer !important;
`

const PlayButton = styled.svg`
  fill: none !important;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  position: absolute;
  z-index: 2;

  @keyframes cliparapulse3 {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  border-radius: 60px;
  cursor: pointer;
  ${(props) => props.animate && `animation: cliparapulse3 1500ms infinite;`}
  @media(max-width: 1000px) {
    width: 40px !important;
    height: 40px !important;
  }
  ${(props) => {
    if (props.widgetPlayButtonPosition === 'BOTTOM_LEFT') {
      return `bottom: 16px; left: 16px;`
    }
    if (props.widgetPlayButtonPosition === 'BOTTOM_RIGHT') {
      return `bottom: 16px; right: 16px;`
    }
    return ` top: 50%; left: 50%; transform: translate(-50%, -50%);`
  }}
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width}px;
  max-width: 100%;
  padding-bottom: 0px !important;
  word-break: break-word;
`

const VideoDuration = styled.p`
  position: absolute;
  bottom: ${(props) => props.durationPadding || 16}px;
  right: ${(props) => props.durationPadding || 16}px;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  margin: 0px !important;
  z-index: 3;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  border-radius: ${(props) => props.borderRadius || 0}px;
  cursor: pointer;
`

const VideoContainer = styled(Flex)`
  visibility: ${(props) => (props.videoPlaying ? 'visible' : 'hidden')};
  border-radius: ${(props) => props.borderRadius || 0}px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const PlayerContainer = styled(Flex)`
  border-radius: ${(props) => props.borderRadius || 0}px;
  visibility: ${(props) => (props.viewFullVideo ? 'visible' : 'hidden')};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`

const Video = styled.video`
  border-radius: ${(props) => props.borderRadius || 0}px;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  z-index: 1;
  cursor: pointer;
`

const Picture = styled.picture`
  width: 100%;
  height: 100%;
`

const MuteContainer = styled(Flex)`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 16px;
  z-index: 110001;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(78, 78, 78, 0.4) !important;
`

const PlayContainer = styled(MuteContainer)`
  right: 60px;
`

const Volume = styled.img`
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: none;
  margin-left: ${(props) => props.ml || 0}px;
`

const PlayButtonComponent = ({ campaign, pbWidth }) => {
  return (
    <PlayButton
      widgetPlayButtonPosition={campaign.widgetPlayButtonPosition}
      animate={campaign.playButtonAnimation}
      playButtonColor={campaign.playButtonColor || '#333333'}
      width={pbWidth}
      height={pbWidth}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {campaign.widgetPlayButtonStyle === 'FILLED' ? (
        <>
          <circle cx="30" cy="30" r="30" fill={campaign.playButtonColor || '#333333'} />
          <path
            d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <circle cx="30" cy="30" r="28.5" stroke={campaign.playButtonColor || '#333333'} stroke-width="3" />
          <path
            d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
            fill={campaign.playButtonColor || '#333333'}
          />
        </>
      )}
    </PlayButton>
  )
}

const WIDTH_TO_HEIGHT = {
  LANDSCAPE: (x) => (x / 16) * 9,
  PORTRAIT: (x) => (x / 9) * 16,
  SHORT_PORTRAIT: (x) => (x / 2) * 3,
  SQUARE: (x) => x
}

const InlineSingle = ({ campaign, setShowFullscreenPlayer, showFullscreenPlayer }) => {
  const [resize, setResize] = useState()
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const {
    videos,
    playButtonColor,
    position,
    borderRadius,
    marginY,
    marginX = 0,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    durationDisplay,
    responsive,
    maxWidth,
    widgetThumbnailDimensions,
    isShoppable,
    defaultOpenFullscreen,
    widgetMobileThumbnailDimensions,
    widgetAutoplay,
    widgetHideDesktop
  } = campaign
  const [trackedView, setTrackedView] = useState(false)
  const [inView, setInView] = useState(false)
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const [viewFullVideo, setViewFullVideo] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasEnded, setHasEnded] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [showWaitingSpinner, setShowWaitingSpinner] = useState(false)
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())

  const singleEmbed = useRef()
  const player = useRef()
  const isWaitingRef = useRef()

  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const containerElement = getContainerElement(campaign.id)

  const videoWidth = Math.round(
    responsive
      ? containerElement && containerElement.clientWidth
      : maxWidth < (containerElement && containerElement.clientWidth)
      ? maxWidth
      : containerElement && containerElement.clientWidth
  )

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
      rootMargin: '0px'
    }
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setInView(true)
        setTrackedView(true)
      } else {
        setInView(false)
      }
    }, options)
    observer.observe(singleEmbed.current)
  }, [campaign && campaign.id])

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()
    document.addEventListener(visibilityChange, onVisibilityChange, false)
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  useEffect(() => {
    const asyncFunc = async () => {
      const element = document.getElementById(`__clipara-single-embed-${videos[0].id}-clip`)
      if (!element) {
        return
      }
      if (videos[0].type !== 'VIDEO') {
        return
      }
      if (!widgetAutoplay) {
        return
      }
      if (!inView || !isVisible) {
        try {
          element.pause()
        } catch (e) {}
        return
      }
      if (!element.hasAttribute('src')) {
        await loadVideoMP4({
          id: `__clipara-single-embed-${video.id}-clip`,
          video,
          campaign,
          playerName: 'single-embed-autoplay',
          useClip: true,
          videoWidth: videoWidth
        })
        setVideoPlaying(true)
      } else {
        try {
          element.play()
        } catch (e) {
          console.log(e)
        }
      }
    }
    asyncFunc()
  }, [inView, isVisible, showFullscreenPlayer])

  const onClick = async () => {
    try {
      const element = document.getElementById(`__clipara-single-embed-${videos[0].id}-clip`)
      element.pause()

      pauseOtherInlines(`__clipara-single-embed-${videos[0].id}`)

      await loadSpecificVideoMP4({
        id: `__clipara-single-embed-${video.id}`,
        video,
        campaign,
        playerName: 'single-embed',
        organisationId: campaign.organisationId,
        videoWidth: videoWidth,
        inputPlayer: player.current,
        setHasPlayed: setIsPlaying,
        setHasEnded: setHasEnded
      })
      gaEvent({
        isGaEnabled: campaign?.gaTracking,
        name: 'clipara_video_play',
        data: {
          campaign_name: campaign?.name,
          video_name: video?.name
        }
      })
      trackEvent({
        type: 'PLAY',
        campaignId: campaign.id,
        videoId: video.id,
        playerType: 'WIDGET',
        widgetId: campaign.widgetId,
        productIds: campaign.videoProducts.filter((p) => p.videoId === video.id).map((p) => p.product.id)
      })
      player.current.addEventListener('ended', () => {
        setHasEnded(true)
        setIsPlaying(false)
      })
      player.current.addEventListener('play', () => {
        setHasEnded(false)
      })
      player.current.addEventListener('pause', () => {
        setIsPlaying(false)
      })
      player.current.addEventListener('waiting', () => {
        setIsWaiting(true)
      })
      player.current.addEventListener('playing', () => {
        setIsPlaying(true)
        setIsWaiting(false)
      })
      setViewFullVideo(true)
    } catch (e) {
      console.log(e)
    }
    return
  }

  useEffect(() => {
    isWaitingRef.current = isWaiting
    if (isWaiting) {
      setTimeout(() => {
        if (isWaitingRef.current) {
          setShowWaitingSpinner(true)
        }
      }, 1000)
    } else {
      setShowWaitingSpinner(false)
    }
  }, [isWaiting])

  const playerOnClick = async (e) => {
    e && e.stopPropagation()
    pauseOtherInlines(`__clipara-single-embed-${video.id}`)

    if (isPlaying) {
      player.current.pause()
      setIsPlaying(false)
    } else {
      player.current.play()
      setIsPlaying(true)
    }
  }

  const restart = (e) => {
    e.stopPropagation()
    player.current.currentTime = 0
    playerOnClick()
  }

  const dimensionsToUse = w < 700 && widgetMobileThumbnailDimensions === 'PORTRAIT' ? 'PORTRAIT' : widgetThumbnailDimensions
  const video = videos[0]
  const isVideo = video.type === 'VIDEO'
  const imageHeight = Math.round(WIDTH_TO_HEIGHT[dimensionsToUse](videoWidth))
  const thumbnailToUse = w < 700 ? video.customMobileThumbnail || video.customThumbnail : video.customThumbnail
  const imageUrl = thumbnailToUse
    ? thumbnailToUse + `-/scale_crop/${videoWidth}x${imageHeight}/center/`
    : `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${
        video.thumbnailTime || 0.0
      }&width=${videoWidth}&height=${imageHeight}&fit_mode=crop`
  const durationPadding = borderRadius > 32 ? borderRadius / 2 : 16
  const webpUrl = thumbnailToUse
    ? thumbnailToUse + `-/scale_crop/${videoWidth}x${imageHeight}/center/-/format/webp/`
    : imageUrl.replace('.png', '.webp')
  if (w > 700 && widgetHideDesktop) {
    return null
  }
  return (
    <>
      {text ? (
        <Title
          color={titleColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          fontFamily={widgetFontFamily}
          textAlign={textAlign}
          width={videoWidth}
        >
          {text}
        </Title>
      ) : null}
      <Flex
        overflow="hidden"
        height={imageHeight}
        minHeight={imageHeight}
        onClick={onClick}
        position="relative"
        maxWidth={responsive ? '100%' : maxWidth || '100%'}
        ref={singleEmbed}
        borderRadius={borderRadius}
        justifyContent="center"
      >
        <VideoContainer videoPlaying={videoPlaying} hasLoaded={hasLoaded} borderRadius={borderRadius}>
          <Video
            id={`__clipara-single-embed-${video.id}-clip`}
            borderRadius={borderRadius}
            preload="metadata"
            playsInline
            webkit-playsInline
            x-webkit-airplay="allow"
            muted
            loop
          />
        </VideoContainer>
        {isVideo ? (
          <Picture>
            <source srcset={webpUrl} type="image/webp" />
            <source srcset={imageUrl} type="image/png" />
            <VideoImage
              height={imageHeight}
              minHeight={imageHeight}
              draggable="false"
              borderRadius={borderRadius}
              loading="lazy"
              src={imageUrl}
              alt={video.name}
            />
          </Picture>
        ) : (
          <Picture>
            <VideoImage
              height={imageHeight}
              minHeight={imageHeight}
              draggable="false"
              borderRadius={borderRadius}
              loading="lazy"
              src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(dimensionsToUse)}&width=${Math.round(
                videoWidth * 2
              )}`}
              alt={video.name}
            />
          </Picture>
        )}
        <PlayerContainer viewFullVideo={viewFullVideo} borderRadius={borderRadius} onClick={playerOnClick}>
          <Video
            id={`__clipara-single-embed-${video.id}`}
            ref={player}
            borderRadius={borderRadius}
            preload="metadata"
            playsInline
            webkit-playsInline
            x-webkit-airplay="allow"
            muted={isMuted}
          />
          {hasEnded ? <End onClick={restart} alt="restart icon" src={`${baseUrl}/__clipara-restart-white.svg`} /> : null}
          {campaign.widgetShowProgressBar ? <ProgressBar forceDesktop campaign={campaign} videoRef={player} /> : null}
          {viewFullVideo ? (
            <>
              <PlayContainer autoOpen={campaign.autoOpen}>
                {isPlaying ? (
                  <Volume width="12px" height="18px" alt="volume" src={`${baseUrl}/__clipara-pause-white.svg`} />
                ) : (
                  <Volume ml="4px" width="22px" height="22px" alt="volume" src={`${baseUrl}/__clipara-play-white.svg`} />
                )}
              </PlayContainer>
              <MuteContainer
                onClick={(e) => {
                  e.stopPropagation()
                  setIsMuted(!isMuted)
                }}
              >
                <Volume
                  width="28px"
                  height="28px"
                  alt="volume"
                  src={!isMuted ? `${baseUrl}/__clipara-mute-white-large.svg` : `${baseUrl}/__clipara-volume-white-large.svg`}
                />
              </MuteContainer>
            </>
          ) : null}
        </PlayerContainer>
        {!viewFullVideo ? (
          <Box zIndex={10} onClick={onClick}>
            <PlayButtonComponent pbWidth={60} campaign={campaign} />
          </Box>
        ) : null}
        {showWaitingSpinner && <Spinner zIndex={100} />}

        {durationDisplay && !isPlaying ? (
          <VideoDuration durationPadding={durationPadding}>{video.duration ? formatSeconds(video.duration) : ''}</VideoDuration>
        ) : null}
      </Flex>
    </>
  )
}

export default InlineSingle
