import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Flex } from '../ui/Layout'
import Select from '../ui/Select'
import { baseUrl, camelObj, formatCurrency } from '../helpers'
import { trackEvent } from '../tracking'
import axios from 'axios'

const Container = styled(Flex)`
  flex-direction: column;
  position: relative;
  background-color: white;
  height: 100%;
  overflow: hidden;
  div {
    box-sizing: border-box !important;
  }
  ${(props) => props.br && `border-radius: ${props.br};`}
  ${(props) => props.shadow && 'box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);'}
`

const InnerContainer = styled(Flex)`
  overflow-y: scroll;
  flex-direction: column;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &&::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  div {
    box-sizing: border-box !important;
  }
  ${(props) => props.br && `border-radius: ${props.br};`}
`

const CloseTargetBox = styled(Flex)`
  transform: translate(0%, -50%);

  position: absolute;
  top: 50%;
  left: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const Close = styled.img`
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

const ProductImage = styled.img`
  height: 330px !important;
  margin-right: 8px;
`

const Title = styled.p`
  margin-bottom: ${(props) => props.mb || '8px'} !important;
  text-decoration: none;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #27272e !important;
  font-family: 'Inter';
  font-size: 16px !important;
  text-align: left !important;
`

const MainTitle = styled(Title)`
  font-size: 20px !important;
  font-weight: 600 !important;
`

const GreyTitle = styled(Title)`
  font-size: 14px !important;
  color: #425466 !important;
  padding-bottom: 16px !important;
`

const Label = styled.p`
  text-decoration: none;
  margin-bottom: ${(props) => (props.noMargin ? '0px' : props.mb || '8px')} !important;
  margin-top: 0px;
  font-weight: 600 !important;
  line-height: 20px;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #27272e !important;
  font-family: 'Inter';
  font-size: 14px !important;
  text-align: left !important;
`

const Carousel = styled(Flex)`
  position: relative;
  scroll-behavior: smooth;
  height: 330px;
  min-height: 330px;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &&::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

const Button = styled.button`
  margin-top: 8px !important;
  background-color: ${(props) => props.backgroundColor} !important;
  color: ${(props) => props.color} !important;
  border: ${(props) => props.border} !important;
  margin-bottom: 16px !important;
  font-family: 'Inter';
  height: 48px !important;
  border-radius: ${(props) => (typeof props.borderRadius === 'number' ? props.borderRadius : 8)}px !important;
  font-size: 14px !important;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  width: 100% !important;
  overflow: hidden;
  outline: inherit;
  opacity: ${(props) => props.opacity || 1};
  max-width: 500px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  p {
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600 !important;
    line-height: 20px;
    font-size: 16px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  &&:hover {
    border-width: 2.5px !important;
  }
`

const SectionWithBorder = styled(Flex)`
  flex-direction: column;
  ${(props) => !props.noTop && `border-top: 8px solid #ebf2fa;`}
  border-bottom: 8px solid #ebf2fa;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
`

const ContentContainer = styled(Flex)`
  flex-direction: column;
  padding-top: 16px;
  div,
  p,
  span {
    text-decoration: none;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 400 !important;
    font-family: 'Inter';
    font-size: 14px;
    color: #425466 !important;
    line-height: 20px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const ChevronImage = styled.img`
  transform: rotate(${(props) => (!props.isOpen ? '-90' : '-270')}deg);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 12px !important;
  height: 12px !important;
`

const Grid = styled(Flex)`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
`

const RecommendationImage = styled.div`
  border-radius: 4px !important;
  margin-bottom: 8px !important;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`

const Price = styled.p`
  font-weight: 600 !important;
`

const OldPrice = styled.span`
  color: #425466;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-decoration: line-through !important;
`

const RecommendationContainer = styled(Flex)`
  flex-direction: column;
  p {
    text-decoration: none;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 400;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: rgba(16, 18, 37, 1) !important;
    text-decoration: none !important;
  }
`

const NoUnderline = styled.a`
  &&:active,
  &&:visited,
  &&:link {
    text-decoration: none !important;
  }
  text-decoration: none !important;
`

const CirclesContainer = styled(Flex)`
  justify-content: center;
`

const Circle = styled.div`
  cursor: pointer;
  display: block !important;
  background-color: ${(props) => (props.isActive ? 'black' : '#CCCCCC')};
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 8px;
  margin-left: 3px;
  margin-right: 3px;
`

// margin includes circles
const Chevron = styled(Flex)`
  @media (max-width: 700px) {
    display: none;
  }
  position: absolute;
  top: calc(50% - 24px);
  width: 32px !important;
  height: 32px !important;
  img {
    width: 16px !important;
    height: 16px !important;
  }
  z-index: 110001;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => props.disabled && `opacity: 0.4; cursor: default;`}
`

const LeftChevron = styled(Chevron)`
  left: 12px;
`

const RightChevron = styled(Chevron)`
  right: 12px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const ItemTitle = styled.p`
  font-size: 14px !important;
  line-height: 18px !important;
  margin-bottom: 8px !important;
  color: #27272e !important;
  font-weight: 600 !important;
`

const ItemPrice = styled.p`
  font-size: 14px !important;
  line-height: 18px !important;
  color: #27272e !important;
  font-weight: 400 !important;
`

const Logo = styled.img`
  width: 160px;
  height: 40px;
  object-fit: contain;
`

const getCurrentImage = (images) => {
  let nearestToLeft = 99999
  let nearestToLeftIndex
  const container = document.getElementById('__clipara-product-image-carousel-container')
  const { left: baseLeft } = document.getElementById('__clipara-product-image-carousel-container').getBoundingClientRect()

  if (container.scrollLeft === container.scrollWidth - container.clientWidth) {
    return images.length - 1
  }
  images.forEach((image, i) => {
    const { left } = document.getElementById('__clipara-product-image-carousel-' + i).getBoundingClientRect()
    if (left - baseLeft < nearestToLeft && left - baseLeft > -200) {
      nearestToLeft = left - baseLeft
      nearestToLeftIndex = i
    }
  })
  return nearestToLeftIndex
}

const goToImageIndex = (images, i) => {
  let width = 0
  const all = document.getElementById('__clipara-product-image-carousel-' + i).getBoundingClientRect()
  for (let j = 0; j < i; j++) {
    width = width + document.getElementById('__clipara-product-image-carousel-' + j).getBoundingClientRect().width + 16
  }
  document.getElementById('__clipara-product-image-carousel-container').scrollTo(width, 0)
  return 1
}

const CarouselComponent = ({ images, product }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  useEffect(() => {
    document.getElementById('__clipara-product-image-carousel-container') &&
      document.getElementById('__clipara-product-image-carousel-container').addEventListener('scroll', () => {
        setCurrentIndex(getCurrentImage(images))
      })
  })
  return (
    <>
      <Flex flexDirection="column" position="relative">
        <LeftChevron
          disabled={currentIndex === 0}
          onClick={() => {
            if (currentIndex === 0) return
            goToImageIndex(images, currentIndex - 1)
          }}
        >
          <img src={`${baseUrl}/__clipara-left-arrow-black.svg`} alt="chevron icon" width="16px" height="16px" />
        </LeftChevron>
        <RightChevron
          disabled={currentIndex === images.length - 1}
          onClick={() => {
            if (currentIndex === images.length - 1) return
            goToImageIndex(images, currentIndex + 1)
          }}
        >
          <img src={`${baseUrl}/__clipara-left-arrow-black.svg`} alt="chevron icon" width="16px" height="16px" />
        </RightChevron>
        <Carousel id={'__clipara-product-image-carousel-container'}>
          {images.map((image, i) => (
            <ProductImage
              key={image.id}
              src={image.src}
              id={'__clipara-product-image-carousel-' + i}
              alt={product.title + ' ' + i}
            />
          ))}
        </Carousel>
        <CirclesContainer mt="16px">
          {images.map((_, i) => {
            return <Circle key={i} onClick={() => goToImageIndex(images, i)} isActive={i === currentIndex} />
          })}
        </CirclesContainer>
      </Flex>
    </>
  )
}

const CollapsableSection = ({ children, label }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <SectionWithBorder cursor={'pointer'} onClick={() => setIsOpen(!isOpen)}>
      <Flex justifyContent="space-between" alignItems="center">
        <Label noMargin>{label}</Label>

        <ChevronImage
          isOpen={isOpen}
          src={`${baseUrl}/__clipara-chevron-left-black.svg`}
          alt="chevron icon"
          width="20px"
          height="20px"
        />
      </Flex>
      {isOpen ? <ContentContainer>{children}</ContentContainer> : null}
    </SectionWithBorder>
  )
}

const ShoppableProduct = ({ product: dbProduct, close, campaign, br, activeVideo, currency, shadow }) => {
  if (!dbProduct) {
    return null
  }
  const product = dbProduct.product
  const firstAvailable = product.variants.filter((variant) => variant.available)[0] || product.variants[0]
  const productUrl = dbProduct.productUrl
  const [variant, setVariant] = useState(firstAvailable)
  const [useVariantImage, setUseVariantImage] = useState(false)
  const [optionValues, setOptionValues] = useState(firstAvailable.options)
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const hasMultipleVariants = product.variants.length > 1
  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const res1 = (
          await axios.get(`${productUrl.split('/products/')[0]}/recommendations/products.json?product_id=${dbProduct.productId}`)
        ).data
        if (res1 && res1.products && res1.products.length) {
          setRecommendedProducts(res1.products.map((el) => camelObj(el)))
        }
      } catch (e) {
        try {
          const res = await Promise.all(
            dbProduct.recommendations.map(async (p) => {
              return axios.get(`${productUrl.split('/products/')[0]}/products/${p.handle}.js`)
            })
          )
          setRecommendedProducts(res.data.map((el) => camelObj(el)))
        } catch (e) {
          console.log(e)
        }
      }
    }
    setVariant(firstAvailable)
    setOptionValues(firstAvailable.options)
    asyncFunc()
  }, [dbProduct.id])

  const baseShopifyUrl = dbProduct.productUrl.split('/products/')[0]
  const addToCartUrl = baseShopifyUrl + `/cart/add?id=${variant.id}&quantity=1`

  const price = formatCurrency({ currency, price: variant.price / 100, campaign })
  const compareAtPrice =
    variant.compareAtPrice &&
    variant.compareAtPrice > 0 &&
    variant.compareAtPrice !== variant.price &&
    !campaign.widgetHideCompareAtPrice
      ? formatCurrency({ currency, price: variant.compareAtPrice / 100, campaign })
      : ''

  const imagesOrdered = useVariantImage
    ? [
        product.media.find((image) => variant.featured_image && image.id === variant.featured_image.id),
        ...product.media.filter((image) => !variant.featured_image || image.id !== variant.featured_image.id)
      ].filter((el) => el)
    : product.media
  return (
    <Container br={br} shadow={shadow}>
      <Flex height="60px" minHeight="60px"></Flex>
      <Flex position="absolute" top="0" left="0" bg="white" height="60px" alignItems="center" px="14px">
        {close ? (
          <CloseTargetBox onClick={close}>
            <Close width="20px" height="12px" alt="close preview" src={`${baseUrl}/__clipara-left-arrow-black.svg`} />
          </CloseTargetBox>
        ) : null}
        {campaign.logoUrl ? (
          <Flex justifyContent="center">
            <Logo src={campaign.logoUrl} />
          </Flex>
        ) : null}
      </Flex>
      <InnerContainer>
        <CarouselComponent images={imagesOrdered} product={product} />
        <Flex flexDirection="column" mt="32px" width="calc(100% - 40px)" mx="20px" maxWidth="600px">
          <MainTitle>{product.title}</MainTitle>
          <Title mb="16px">
            {price} {variant.compareAtPrice && variant.compareAtPrice !== price ? <OldPrice>{compareAtPrice}</OldPrice> : null}
          </Title>
          {hasMultipleVariants
            ? product.options.map((option, i) => {
                return (
                  <Select
                    onChange={(value) => {
                      let newValues = optionValues
                      newValues[i] = value
                      setOptionValues(newValues)
                      const newVariant = product.variants.find((v) => {
                        let result = true
                        optionValues.forEach((ov, i) => {
                          if (v[`option${i + 1}`] !== ov) {
                            result = false
                          }
                        })
                        return result
                      })
                      if (newVariant) {
                        setVariant(newVariant)
                        setUseVariantImage(true)
                      }
                    }}
                    value={optionValues && optionValues[i]}
                    values={option.values}
                    key={option.name}
                    name={option.name}
                  />
                )
              })
            : null}
          {variant.available ? (
            <Button
              onClick={async () => {
                if (window.location.href.includes(campaign.shop.domain)) {
                  await axios.post(`${baseShopifyUrl}/cart/add.js`, { items: [{ id: variant.id, quantity: 1 }] })
                  const res = await axios.get(`${baseShopifyUrl}/cart.js`)
                  const cartToken = res.data.token
                  await trackEvent({
                    type: 'ADD_TO_CART',
                    campaignId: campaign.id,
                    cartId: cartToken,
                    videoId: activeVideo.id,
                    playerType: campaign.autoOpen ? 'SHARE' : 'WIDGET',
                    widgetId: campaign.widgetId,
                    productIds: product.id
                  })
                  window.location.href = `${baseShopifyUrl}/cart`
                } else {
                  await trackEvent({
                    type: 'ADD_TO_CART',
                    campaignId: campaign.id,
                    videoId: activeVideo.id,
                    playerType: campaign.autoOpen ? 'SHARE' : 'WIDGET',
                    widgetId: campaign.widgetId,
                    productIds: product.id
                  })
                  window.location.href = addToCartUrl
                }
              }}
              borderRadius={campaign.buttonBorderRadius}
              backgroundColor={campaign.buttonRgba || campaign.buttonColor}
              color={campaign.buttonFontColor}
              border={`2px solid ${campaign.buttonBorderColor}`}
            >
              <p>{campaign.addToCartText || 'Add to cart'}</p>
            </Button>
          ) : (
            <Button
              borderRadius={campaign.buttonBorderRadius}
              backgroundColor={campaign.buttonRgba || campaign.buttonColor}
              color={campaign.buttonFontColor}
              border={`2px solid ${campaign.buttonBorderColor}`}
              opacity={0.6}
            >
              <p>Out of stock</p>
            </Button>
          )}
          {campaign.deliveryMessage && <GreyTitle>{campaign.deliveryMessage}</GreyTitle>}
        </Flex>
        {product.description ? (
          <CollapsableSection label={[5, 6, 7, 899].includes(campaign.organisationId) ? 'Descripción' : 'Description'}>
            <Flex flexDirection="column" dangerouslySetInnerHTML={{ __html: product.description }} />
          </CollapsableSection>
        ) : null}
        {recommendedProducts && recommendedProducts.length ? (
          <SectionWithBorder noTop>
            <Label mb="16px">
              {[5, 6, 7, 899].includes(campaign.organisationId) ? 'Los clientes también compraron' : 'People also bought'}
            </Label>
            <Grid>
              {recommendedProducts.map((recommendedProduct) => {
                const price = formatCurrency({ currency, price: recommendedProduct.price / 100, campaign })

                const link = dbProduct.productUrl.split('/products/')[0] + `/products/${recommendedProduct.handle}`

                return (
                  <NoUnderline
                    key={recommendedProduct.id}
                    onClick={() => {
                      window.location.href = link
                    }}
                  >
                    <RecommendationContainer key={product.id}>
                      <RecommendationImage src={recommendedProduct.media[0].src} alt={recommendedProduct.title} />
                      <ItemTitle>{recommendedProduct.title}</ItemTitle>
                      <ItemPrice>{price}</ItemPrice>
                    </RecommendationContainer>
                  </NoUnderline>
                )
              })}
            </Grid>
          </SectionWithBorder>
        ) : null}
      </InnerContainer>
    </Container>
  )
}

export default ShoppableProduct
