import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import {
  baseUrl,
  formatSeconds,
  loadVideoLite,
  loadScripts,
  getIsDocumentHidden,
  getBrowserVisibilityProp,
  loadVideoMP4
} from '../helpers'
import { trackEvent } from '../tracking'
import { getChevronStyling, getChevronImg, getChevronImgSize } from './chevronStyles.js'
import { getAspectRatio } from './EmbedCarousel.js'
import { ImageExpandButton } from './ui/ImageExpandButton.js'

const CarouselItem = styled(Box)`
  position: relative;
  cursor: pointer;
  width: ${(props) => props.videoWidth}px;
  min-width: ${(props) => props.videoWidth}px;
  height: ${(props) => props.videoHeight}px;
  margin-left: ${(props) => props.gapWidth / 2}px;
  margin-right: ${(props) => props.gapWidth / 2}px;
  border-radius: ${(props) => `${props.borderRadius}px`};
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-color: white;
  box-sizing: border-box;
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}
  &&:hover {
    .hover {
      display: flex;
    }
  }
`

const pbSizeFunc = (props) => {
  if (!props.widgetMobileThumbnailSize || props.widgetMobileThumbnailSize === 'LARGE') {
    return `width: 60px;
      height: 60px;`
  }
  if (props.widgetMobileThumbnailSize === 'MEDIUM') {
    return `width: 40px;
      height: 40px;`
  }
  if (props.widgetMobileThumbnailSize === 'SMALL') {
    return `width: 20px;
      height: 20px;`
  }
}
const PlayButton = styled.svg`
  fill: none !important;
  ${pbSizeFunc}
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`

const PBContainer = styled(Box)`
  ${pbSizeFunc}
  position: absolute;
  z-index: 1;
  @keyframes cliparapulse2 {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  border-radius: 60px;
  ${(props) => props.animate && `animation: cliparapulse2 1500ms infinite;`}
  ${(props) => {
    if (props.widgetPlayButtonPosition === 'BOTTOM_LEFT') {
      return `bottom: 16px; left: 16px;`
    }
    if (props.widgetPlayButtonPosition === 'BOTTOM_RIGHT') {
      return `bottom: 16px; right: 16px;`
    }
    return ` top: 50%; left: 50%; transform: translate(-50%, -50%);`
  }}
`

const VideoImage = styled.img`
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  opacity: 1 !important;
  ${(props) =>
    props.isCircle && props.widgetThumbnailBorder && `border: 2.5px solid white; box-sizing: border-box; border-radius: 100%;`}
`

const Picture = styled.picture`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const NextPicture = styled.picture`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`

const Slider = styled(Flex)`
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &&::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  justify-content: ${(props) => {
    if (props.shouldShowArrows) {
      return 'flex-start'
    }
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.mobileFontSize || props.fontSize || '50'}px !important;
  line-height: ${(props) => props.mobileFontSize || props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none;
  margin-bottom: 16px;
  margin-top: 0px;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  word-break: break-word;
  text-transform: none !important;
`

const VideoDuration = styled.p`
  position: absolute;
  bottom: ${(props) => props.durationPadding || 16}px;
  right: ${(props) => props.durationPadding || 16}px;
  color: white !important;
  font-weight: 600 !important;

  margin: 0px !important;
  z-index: 2;
  ${(props) => {
    if (props.widgetMobileThumbnailSize === 'MEDIUM') {
      return 'font-size: 14px !important; line-height: 14px !important;'
    }
    if (props.widgetMobileThumbnailSize === 'SMALL') {
      return 'font-size: 12px !important; line-height: 12px !important;'
    }
    return 'font-size: 16px !important; line-height: 16px !important;'
  }}
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 48px;
  min-width: 48px;
  height: 48px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => {
    if (!props.widgetMobileThumbnailSize || props.widgetMobileThumbnailSize === 'LARGE') {
      return `width: 48px;
  min-width: 48px;
  height: 48px;`
    }
    if (props.widgetMobileThumbnailSize === 'MEDIUM' || props.widgetMobileThumbnailSize === 'SMALL') {
      return `width: 40px;
  min-width: 40px;
  height: 40px;`
    }
  }}
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
`

const ChevronImage = styled(Flex)`
  svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  ${(props) => {
    if (!props.widgetMobileThumbnailSize || props.widgetMobileThumbnailSize === 'LARGE') {
      return `width: 20px !important;
  height: 20px !important;`
    }
    if (props.widgetMobileThumbnailSize === 'MEDIUM' || props.widgetMobileThumbnailSize === 'SMALL') {
      return `width: 16px !important;
  height: 16px !important;`
    }
  }}
`

const LeftChevron = styled(Chevron)``

const RightChevron = styled(Chevron)`
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const VideoContainer = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

// height: 100%;
const Video = styled.video`
  width: ${(props) => props.videoWidth}px !important;
  min-width: 100%;
  height: ${(props) => (props.isCircle ? 'auto' : `${props.videoHeight}px`)} !important;
  object-fit: cover;
  z-index: 1;
  ${(props) =>
    props.isCircle &&
    props.widgetThumbnailBorder &&
    `width: ${props.videoWidth - 8}px !important;border: 2.5px solid white; box-sizing: border-box; border-radius: 100%;`}

  &&::-webkit-media-controls {
    display: none !important;
  }
  /* Could Use thise as well for Individual Controls */
  &&::-webkit-media-controls-play-button {
    display: none !important;
  }
  &&::-webkit-media-controls-volume-slider {
    display: none !important;
  }
  &&::-webkit-media-controls-mute-button {
    display: none !important;
  }
  &&::-webkit-media-controls-timeline {
    display: none !important;
  }
  &&::-webkit-media-controls-current-time-display {
    display: none !important;
  }
  transition: opacity 0.15 ease-in-out;
  -moz-transition: opacity 0.15 ease-in-out;
  -webkit-transition: opacity 0.15 ease-in-out;
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.widgetTitleFontFamily ? props.widgetTitleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.widgetTitleFontWeight || '700'} !important;
  font-size: ${(props) => props.widgetMobileTitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetMobileTitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetTitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetTitleFontColor || 'black'};
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) =>
    props.widgetSubtitleFontFamily ? props.widgetSubtitleFontFamily.replace(';') : 'inherit'} !important;
  font-weight: ${(props) => props.widgetSubtitleFontWeight || '500'} !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  font-size: ${(props) => props.widgetMobileSubtitleFontSize || '16'}px !important;
  line-height: ${(props) => props.widgetMobileSubtitleFontSize || '16'}px !important;
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  }
`

const Titles = ({
  isStories,
  gapWidth,
  titleVideoObject,
  widgetTitleAlignment,
  widgetMobileTitleFontSize,
  widgetTitleFontColor,
  widgetTitleFontFamily,
  widgetTitleFontWeight,
  widgetSubtitleFontWeight,
  widgetSubtitleFontColor,
  widgetSubtitleFontFamily,
  widgetMobileSubtitleFontSize
}) => {
  return (
    <Flex flexDirection="column" mx={`${gapWidth / 2}px`} width="100%">
      {titleVideoObject.title && (
        <VideoTitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetMobileTitleFontSize={widgetMobileTitleFontSize}
          widgetTitleFontColor={widgetTitleFontColor}
          widgetTitleFontFamily={widgetTitleFontFamily}
          widgetTitleFontWeight={widgetTitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && !isStories && (
        <VideoSubtitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetMobileSubtitleFontSize={widgetMobileSubtitleFontSize}
          widgetSubtitleFontColor={widgetSubtitleFontColor}
          widgetSubtitleFontFamily={widgetSubtitleFontFamily}
          widgetSubtitleFontWeight={widgetSubtitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  height: 150px;
  flex-direction: column-reverse;
  box-sizing: border-box !important;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`

const FreeLink = styled.a`
  position: absolute !important;
  left: 0 !important;
  bottom: ${(props) => (props.widgetMobileChevrons ? 0 : -8)}px !important;
  margin-left: 8px !important;
`

const VideoHack = styled.div`
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 3;
`

const applySizeFactor = (x, size, isStories) => {
  if (isStories) {
    if (size === 'MEDIUM') {
      return Math.round(x * 0.75)
    }
    if (size === 'SMALL') {
      return Math.round(x * 0.5833)
    }
    return x
  }
  if (size === 'MEDIUM') {
    return Math.round(x * 0.6)
  }
  if (size === 'SMALL') {
    return Math.round(x * 0.4)
  }
  return x
}

const getGapWidth = (size) => {
  if (size === 'MEDIUM') {
    return 8
  }
  if (size === 'SMALL') {
    return 4
  }
  return 16
}

const getVideoWidth = (dimensions, isStories) => {
  if (isStories) {
    if (dimensions === 'CIRCLE') {
      return 120
    }
    return 120
  }
  if (dimensions === 'LANDSCAPE') {
    return 300
  }
  if (dimensions === 'SQUARE') {
    return 300
  }
  if (dimensions === 'SQUISHED_PORTRAIT') {
    return 250
  }
  if (dimensions === 'CIRCLE') {
    return 300
  }
  // PORTRAIT
  return 250
}

const getVideoHeight = (dimensions, isStories) => {
  if (isStories) {
    if (dimensions === 'CIRCLE') {
      return 120
    }
    return 177.5
  }
  if (dimensions === 'LANDSCAPE') {
    return 175
  }
  if (dimensions === 'SQUARE') {
    return 300
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return 325
  }
  if (dimensions === 'CIRCLE') {
    return 300
  }
  // PORTRAIT
  return 400
}

const getFixedVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth / 640) * 370
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth / 338) * 500
  }
  if (dimensions === 'CIRCLE') {
    return videoWidth
  }
  // PORTRAIT
  return (videoWidth / 338) * 600
}

const getDurationPadding = (borderRadius, size) => {
  if (borderRadius > 32) {
    return borderRadius + 8
  }
  if (size === 'MEDIUM') {
    return 10
  }
  if (size === 'SMALL') {
    return 6
  }
  return 12
}

const EmbedMobileCarousel = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo, showFullscreenPlayer }) => {
  const {
    videos,
    landscape,
    playButtonColor,
    borderRadius: inputBorderRadius,
    marginY,
    marginX,
    fontSize,
    mobileFontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    durationDisplay,
    widgetTitleActive,
    videoObjects,
    widgetTitleAlignment,
    widgetDesktopTitleFontSize,
    widgetMobileTitleFontSize,
    widgetTitleFontColor,
    widgetTitleFontFamily,
    widgetTitleFontWeight,
    widgetSubtitleFontWeight,
    widgetTitlePosition,
    widgetAutoplay,
    widgetSubtitleFontColor,
    widgetSubtitleFontFamily,
    widgetMobileSubtitleFontSize,
    widgetThumbnailDimensions,
    widgetMobileThumbnailDimensions,
    widgetMobileThumbnailSize,
    widgetThumbnailBorderSize,
    widgetThumbnailBorderColor,
    widgetThumbnailBorder,
    widgetPlayButtonPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetPlayButtonStyle,
    type,
    widgetFixedThumbnailWidth,
    widgetFixedThumbnailHeight,
    widgetFixedThumbnail,
    position,
    widgetGapWidth,
    widgetMobileChevrons
  } = campaign
  const isStories = type === 'STORIES'
  const dimensions = isStories ? widgetThumbnailDimensions : widgetMobileThumbnailDimensions || widgetThumbnailDimensions
  const videoWidth = widgetFixedThumbnail
    ? widgetFixedThumbnailWidth
    : applySizeFactor(getVideoWidth(dimensions, isStories), widgetMobileThumbnailSize, isStories)
  const videoHeight = widgetFixedThumbnail
    ? getFixedVideoHeight(dimensions, videoWidth)
    : applySizeFactor(getVideoHeight(dimensions, isStories), widgetMobileThumbnailSize, isStories)
  const isCircle = dimensions === 'CIRCLE'
  const borderRadius = isCircle ? videoWidth / 2 : inputBorderRadius
  const gapWidth = isStories ? widgetGapWidth : getGapWidth(widgetMobileThumbnailSize)
  const numberOfVideos = videos.length
  const containerWidth = 400
  const shouldShowArrows = containerWidth - numberOfVideos * videoWidth - gapWidth * (numberOfVideos - 2) < 0

  const [activeVideo, setActiveVideo] = useState(0)
  const [inView, setInView] = useState(false)
  const [trackedView, setTrackedView] = useState(false)
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())
  const slider = useRef()
  useEffect(() => {
    slider.current.addEventListener('scroll', () => {
      const newActiveVideo = Math.round(slider.current.scrollLeft / videoWidth)
      setActiveVideo(newActiveVideo)
    })
    const options = {
      root: null,
      threshold: 0.5,
      rootMargin: '400px'
    }
    if (widgetAutoplay) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          setInView(true)
          setTrackedView(true)
        } else {
          setInView(false)
        }
      }, options)
      observer.observe(slider.current)
    }
  }, [campaign && campaign.id])

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()
    document.addEventListener(visibilityChange, onVisibilityChange, false)
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  useEffect(() => {
    const asyncFunc = async () => {
      if (isStories) {
        return
      }
      videos.forEach(async (video, i) => {
        const element = document.getElementById(`__clipara-mobile-carousel-${video.id}-${campaign.id}`)
        if (!element) {
          return
        }
        if (video.type !== 'VIDEO') {
          return
        }
        if (i !== activeVideo || !inView || !isVisible) {
          element.style.opacity = '0'
          element.currentTime = 0
          return element.pause()
        }

        if (!element.hasAttribute('src')) {
          await loadVideoMP4({
            id: `__clipara-mobile-carousel-${video.id}-${campaign.id}`,
            video,
            campaign,
            useClip: true,
            videoWidth
          })
          element.style.opacity = '1'
        } else {
          try {
            document.getElementById(`__clipara-mobile-carousel-${video.id}-${campaign.id}`).play()
            element.style.opacity = '1'
          } catch (e) {}
        }
      })
    }
    asyncFunc()
  }, [activeVideo, inView, isVisible])

  // stories autoplay
  useEffect(() => {
    if (!isStories || !widgetAutoplay) {
      return
    }
    if (!trackedView) {
      return
    }
    const numberVideos = videos.length > 4 ? 4 : videos.length
    const async = async () => {
      const play = async (index, runThrough) => {
        try {
          const element = document.getElementById(`__clipara-mobile-carousel-${videos[index].id}-${campaign.id}`)
          if (videos[index].type !== 'VIDEO') {
            if (index < numberVideos - 1) {
              play(index + 1, runThrough)
            } else {
              if (runThrough < 6) {
                play(0, runThrough + 1)
              }
            }
            return
          }
          if (!element.hasAttribute('src')) {
            await loadVideoMP4({
              id: `__clipara-mobile-carousel-${videos[index].id}-${campaign.id}`,
              video: videos[index],
              campaign,
              playerName: 'stories',
              useClip: true,
              videoWidth
            })
            element.style.opacity = '1'
          } else {
            try {
              element.play()
              element.style.opacity = '1'
            } catch (e) {}
          }
          if (index < numberVideos - 1) {
            setTimeout(() => {
              document.getElementById(`__clipara-mobile-carousel-${videos[index].id}-${campaign.id}`).pause()
              const element = document.getElementById(`__clipara-mobile-carousel-${videos[index].id}-${campaign.id}`)
              element.pause()
              element.currentTime = 0
              element.style.opacity = '0'
              play(index + 1, runThrough)
            }, 5000)
          } else {
            setTimeout(() => {
              const element = document.getElementById(`__clipara-mobile-carousel-${videos[index].id}-${campaign.id}`)
              element.pause()
              element.currentTime = 0
              element.style.opacity = '0'
              if (runThrough < 6) {
                play(0, runThrough + 1)
              }
            }, 5000)
          }
        } catch (e) {
          console.log(e)
        }
      }
      await play(0, 1)
    }
    async()
  }, [trackedView])

  return (
    <Flex flexDirection="column" position="relative">
      {campaign.text && !showFullscreenPlayer ? (
        <Title
          color={titleColor}
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
          fontWeight={fontWeight}
          fontFamily={widgetFontFamily}
          textAlign={textAlign}
          width={'100%'}
        >
          {campaign.text}
        </Title>
      ) : null}
      <Slider
        shouldShowArrows={shouldShowArrows}
        position={position}
        ref={slider}
        mb={isStories ? '16px' : shouldShowArrows ? '16px' : '0px'}
      >
        {videos.map((video, i) => {
          const isVideo = video.type === 'VIDEO'
          const imageBoostFactor = 1.5
          const customThumbnailUrl =
            video.customMobileThumbnail || video.customThumbnail
              ? (video.customMobileThumbnail || video.customThumbnail).replace(
                  'https://ucarecdn.com/',
                  'https://customthumbnail.getclipara.com/'
                )
              : undefined
          const imageUrl = customThumbnailUrl
            ? customThumbnailUrl +
              `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(videoHeight * imageBoostFactor)}/center/`
            : `https://thumbnails.getclipara.com/${video.playbackId}/thumbnail.png?time=${
                video.thumbnailTime || 0.0
              }&width=${Math.round(videoWidth * imageBoostFactor)}&height=${Math.round(
                videoHeight * imageBoostFactor
              )}&fit_mode=crop`
          const webpUrl = customThumbnailUrl
            ? customThumbnailUrl +
              `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(
                videoHeight * imageBoostFactor
              )}/center/-/format/webp/`
            : imageUrl.replace('.png', '.webp')
          const durationPadding = getDurationPadding(borderRadius, widgetMobileThumbnailSize)
          const titleVideoObject =
            videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}

          // get next image url for lazy load
          let nextImageUrl, nextWebpUrl, nextImageIsVideo, nextImageImageUrl
          if (videos[i + 1]) {
            nextImageIsVideo = videos[i + 1].type === 'VIDEO'
            if (!nextImageIsVideo) {
              nextImageImageUrl = `https://clipara.b-cdn.net/${videos[i + 1].path}?aspect_ratio=${getAspectRatio(
                campaign.widgetThumbnailDimensions
              )}&width=${Math.round(videoWidth * 2)}`
            }
            const nextCustomThumbnailUrl =
              videos[i + 1].customMobileThumbnail || videos[i + 1].customThumbnail
                ? (videos[i + 1].customMobileThumbnail || videos[i + 1].customThumbnail).replace(
                    'https://ucarecdn.com/',
                    'https://customthumbnail.getclipara.com/'
                  )
                : undefined
            nextImageUrl = nextCustomThumbnailUrl
              ? nextCustomThumbnailUrl +
                `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(videoHeight * imageBoostFactor)}/center/`
              : `https://thumbnails.getclipara.com/${videos[i + 1].playbackId}/thumbnail.png?time=${
                  videos[i + 1].thumbnailTime || 0.0
                }&width=${Math.round(videoWidth * imageBoostFactor)}&height=${Math.round(
                  videoHeight * imageBoostFactor
                )}&fit_mode=crop`
            nextWebpUrl = nextCustomThumbnailUrl
              ? nextCustomThumbnailUrl +
                `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(
                  videoHeight * imageBoostFactor
                )}/center/-/format/webp/`
              : nextImageUrl.replace('.png', '.webp')
          }

          return (
            <Flex key={video.id} flexDirection="column" width="auto">
              <CarouselItem
                onClick={() => {
                  setActiveFullScreenVideo(i)
                  setShowFullscreenPlayer(true)
                  videos.forEach(async (video, i) => {
                    const element = document.getElementById(`__clipara-mobile-carousel-${video.id}-${campaign.id}`)
                    return element.pause()
                  })
                }}
                gapWidth={gapWidth}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                landscape={landscape}
                key={video.id}
                borderRadius={borderRadius}
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
              >
                <VideoContainer>
                  <Video
                    loop
                    videoWidth={videoWidth}
                    videoHeight={videoHeight}
                    isCircle={isCircle}
                    widgetThumbnailBorder={widgetThumbnailBorder}
                    borderRadius={borderRadius}
                    muted
                    id={`__clipara-mobile-carousel-${video.id}-${campaign.id}`}
                    preload="none"
                    poster={navigator.userAgent.includes('wv') ? imageUrl : undefined}
                    playsInline
                    webkit-playsInline
                    x-webkit-airplay="allow"
                  />
                  <VideoHack />
                </VideoContainer>
                {i < activeVideo + 5 ? (
                  isVideo ? (
                    <Picture>
                      <source srcset={webpUrl} type="image/webp" />
                      <source srcset={imageUrl} type="image/png" />
                      <VideoImage
                        isCircle={isCircle}
                        widgetThumbnailBorder={widgetThumbnailBorder}
                        loading="lazy"
                        src={imageUrl}
                        alt={video.name}
                        borderRadius={borderRadius}
                      />
                    </Picture>
                  ) : (
                    <VideoImage
                      isCircle={isCircle}
                      widgetThumbnailBorder={widgetThumbnailBorder}
                      loading="lazy"
                      src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(
                        campaign.widgetThumbnailDimensions
                      )}&width=${Math.round(videoWidth * 2)}`}
                      alt={video.name}
                      borderRadius={borderRadius}
                    />
                  )
                ) : null}
                {nextImageUrl && nextWebpUrl ? (
                  nextImageIsVideo ? (
                    <NextPicture>
                      <source srcset={nextWebpUrl} type="image/webp" />
                      <source srcset={nextImageUrl} type="image/png" />
                      <VideoImage
                        isCircle={isCircle}
                        widgetThumbnailBorder={widgetThumbnailBorder}
                        loading="lazy"
                        src={nextImageUrl}
                        alt={video.name}
                        borderRadius={borderRadius}
                      />
                    </NextPicture>
                  ) : (
                    <NextPicture>
                      <VideoImage
                        isCircle={isCircle}
                        widgetThumbnailBorder={widgetThumbnailBorder}
                        loading="lazy"
                        src={nextImageImageUrl}
                        alt={video.name}
                        borderRadius={borderRadius}
                      />
                    </NextPicture>
                  )
                ) : null}
                {isStories ? null : isVideo ? (
                  <PBContainer
                    widgetPlayButtonPosition={widgetPlayButtonPosition}
                    widgetMobileThumbnailSize={widgetMobileThumbnailSize}
                    animate={campaign.playButtonAnimation}
                    playButtonColor={playButtonColor || '#333333'}
                  >
                    <PlayButton
                      widgetMobileThumbnailSize={widgetMobileThumbnailSize}
                      animate={campaign.playButtonAnimation}
                      playButtonColor={playButtonColor || '#333333'}
                      width={36}
                      height={36}
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {widgetPlayButtonStyle === 'FILLED' ? (
                        <>
                          <circle cx="30" cy="30" r="30" fill={playButtonColor || '#333333'} />
                          <path
                            d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
                            fill="white"
                          />
                        </>
                      ) : (
                        <>
                          <circle cx="30" cy="30" r="28.5" stroke={playButtonColor || '#333333'} stroke-width="3" />
                          <path
                            d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
                            fill={playButtonColor || '#333333'}
                          />
                        </>
                      )}
                    </PlayButton>
                  </PBContainer>
                ) : null}

                {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'INSIDE' ? (
                  <InnerTitleContainer>
                    <Titles
                      isStories={isStories}
                      widgetTitleAlignment={widgetTitleAlignment}
                      widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
                      widgetMobileTitleFontSize={widgetMobileTitleFontSize}
                      widgetTitleFontColor={widgetTitleFontColor}
                      widgetTitleFontFamily={widgetTitleFontFamily}
                      widgetTitleFontWeight={widgetTitleFontWeight}
                      widgetSubtitleFontWeight={widgetSubtitleFontWeight}
                      titleVideoObject={titleVideoObject}
                      widgetMobileSubtitleFontSize={widgetMobileSubtitleFontSize}
                      widgetSubtitleFontFamily={widgetSubtitleFontFamily}
                      widgetSubtitleFontColor={widgetSubtitleFontColor}
                    />
                  </InnerTitleContainer>
                ) : null}
                {durationDisplay ? (
                  <VideoDuration durationPadding={durationPadding} widgetMobileThumbnailSize={widgetMobileThumbnailSize}>
                    {video.duration ? formatSeconds(video.duration) : ''}
                  </VideoDuration>
                ) : null}
              </CarouselItem>
              {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'BELOW' ? (
                <Flex mt="16px" maxWidth={videoWidth + 16} px="8px" boxSizing="border-box">
                  <Titles
                    isStories={isStories}
                    widgetTitleAlignment={widgetTitleAlignment}
                    widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
                    widgetMobileTitleFontSize={widgetMobileTitleFontSize}
                    widgetMobileSubtitleFontSize={widgetMobileSubtitleFontSize}
                    widgetTitleFontColor={widgetTitleFontColor}
                    widgetTitleFontFamily={widgetTitleFontFamily}
                    widgetTitleFontWeight={widgetTitleFontWeight}
                    widgetSubtitleFontWeight={widgetSubtitleFontWeight}
                    titleVideoObject={titleVideoObject}
                    widgetSubtitleFontFamily={widgetSubtitleFontFamily}
                    widgetSubtitleFontColor={widgetSubtitleFontColor}
                  />
                </Flex>
              ) : null}
            </Flex>
          )
        })}
        {shouldShowArrows && <Box width="8px" minWidth="8px" />}
      </Slider>
      {shouldShowArrows && !isStories && widgetMobileChevrons ? (
        <Flex justifyContent="flex-end" alignItems="center" display="flex !important">
          <LeftChevron
            widgetChevronSecondaryColor={widgetChevronSecondaryColor}
            widgetButtonStyle={widgetButtonStyle}
            widgetMobileThumbnailSize={widgetMobileThumbnailSize}
            mr="12px"
            isDisabled={activeVideo === 0}
            onClick={() => {
              slider.current.scrollBy(-videoWidth, 0)
            }}
          >
            <ChevronImage
              widgetChevronPrimaryColor={widgetChevronPrimaryColor}
              widgetButtonStyle={widgetButtonStyle}
              widgetMobileThumbnailSize={widgetMobileThumbnailSize}
              width={getChevronImgSize(widgetButtonStyle)}
              height={getChevronImgSize(widgetButtonStyle)}
            >
              {getChevronImg(widgetButtonStyle)}
            </ChevronImage>
          </LeftChevron>
          <RightChevron
            widgetChevronSecondaryColor={widgetChevronSecondaryColor}
            widgetButtonStyle={widgetButtonStyle}
            widgetMobileThumbnailSize={widgetMobileThumbnailSize}
            mr="8px"
            isDisabled={activeVideo === videos.length - 1}
            onClick={() => {
              slider.current.scrollBy(videoWidth, 0)
            }}
          >
            <ChevronImage
              widgetChevronPrimaryColor={widgetChevronPrimaryColor}
              widgetButtonStyle={widgetButtonStyle}
              widgetMobileThumbnailSize={widgetMobileThumbnailSize}
              width={getChevronImgSize(widgetButtonStyle)}
              height={getChevronImgSize(widgetButtonStyle)}
            >
              {getChevronImg(widgetButtonStyle)}
            </ChevronImage>
          </RightChevron>
        </Flex>
      ) : null}
      {!isStories && campaign.isFreeUser ? (
        <FreeLink widgetMobileChevrons={widgetMobileChevrons} href="https://getclipara.com/?utm_source=widget">
          <img width={104} height={12} src={`${baseUrl}/__clipara-powered-by.svg`} alt="powered by clipara" />
        </FreeLink>
      ) : null}
    </Flex>
  )
}

export default EmbedMobileCarousel
