import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Spinner from '../ui/Spinner'
import { loadSpecificVideo, loadVideoMP4, playPause, baseUrl, serverUrl, loadSpecificVideoMP4 } from '../helpers'
import { gaEvent, trackEvent } from '../tracking'
import { CarouselDownloadButton } from './ui/CarouselDownloadButton'
import { ProgressBar } from './ui/ProgressBar'

const End = styled.img`
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 100001 !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  cursor: pointer !important;
`

const FakePoster = styled.img`
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  ${(props) => (props.isMobile ? '' : 'border-radius:8px;')}
  ${(props) => `${!props.noInActiveBrightness && !props.isActive ? 'filter: brightness(40%);' : ''}`}
  max-width: 100% !important;
  max-height: 100% !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  ${(props) => (props.isLandscape ? 'width: 100% !important;' : 'height: 100% !important;')}
  object-fit: ${(props) => (props.fillPortrait ? 'cover' : 'contain')};
  ${(props) => props.fillPortrait && 'width: 100%;'}
  ${(props) => {
    if (props.useFixedRatio) {
      return !props.isLandscape
        ? 'width: 53.44vh !important; object-fit: cover !important;'
        : 'width: 50vw !important; height: 28.1vw; object-fit: cover !important;'
    }
  }}
`

const Player = styled.video`
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  ${(props) =>
    `${
      (!props.noInActiveBrightness && props.hasEnded) || (!props.noInActiveBrightness && !props.isActive)
        ? 'filter: brightness(40%);'
        : ''
    }`}
  border-radius: 8px !important;
  object-fit: ${(props) => (props.fillPortrait ? 'cover' : 'contain')} !important;
  max-width: 100% !important;
  max-height: 100% !important;
  z-index: 1;
  width: unset !important;
  ${(props) => (props.isLandscape ? 'width: 100% !important;' : 'height: 101% !important;')}
  ${(props) => props.fillPortrait && 'width: 100% !important;'}
  ${(props) => {
    if (props.useFixedRatio) {
      return !props.isLandscape
        ? 'width: 53.44vh !important; object-fit: cover !important;'
        : 'width: 50vw !important; height: 28.1vw; object-fit: cover !important;'
    }
  }}
    @media (max-width: 700px) {
    border-radius: 0px !important;
  }
  &&::-webkit-media-controls {
    display: none !important;
  }
  /* Could Use thise as well for Individual Controls */
  &&::-webkit-media-controls-play-button {
    display: none !important;
  }
  &&::-webkit-media-controls-volume-slider {
    display: none !important;
  }
  &&::-webkit-media-controls-mute-button {
    display: none !important;
  }
  &&::-webkit-media-controls-timeline {
    display: none !important;
  }
  &&::-webkit-media-controls-current-time-display {
    display: none !important;
  }
`

const Image = styled.img`
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  ${(props) =>
    `${
      (!props.noInActiveBrightness && props.hasEnded) || (!props.noInActiveBrightness && !props.isActive)
        ? 'filter: brightness(40%);'
        : ''
    }`}
  border-radius: 8px !important;
  object-fit: ${(props) => (props.fillPortrait ? 'cover' : 'contain')} !important;
  max-width: 100% !important;
  max-height: 100% !important;
  z-index: 1;
  width: unset !important;
  ${(props) => (props.isLandscape ? 'width: 100% !important;' : 'height: 101% !important;')}
  ${(props) => props.fillPortrait && 'width: 100% !important;'}
  ${(props) => {
    if (props.useFixedRatio) {
      return !props.isLandscape
        ? 'width: 53.44vh !important; object-fit: cover !important;'
        : 'width: 50vw !important; height: 28.1vw; object-fit: cover !important;'
    }
  }}
    @media (max-width: 700px) {
    border-radius: 0px !important;
  }
`

const Button = styled.button`
  cursor: pointer !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border: ${(props) => props.border} !important;
  font-family: 'Inter';
  height: 42px !important;
  border-radius: ${(props) => (typeof props.borderRadius === 'number' ? props.borderRadius : 8)}px !important;
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  width: ${(props) => `calc(${props.videoWidth} - 32px)`} !important;
  position: absolute;
  bottom: ${(props) => {
    if (props.isMobile) {
      return props.noCliparaLogo ? '16px' : '30px'
    }
    return '16px'
  }};
  left: 0px;
  z-index: 110001;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 0);
  outline: inherit;
  ${(props) =>
    !props.buttonFullWidth
      ? `width: fit-content !important; padding-left: 16px !important; padding-right: 16px !important;`
      : null}
  p {
    color: ${(props) => props.color} !important;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const PlayButton = styled.img`
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  z-index: 101;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100px !important;
  height: 100px !important;
`

const FullscreenPlayer = ({
  playerId,
  buttonVideoObject,
  campaign,
  video,
  isActive,
  fillPortrait,
  noAutoplay,
  noPoster,
  moveToNextVideo,
  noInActiveBrightness,
  optimiseLoad,
  isMobile,
  isMuted,
  noCliparaLogo,
  useFixedRatio,
  videoWidth
}) => {
  const [hasEnded, setHasEnded] = useState(false)
  const [hasPlayed, setHasPlayed] = useState('NOT_YET')
  const [playCount, setPlayCount] = useState(0)

  const isWaitingRef = useRef()
  const [isWaiting, setIsWaiting] = useState(false)
  const [showWaitingSpinner, setShowWaitingSpinner] = useState(false)
  const player = useRef()
  const isVideo = video.type === 'VIDEO'

  const tracking = () => {
    gaEvent({
      isGaEnabled: campaign?.gaTracking,
      name: 'clipara_video_play',
      data: {
        campaign_name: campaign?.name,
        video_name: video?.name
      }
    })
    trackEvent({
      type: 'PLAY',
      campaignId: campaign.id,
      videoId: video.id,
      playerType: campaign.autoOpen ? 'SHARE' : 'WIDGET',
      widgetId: campaign.widgetId,
      productIds: campaign.videoProducts.filter((p) => p.videoId === video.id).map((p) => p.product.id)
    })
  }

  useEffect(() => {
    isWaitingRef.current = isWaiting
    if (isWaiting) {
      setTimeout(() => {
        if (isWaitingRef.current) {
          setShowWaitingSpinner(true)
        }
      }, 1000)
    } else {
      setShowWaitingSpinner(false)
    }
  }, [isWaiting])

  useEffect(() => {
    if (hasEnded) {
      moveToNextVideo && moveToNextVideo()
      setPlayCount(0)
    }
  }, [hasEnded])

  //desktop
  useEffect(() => {
    if (isActive && !isMobile && isVideo) {
      setPlayCount(0)
      player.current?.setAttribute('oncontextmenu', 'return false;')
      loadSpecificVideo({
        campaign: campaign,
        playerName: 'widget',
        campaignId: campaign.id,
        organisationId: campaign.organisationId,
        video,
        playerId,
        setHasEnded,
        setHasPlayed,
        noAutoplay,
        inputPlayer: player.current,
        widgetId: campaign.widgetId,
        productIds: campaign.videoProducts.filter((p) => p.videoId === video.id).map((p) => p.product.id)
      })
      player.current.addEventListener('waiting', () => {
        setIsWaiting(true)
      })
      player.current.addEventListener('playing', () => {
        setIsWaiting(false)
      })
      player.current.addEventListener('play', () => {
        setHasPlayed(true)
        if (player.current.currentTime < 0.1) {
          tracking()
        }
      })
    }
    if (!isVideo) {
      tracking()
    }
  }, [isActive])

  //mobile
  useEffect(() => {
    if (!optimiseLoad && isMobile) {
      setPlayCount(0)
      player.current?.setAttribute('oncontextmenu', 'return false;')
      if (!player.current.src && !player.current?.innerHTML?.includes('source')) {
        const x = (campaign.useAdaptiveBitrate || campaign.useAdaptiveBitrateCampaign ? loadSpecificVideo : loadSpecificVideoMP4)(
          {
            campaign: campaign,
            playerName: 'widget',
            campaignId: campaign.id,
            organisationId: campaign.organisationId,
            video,
            playerId,
            setHasEnded,
            setHasPlayed,
            noAutoplay,
            inputPlayer: player.current,
            widgetId: campaign.widgetId,
            productIds: campaign.videoProducts.filter((p) => p.videoId === video.id).map((p) => p.product.id)
          }
        )
      }

      player.current.addEventListener('waiting', () => {
        setIsWaiting(true)
      })
      player.current.addEventListener('playing', () => {
        setIsWaiting(false)
      })

      player.current.addEventListener('play', () => {
        setHasPlayed(true)
        if (player.current.currentTime < 0.1) {
          tracking()
        }
      })
    }
  }, [optimiseLoad])

  const isLandscape = isVideo ? video.aspectRatioCalc > 1 : video.width > video.height
  const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  const posterHeight = Math.round(h * 0.8)
  const posterWidth = Math.round(h * 0.8 * video.aspectRatioCalc)
  const poster = `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime}&width=${posterWidth}&height=${posterHeight}&fit_mode=pad`
  const posterFirstFrame = `https://image.mux.com/${video.playbackId}/thumbnail.png?time=0&width=${posterWidth}&height=${posterHeight}&fit_mode=pad`
  const webpUrl = poster.replace('.png', '.webp')
  const webpUrlFirstFrame = posterFirstFrame.replace('.png', '.webp')

  let buttonUrl = buttonVideoObject
    ? `${serverUrl}/campaign/click?url=${encodeURIComponent(buttonVideoObject.buttonLink)}&campaignId=${campaign.id}&type=WIDGET`
    : ''

  // HACK FOR IFRAME HOMEPAGE
  if (buttonVideoObject && buttonVideoObject.buttonLink === 'CLIPARA_TEST') {
    buttonUrl = null
  }

  let width = 'auto'
  let height = 'auto'
  if (isLandscape) {
    width = `100%`
  } else {
    height = `100%`
  }

  //desktop
  if (optimiseLoad && !isMobile) {
    return null
  }

  const hideProgressMobileLong = isMobile && campaign.videos.length > 50

  return (
    <>
      {!noPoster && !optimiseLoad && isVideo ? (
        <picture>
          <source srcset={webpUrlFirstFrame} type="image/webp" />
          <source srcset={posterFirstFrame} type="image/png" />
          <FakePoster
            isMobile={isMobile}
            noInActiveBrightness={noInActiveBrightness}
            isActive={isActive}
            useFixedRatio={useFixedRatio}
            isLandscape={isLandscape}
            fillPortrait={fillPortrait && !isLandscape}
            width={width}
            height={height}
            src={posterFirstFrame}
          />
        </picture>
      ) : null}
      {isVideo ? (
        <Player
          isActive={isActive}
          useFixedRatio={useFixedRatio}
          muted={isMuted}
          ref={player}
          width={width}
          height={height}
          fillPortrait={fillPortrait && !isLandscape}
          onClick={() => playPause(playerId)}
          id={`${playerId}`}
          preload="metadata"
          playsInline
          webkit-playsInline
          x-webkit-airplay="allow"
          isLandscape={isLandscape}
          noInActiveBrightness={noInActiveBrightness}
          // poster={!noAutoplay ? poster : undefined}
          hasEnded={hasEnded}
          oncontextmenu="return false;"
        />
      ) : (
        <Image
          src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${isLandscape ? '16:9' : '9:16'}&width=${Math.round(
            posterWidth * 2
          )}`}
          isActive={isActive}
          useFixedRatio={useFixedRatio}
          muted={isMuted}
          ref={player}
          width={width}
          height={height}
          fillPortrait={fillPortrait && !isLandscape}
          id={`${playerId}`}
          isLandscape={isLandscape}
          noInActiveBrightness={noInActiveBrightness}
        />
      )}
      {isActive && showWaitingSpinner && <Spinner zIndex={100} />}
      {isActive && !hasPlayed && (
        <PlayButton
          onClick={() => {
            playPause(playerId)
            if (isMobile) {
              campaign.videos.map((video) => {
                const element = document.getElementById(`__clipara-player-${video.id}`)
                if (!element) {
                  return
                }
                element.muted = true
                element.muted = false
              })
            }
            setHasPlayed(true)
          }}
          src={`${baseUrl}/__clipara-play-button.svg`}
        />
      )}
      {isActive &&
      buttonVideoObject &&
      buttonVideoObject.buttonText &&
      buttonVideoObject.buttonLink &&
      !campaign.isDownloadCampaign ? (
        <a rel="noreferrer" href={buttonUrl} target={campaign.openButtonLinksNewTab ? '_blank' : '_self'}>
          <Button
            isMobile={isMobile}
            videoWidth={videoWidth}
            noCliparaLogo={noCliparaLogo}
            buttonFullWidth={campaign.buttonFullWidth}
            onClick={() => {
              gaEvent({
                isGaEnabled: campaign?.gaTracking,
                name: 'clipara_click',
                data: {
                  campaign_name: campaign?.name,
                  video_name: video?.name
                }
              })
              trackEvent({
                type: 'CLICK',
                campaignId: campaign.id,
                videoId: video.id,
                playerType: campaign.autoOpen ? 'SHARE' : 'WIDGET',
                widgetId: campaign.widgetId
              })
              try {
                const element = document.getElementById(playerId)
                element.pause()
                setHasPlayed(false)
              } catch (e) {}
            }}
            borderRadius={campaign.buttonBorderRadius}
            backgroundColor={campaign.buttonRgba || campaign.buttonColor}
            color={campaign.buttonFontColor}
            border={`2px solid ${campaign.buttonBorderColor}`}
          >
            <p>{buttonVideoObject.buttonText}</p>
          </Button>
        </a>
      ) : null}
      {isActive && !optimiseLoad && campaign.isDownloadCampaign && (!window.__clipara?.autoOpen || campaign.isShareCampaign) ? (
        <CarouselDownloadButton activeResult={video} campaign={campaign} />
      ) : null}
      {isActive && hasEnded ? (
        <End onClick={() => playPause(playerId)} alt="restart icon" src={`${baseUrl}/__clipara-restart-white.svg`} />
      ) : null}
      {isVideo && isActive && !optimiseLoad && !hideProgressMobileLong ? <ProgressBar videoRef={player} /> : null}
    </>
  )
}

export default FullscreenPlayer
