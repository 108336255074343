import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout.js'
import { loadVideoMP4, formatSeconds, getContainerElement, loadVideoLite, baseUrl } from '../helpers.js'
import { gaEvent, trackEvent } from '../tracking.js'
import { getChevronStyling, getChevronImg, getChevronImgSize } from './chevronStyles.js'
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import InlineSlideItem from './InlineSlideItem.js'

export const FreeLink = styled.a`
  position: absolute !important;
  left: 0 !important;
  bottom: -24px !important;
  margin-left: 16px !important;
`

const Alignment = styled(Flex)`
  flex-direction: column;
  align-items: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
  position: relative;
  margin-bottom: 24px;
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor, props.isStories)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
  ${(props) => (props.isSmallControls ? `width: 32px; height: 32px; min-width: 32px;` : '')}
`

const ChevronImage = styled(Flex)`
  ${(props) =>
    props.isStories
      ? `svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }`
      : ''}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }
  ${(props) => (props.isSmallControls ? `width: 16px !important; height: 16px !important; min-width: 16px !important;` : '')}
`

const LeftChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-left: 8px;'}
  ${(props) => props.hide && 'display:none;'}
`

const RightChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-right: 8px;'}
  ${(props) => props.hide && 'display:none;'}
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  box-sizing: border-box;
  word-break: break-word;
`

const getVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth * 9) / 16
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth * 3) / 2
  }
  // PORTRAIT
  return (videoWidth * 16) / 9
}

const getNumberVideoOnScreen = (widgetMobileThumbnailSize) => {
  if (widgetMobileThumbnailSize === 'MEDIUM') {
    return 2.5
  }
  if (widgetMobileThumbnailSize === 'SMALL') {
    return 3.5
  }
  return 1.2
}

const getOffsetForSize = (widgetMobileThumbnailSize, videoWidth) => {
  if (widgetMobileThumbnailSize === 'MEDIUM') {
    return (1 / videoWidth) * 5
  }
  if (widgetMobileThumbnailSize === 'SMALL') {
    return (1 / videoWidth) * 3.6
  }
  return (1 / videoWidth) * 12
}

const NavigationMobileCarousel = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo }) => {
  const [resize, setResize] = useState()
  const {
    videos,
    position,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    videoObjects,
    widgetArrowPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetMobileThumbnailDimensions,
    widgetThumbnailDimensions,
    borderRadius: inputBorderRadius,
    widgetIsPartialStart,
    type,
    widgetAutoplay,
    widgetMobileChevrons,
    widgetMobileThumbnailSize,
    desktopVideos
  } = campaign
  const [inView, setInView] = useState(false)
  const [activeVideo, setActiveVideo] = useState(widgetIsPartialStart ? Math.floor(desktopVideos / 2) : 0)
  const [isMuted, setIsMuted] = useState(true)
  const [isPaused, setIsPaused] = useState(true)
  const [hasStarted, setHasStarted] = useState(false)
  const [clipLoaded, setClipLoaded] = useState(false)

  const isStories = type === 'STORIES'
  const containerElement = getContainerElement(campaign.id)
  const dimensions = isStories ? widgetThumbnailDimensions : widgetMobileThumbnailDimensions || widgetThumbnailDimensions
  const containerWidth = containerElement && containerElement.clientWidth
  const videoWidth =
    (containerWidth - (getNumberVideoOnScreen(widgetMobileThumbnailSize) - 1) * 16) /
    getNumberVideoOnScreen(widgetMobileThumbnailSize)
  const videoHeight = getVideoHeight(dimensions, videoWidth)
  const isCircle = dimensions === 'CIRCLE'
  const borderRadius = isCircle ? videoWidth / 2 : inputBorderRadius
  const noArrows = videos.length === 1 || !widgetMobileChevrons

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const leftChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.prev()
  const rightChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.next()
  const sliderComponentRef = useRef()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: true,
    loop: true,
    initial: widgetIsPartialStart ? Math.floor(desktopVideos / 2) : 0,
    renderMode: 'performance',
    slideChanged(s) {
      setActiveVideo(s.track.details.rel)
    },
    animationEnded(s) {
      // setActiveVideo(s.track.details.rel)
    },
    slides: {
      perView: getNumberVideoOnScreen(widgetMobileThumbnailSize),
      spacing: 16,
      origin: widgetIsPartialStart ? 'center' : getOffsetForSize(widgetMobileThumbnailSize, videoWidth)
    }
  })

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
      rootMargin: '400px'
    }
    if (widgetAutoplay) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          setInView(true)
        } else {
          setInView(false)
        }
      }, options)
      observer.observe(sliderComponentRef.current)
    }
  }, [campaign && campaign.id])

  useEffect(() => {
    if (!clipLoaded) {
      const video = campaign.videos[widgetIsPartialStart ? Math.floor(desktopVideos / 2) : 0]
      if (video.type !== 'VIDEO') {
        return
      }
      const playerId = `__clipara-inline-carousel-clip-${video.id}-${campaign.id}`
      loadVideoMP4({
        id: playerId,
        video,
        campaign,
        playerName: 'inline-carousel-autoplay',
        useClip: true,
        videoWidth
      })
    }
  }, [inView])

  useEffect(() => {
    campaign.videos.forEach((v, i) => {
      if (v.type !== 'VIDEO') {
        return
      }
      if (!hasStarted && activeVideo === (widgetIsPartialStart ? Math.floor(desktopVideos / 2) : 0)) {
        return
      }
      setIsPaused(false)
      const playerId = `__clipara-inline-carousel-${v.id}-${campaign.id}`
      const element = document.getElementById(playerId)
      if (!element) {
        return
      }
      if (i === activeVideo) {
        if (element.src) {
          setHasStarted(true)
          element
            .play()
            .then(() => {
              setHasStarted(true)
            })
            .catch((e) => {
              console.log(e)
              setHasStarted(false)
            })
          element.currentTime = 0
          element.style.opacity = '1'
          return
        } else {
          gaEvent({
            isGaEnabled: campaign?.gaTracking,
            name: 'clipara_video_play',
            data: {
              campaign_name: campaign?.name,
              video_name: v?.name
            }
          })
          trackEvent({
            type: 'PLAY',
            campaignId: campaign.id,
            videoId: v.id,
            playerType: campaign.autoOpen ? 'SHARE' : 'WIDGET',
            widgetId: campaign.widgetId,
            productIds: campaign.videoProducts.filter((p) => p.videoId === v.id).map((p) => p.product.id)
          })
          loadVideoMP4({
            id: playerId,
            video: v,
            campaign,
            playerName: 'inline-carousel-autoplay',
            useClip: false,
            videoWidth,
            setHasPlayed: (x) => setHasStarted(x),
            useHigh: true
          })
          element.currentTime = 0
          element.style.opacity = '1'
          return
        }
      } else {
        element.pause()
        element.currentTime = 0
        element.style.opacity = '0'
        return
      }
    })
  }, [activeVideo])

  return (
    <>
      <Alignment position={position}>
        {text ? (
          <Title
            isStories={isStories}
            color={titleColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            fontFamily={widgetFontFamily}
            textAlign={textAlign}
            width={'100%'}
          >
            {text}
          </Title>
        ) : null}
        <Flex width="100%" ref={sliderComponentRef}>
          <Flex
            justifyContent={videos.length === 1 ? 'center' : 'flex-start'}
            ref={sliderRef}
            className="keen-slider"
            width="900000px"
          >
            {videos.map((video, i) => {
              return (
                <InlineSlideItem
                  isPaused={isPaused}
                  isMobile
                  setIsPaused={setIsPaused}
                  campaign={campaign}
                  key={video.id}
                  videoHeight={videoHeight}
                  videoWidth={videoWidth}
                  activeVideoIndex={activeVideo}
                  videosToShow={2}
                  i={i}
                  isActive={activeVideo === i}
                  video={video}
                  videoObjects={videoObjects}
                  borderRadius={borderRadius}
                  isStories={isStories}
                  isCircle={isCircle}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  hasStarted={hasStarted}
                  setHasStarted={setHasStarted}
                  setActive={() => {
                    instanceRef.current?.moveToIdx(i)
                  }}
                />
              )
            })}
          </Flex>
          {campaign.isFreeUser ? (
            <FreeLink href="https://www.getclipara.com/?utm_source=widget">
              <img width={104} height={12} src={`${baseUrl}/__clipara-powered-by.svg`} alt="powered by clipara" />
            </FreeLink>
          ) : null}
        </Flex>
        {!noArrows ? (
          <Flex mr="16px" mt="16px" justifyContent="flex-end" alignItems="center" display="flex !important" width="100%">
            <LeftChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              widgetArrowPosition={widgetArrowPosition}
              onClick={leftChevronOnClick}
              mr="8px"
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={false}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </LeftChevron>
            <RightChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              widgetArrowPosition={widgetArrowPosition}
              onClick={rightChevronOnClick}
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={false}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </RightChevron>
          </Flex>
        ) : null}
      </Alignment>
    </>
  )
}

export default NavigationMobileCarousel
