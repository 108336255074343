import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import Spinner from '../ui/Spinner'
import FullScreenPlayer from '../Components/FullScreenPlayer'
import { playPause, baseUrl } from '../helpers'

const VideoContainer = styled(Flex)`
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  position: relative;
  ${(props) => (props.isOnlyOneVideo ? 'width: 90vw; height: 90vh; left: 5vw;' : '')}
  ${(props) => (props.shouldShiftLandscapeRight ? 'transform: translate(10%);' : '')}
  ${(props) => (props.shouldShiftLandscapeLeft ? 'transform: translate(-10%);' : '')}
`

const InnerVideoContainer = styled(Flex)`
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
`

const CarouselContainer = styled(Flex)`
  width: ${(props) => (props.isOnlyOneVideo ? '100%' : 'auto')};
  position: absolute;
  align-items: center;
  top: 0;
  overflow: hidden;
  height: 100%;
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  left: ${(props) => props.activeVideoIndex * -25}vw;
`

const Chevron = styled(Flex)`
  width: 40px !important;
  height: 40px !important;
  img {
    width: 24px !important;
    height: 24px !important;
  }
  position: fixed;
  z-index: 110001;
  background-color: rgba(91, 91, 91, 0.4);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  top: 50vh;
  ${(props) => props.disabled && `opacity: 0.4; cursor: default;`}
`

const LeftChevron = styled(Chevron)`
  left: 30vw;
  ${(props) =>
    props.hasLandscapeVideo &&
    `position:fixed; 
    right: 120px; 
    bottom: 32px; 
    top:auto;
    left:auto;`}
`

const RightChevron = styled(Chevron)`
  right: 30vw;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  ${(props) =>
    props.hasLandscapeVideo &&
    `position:fixed; 
    right: 32px; 
    bottom: 32px; 
    top:auto; 
    left:auto;`}
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';', '') : 'inherit')} !important;
  font-weight: 700 !important;
  font-size: ${(props) => props.titleFontSize || '16'}px !important;
  line-height: ${(props) => (props.titleFontSize ? props.titleFontSize + 2 : '18')}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.hasSubtitle ? '6px' : '16px')} !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: white;
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';', '') : 'inherit')} !important;
  font-weight: 500 !important;
  font-size: ${(props) => props.subtitleFontSize || '14'}px !important;
  line-height: ${(props) => (props.subtitleFontSize ? props.subtitleFontSize + 2 : '16')}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: white;
  }
`

const Titles = ({
  hasNonShoppableButton,
  hasShoppableButtonSmall,
  hasShoppableButtonLarge,
  titleActive,
  titleFontFamily,
  titleColor,
  titleAlignment,
  titleFontSize,
  subtitleColor,
  subtitleFontSize,
  subtitleActive,
  titleVideoObject
}) => {
  let paddingBottom = '0px'
  if (hasNonShoppableButton) {
    paddingBottom = '60px'
  }
  if (hasShoppableButtonSmall) {
    paddingBottom = '100px'
  }
  if (hasShoppableButtonLarge) {
    paddingBottom = '120px'
  }

  return (
    <Flex flexDirection="column" width="auto" pb={paddingBottom}>
      {titleVideoObject.title && titleActive && (
        <VideoTitle
          titleFontSize={titleFontSize}
          titleAlignment={titleAlignment}
          titleColor={titleColor}
          titleFontFamily={titleFontFamily}
          hasSubtitle={titleVideoObject.subtitle && subtitleActive}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && subtitleActive && (
        <VideoSubtitle
          titleAlignment={titleAlignment}
          subtitleFontSize={subtitleFontSize}
          subtitleColor={subtitleColor}
          titleFontFamily={titleFontFamily}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  box-sizing: border-box !important;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 50px;
  flex-direction: column-reverse;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  left: 50% !important;
  transform: translate(-50%);
  border-radius: 0px 0px 8px 8px;
`

const VideoElement = ({ index, video, campaign, activeVideoIndex, setActiveVideoIndex, isOnlyOneVideo }) => {
  const isActive = index === activeVideoIndex
  const optimiseLoad = index > activeVideoIndex + 4 || index < activeVideoIndex - 4
  const buttonVideoObject = campaign.videoObjects.find(
    (el) => el.videoId.toString() === video.id.toString() && el.type === 'BUTTON'
  )
  const isLandscape = video.aspectRatioCalc > 1
  const shouldShiftLandscapeLeft = index === activeVideoIndex - 1 && isLandscape
  const shouldShiftLandscapeRight = index === activeVideoIndex + 1 && isLandscape
  const width = isActive ? '50vw' : '25vw'
  const height = isActive ? '95vh' : '40vh'
  const titleVideoObject =
    campaign.videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}
  const videoWidth = isActive ? (isLandscape ? '50vw' : '53.43vh') : isLandscape ? '25vw' : '26.715vh'
  const videoHeight = isActive ? (isLandscape ? '28.125vw' : '95vh') : isLandscape ? '14.06vw' : '47.5vh'
  const marginBottom = isLandscape ? 'calc((95vh - 28vw) / 2)' : '0'
  return (
    <VideoContainer
      id="container"
      onClick={() => {
        if (isActive) {
          return
        }
        setActiveVideoIndex(index)
      }}
      shouldShiftLandscapeLeft={shouldShiftLandscapeLeft}
      shouldShiftLandscapeRight={shouldShiftLandscapeRight}
      index={index}
      activeVideoIndex={activeVideoIndex}
      isLandscape={isLandscape}
      isActive={isActive}
      position="relative"
      height={height}
      minWidth={width}
      width={width}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      isOnlyOneVideo={isOnlyOneVideo}
    >
      <InnerVideoContainer position="relative" width={videoWidth} height={videoHeight}>
        <FullScreenPlayer
          videoWidth={videoWidth}
          moveToNextVideo={() => {
            if (index < campaign.videos.length - 1) {
              setActiveVideoIndex(index + 1)
            }
          }}
          useFixedRatio
          isActive={isActive}
          buttonVideoObject={buttonVideoObject}
          playerId={`__clipara-player-carousel-${video.id}`}
          campaign={campaign}
          video={video}
          optimiseLoad={optimiseLoad}
        ></FullScreenPlayer>
      </InnerVideoContainer>
      {campaign.titleActive && titleVideoObject && titleVideoObject.id && isActive ? (
        <InnerTitleContainer width={videoWidth} mb={marginBottom}>
          <Titles
            hasNonShoppableButton={!!buttonVideoObject}
            titleFontSize={campaign.titleFontSize}
            titleActive={campaign.titleActive}
            titleFontFamily={campaign.titleFontFamily}
            titleColor={campaign.titleColor}
            titleAlignment={campaign.titleAlignment}
            subtitleColor={campaign.subtitleColor}
            subtitleFontSize={campaign.subtitleFontSize}
            subtitleActive={campaign.subtitleActive}
            titleVideoObject={titleVideoObject}
            isLandscape={isLandscape}
          />
        </InnerTitleContainer>
      ) : null}
    </VideoContainer>
  )
}

const Carousel = ({ campaign, activeFullScreenVideo, setActiveFullScreenVideo }) => {
  const hasLandscapeVideo = campaign.videos.find((video) => video.aspectRatioCalc > 1)
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(activeFullScreenVideo)
  const [animateIndex, setAnimateIndex] = useState(activeFullScreenVideo)
  const isOnlyOneVideo = campaign.videos.length === 1
  useEffect(() => {
    setCarouselActiveIndex(activeFullScreenVideo)
  }, [activeFullScreenVideo])

  useEffect(() => {
    setAnimateIndex(carouselActiveIndex)
  }, [carouselActiveIndex])

  useEffect(() => {
    try {
      campaign.videos.map((video, i) => {
        if (video.type !== 'VIDEO') {
          return
        }
        const element = document.getElementById(`__clipara-player-carousel-${video.id}`)
        if (!element) {
          return
        }
        if (campaign.videos[activeFullScreenVideo].id !== video.id) {
          element.pause()
        } else {
          if (element.src) {
            element.play()
          }
        }
        element.currentTime = 0
      })
    } catch (e) {
      console.log(e)
    }
  }, [carouselActiveIndex])

  return (
    <>
      <CarouselContainer id="__clipara-carousel" activeVideoIndex={animateIndex} isOnlyOneVideo={isOnlyOneVideo}>
        {!isOnlyOneVideo ? <Flex minWidth="25vw" width="25vw" display="block !important" /> : null}
        {campaign.videos.map((video, index) => {
          return (
            <VideoElement
              key={index}
              isOnlyOneVideo={isOnlyOneVideo}
              activeVideoIndex={activeFullScreenVideo}
              index={index}
              video={video}
              campaign={campaign}
              setActiveVideoIndex={setActiveFullScreenVideo}
            />
          )
        })}
      </CarouselContainer>
      {activeFullScreenVideo !== 0 ? (
        <LeftChevron
          disabled={activeFullScreenVideo === 0}
          hasLandscapeVideo={hasLandscapeVideo}
          onClick={() => {
            if (activeFullScreenVideo === 0) return
            setActiveFullScreenVideo(activeFullScreenVideo - 1)
          }}
        >
          <img src={`${baseUrl}/__clipara-chevron-left-white.svg`} alt="chevron icon" width="24px" height="24px" />
        </LeftChevron>
      ) : null}
      {activeFullScreenVideo !== campaign.videos.length - 1 ? (
        <RightChevron
          disabled={activeFullScreenVideo === campaign.videos.length - 1}
          hasLandscapeVideo={hasLandscapeVideo}
          onClick={() => {
            if (activeFullScreenVideo === campaign.videos.length - 1) return
            setActiveFullScreenVideo(activeFullScreenVideo + 1)
          }}
        >
          <img src={`${baseUrl}/__clipara-chevron-left-white.svg`} alt="chevron icon" width="24px" height="24px" />
        </RightChevron>
      ) : null}
    </>
  )
}

export default Carousel
