import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

const containers = Array.from(document.querySelectorAll('[id^=__clipara-root]'))
let singleAlreadyInserted = false

containers.forEach((container) => {
  if (container.id === '__clipara-root') {
    if (singleAlreadyInserted) {
      console.log('SINGLE ALREADY INSERTED')
      return
    }
    singleAlreadyInserted = true
    const root = ReactDOM.createRoot(container)
    return root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  } else {
    const root = ReactDOM.createRoot(container)
    return root.render(
      <React.StrictMode>
        <App campaignId={Number(container.id.replace('__clipara-root-', ''))} />
      </React.StrictMode>
    )
  }
})
