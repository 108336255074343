import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { gaEvent, trackEvent } from '../../tracking.js'
import axios from 'axios'
import { DOWNLOAD_URL_URL, baseUrl, downloadUrlUrl, formatSeconds, getDownloadUrlUrl } from '../../helpers.js'

import Spinner, { DarkBlockSpinner } from '../../ui/Spinner.js'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 62px;
  right: 16px;
  width: 160px !important;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-sizing: border-box !important;
  cursor: pointer;
`

const ButtonComp = styled.button`
  cursor: pointer !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border: ${(props) => props.border} !important;
  font-family: 'Inter';
  height: 42px !important;
  border-radius: ${(props) => props.buttonBorderRadius}px;
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  margin-right: ${(props) => (props.isMobile ? '6px' : '8px')};
  padding-left: 17px !important;
  padding-right: 17px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit !important;
  margin-bottom: 8px !important;
  &&:focus {
    outline: inherit !important;
  }
  p {
    color: ${(props) => props.color};
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 500 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const Button = ({ label, ...rest }) => {
  return (
    <ButtonComp {...rest}>
      <p>{label}</p>
    </ButtonComp>
  )
}

const DownloadContainer = styled(Flex)`
  right: 8px;
  bottom: 8px;
`

const PWithMargin = styled.p`
  margin-right: 10px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
`

const PWithMargin2 = styled.p`
  margin-right: 16px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
`

const MenuText = styled.p`
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100%;
`

const Span = styled.span`
  margin-left: 6px !important;
  font-weight: 400;
  color: #7a7a9d !important;
`

function downloadURI(uri, name) {
  const link = document.createElement('a')
  link.download = `${name}`
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function isWithin24Hours(isoDate) {
  const targetDate = new Date(isoDate)
  const differenceInMilliseconds = Math.abs(targetDate - Date.now())
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)
  return differenceInHours < 24
}

export const CarouselDownloadButton = ({ activeResult, campaign }) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)

  const useMedium = activeResult.maxHeight && activeResult.maxHeight * activeResult.aspectRatioCalc < 1080 ? true : false
  const isOriginal =
    activeResult.maxHeight &&
    activeResult.aspectRatioCalc &&
    activeResult.maxHeight * activeResult.aspectRatioCalc > 1080 &&
    isWithin24Hours(activeResult.masterAccessTimestamp)

  let originalQuality = '2k'
  if (activeResult.maxHeight && activeResult.aspectRatioCalc && activeResult.maxHeight * activeResult.aspectRatioCalc > 2000) {
    originalQuality = '4k'
  }

  if (
    activeResult.type === 'IMAGE' ||
    !isOriginal ||
    (activeResult?.tagsArray?.includes('High Quality Download') && isWithin24Hours(activeResult.masterAccessTimestamp))
  ) {
    let downloadUrl =
      activeResult.type === 'IMAGE'
        ? `https://clipara-original.b-cdn.net${activeResult.path}`
        : `https://stream.mux.com/${activeResult.playbackId}/${useMedium ? 'medium' : 'high'}.mp4?download=${encodeURIComponent(
            activeResult.name
          )}`

    if (activeResult?.tagsArray?.includes('High Quality Download') && isWithin24Hours(activeResult.masterAccessTimestamp)) {
      downloadUrl = activeResult.masterAccessUrl
    }
    return (
      <DownloadContainer
        onClick={async () => {
          setShowSpinner(true)
          try {
            gaEvent({
              isGaEnabled: campaign?.gaTracking,
              name: 'clipara_click',
              data: {
                campaign_name: campaign?.name,
                video_name: activeResult?.name
              }
            })
            trackEvent({
              type: 'CLICK',
              campaignId: campaign.id,
              videoId: activeResult.id,
              playerType: 'WIDGET',
              widgetId: campaign.widgetId
            })
          } catch (e) {}
          downloadURI(downloadUrl, activeResult.name)
          setTimeout(() => {
            setShowSpinner(false)
          }, 1500)
        }}
        width="auto"
        position="absolute"
        zIndex={2}
      >
        <ButtonComp
          color={campaign.buttonFontColor}
          backgroundColor={campaign.buttonRgba || campaign.buttonColor}
          buttonBorderRadius={campaign.buttonBorderRadius}
          border={`1px solid ${campaign.buttonFontColor}`}
        >
          <Flex>
            <PWithMargin>Download</PWithMargin>
            {showSpinner ? (
              <DarkBlockSpinner color={campaign.buttonFontColor} />
            ) : (
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.543478 10.0651C0.843633 10.0651 1.08696 10.3084 1.08696 10.6085V13.3259C1.08696 13.9262 1.5736 14.4129 2.17391 14.4129H15.2174C15.8177 14.4129 16.3043 13.9262 16.3043 13.3259V10.6085C16.3043 10.3084 16.5477 10.0651 16.8478 10.0651C17.148 10.0651 17.3913 10.3084 17.3913 10.6085V13.3259C17.3913 14.5265 16.418 15.4998 15.2174 15.4998H2.17391C0.973295 15.4998 0 14.5265 0 13.3259V10.6085C0 10.3084 0.243323 10.0651 0.543478 10.0651Z"
                  fill={campaign.buttonFontColor}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.31135 12.1885C8.5236 12.4007 8.86771 12.4007 9.07995 12.1885L12.3408 8.92758C12.5531 8.71534 12.5531 8.37123 12.3408 8.15899C12.1286 7.94675 11.7845 7.94675 11.5722 8.15899L9.23913 10.4921V0.934592C9.23913 0.634437 8.99581 0.391113 8.69565 0.391113C8.3955 0.391113 8.15217 0.634437 8.15217 0.934592V10.4921L5.81908 8.15899C5.60684 7.94675 5.26273 7.94675 5.05049 8.15899C4.83824 8.37123 4.83824 8.71534 5.05049 8.92758L8.31135 12.1885Z"
                  fill={campaign.buttonFontColor}
                />
              </svg>
            )}
          </Flex>
        </ButtonComp>
      </DownloadContainer>
    )
  }

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  const sdDownloadUrl = `https://stream.mux.com/${activeResult.playbackId}/medium.mp4?download=${encodeURIComponent(
    activeResult.name
  )}`
  const hdDownloadUrl = `https://stream.mux.com/${activeResult.playbackId}/high.mp4?download=${encodeURIComponent(
    activeResult.name
  )}`

  const downloadMp4 = (downloadUrl) => {
    setShowSpinner(true)
    try {
      gaEvent({
        isGaEnabled: campaign?.gaTracking,
        name: 'clipara_click',
        data: {
          campaign_name: campaign?.name,
          video_name: activeResult?.name
        }
      })
      trackEvent({
        type: 'CLICK',
        campaignId: campaign.id,
        videoId: activeResult.id,
        playerType: 'WIDGET',
        widgetId: campaign.widgetId
      })
    } catch (e) {}
    downloadURI(downloadUrl, activeResult.name)
    setTimeout(() => {
      setShowSpinner(false)
    }, 1500)
  }

  const downloadMaster = (downloadUrl) => {
    setShowSpinner(true)
    try {
      gaEvent({
        isGaEnabled: campaign?.gaTracking,
        name: 'clipara_click',
        data: {
          campaign_name: campaign?.name,
          video_name: activeResult?.name
        }
      })
      trackEvent({
        type: 'CLICK',
        campaignId: campaign.id,
        videoId: activeResult.id,
        playerType: 'WIDGET',
        widgetId: campaign.widgetId
      })
    } catch (e) {}
    downloadURI(activeResult.masterAccessUrl, activeResult.name)
    setTimeout(() => {
      setShowSpinner(false)
    }, 1500)
  }

  return (
    <>
      <DownloadContainer
        onClick={async (e) => {
          if (activeResult.maxStoredResolution === 'SD') {
            return downloadMp4(sdDownloadUrl)
          }
          return downloadMp4(hdDownloadUrl)
        }}
        width="auto"
        position="absolute"
        zIndex={2}
      >
        <ButtonComp
          color={campaign.buttonFontColor}
          backgroundColor={campaign.buttonRgba || campaign.buttonColor}
          buttonBorderRadius={campaign.buttonBorderRadius}
          border={`1px solid ${campaign.buttonFontColor}`}
        >
          <Flex alignItems="center">
            <PWithMargin2>Download</PWithMargin2>
            <Flex
              onClick={(e) => {
                e.stopPropagation()
                if (!showPopover) {
                  showPopoverFunc(e)
                } else {
                  closeMenu(e)
                }
              }}
              alignItems="center"
              pl="16px"
              mr="-17px"
              pr="17px"
              height="40px !important"
              borderLeft={`1px solid ${campaign.buttonFontColor}`}
            >
              {showSpinner ? (
                <DarkBlockSpinner color={campaign.buttonFontColor} />
              ) : (
                // <img width={17} height={17} src={`${baseUrl}/__clipara-chevron-down-grey.svg`} />
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.14645 4.64645C2.34171 4.45118 2.65829 4.45118 2.85355 4.64645L8.5 10.2929L14.1464 4.64645C14.3417 4.45118 14.6583 4.45118 14.8536 4.64645C15.0488 4.84171 15.0488 5.15829 14.8536 5.35355L8.85355 11.3536C8.65829 11.5488 8.34171 11.5488 8.14645 11.3536L2.14645 5.35355C1.95118 5.15829 1.95118 4.84171 2.14645 4.64645Z"
                    fill={campaign.buttonFontColor}
                  />
                </svg>
              )}
            </Flex>
          </Flex>
        </ButtonComp>
      </DownloadContainer>
      {showPopover ? (
        <Popover ref={popover}>
          <MenuText onClick={() => downloadMp4(sdDownloadUrl)}>
            SD<Span> 480p</Span>
          </MenuText>
          {activeResult.maxStoredResolution !== 'SD' ? (
            <MenuText onClick={() => downloadMp4(hdDownloadUrl)}>
              HD<Span> 1080p</Span>
            </MenuText>
          ) : null}
          {isOriginal ? (
            <MenuText onClick={downloadMaster}>
              Original<Span> {originalQuality}</Span>
            </MenuText>
          ) : null}
        </Popover>
      ) : null}
    </>
  )
}
