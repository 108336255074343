import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { gaEvent, trackEvent } from '../../tracking.js'
import axios from 'axios'
import { DOWNLOAD_URL_URL, baseUrl, downloadUrlUrl, formatSeconds, getDownloadUrlUrl } from '../../helpers.js'
import { getChevronImgSize, getChevronStyling } from '../chevronStyles.js'
import { StyledPicture, VideoImage } from './FullScreen.js'
import Spinner, { DarkBlockSpinner } from '../../ui/Spinner.js'

export const tagsFetchUrl =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/search/get-tags' : 'https://api.getclipara.com/search/get-tags'

export const searchUrl =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/search/search' : 'https://api.getclipara.com/search/search'

export const getNumberOfColumns = ({ widgetContainerWidth, columnWidth, columnGap }) => {
  if (!widgetContainerWidth) {
    return 0
  }
  return Math.floor(widgetContainerWidth / (columnWidth + columnGap))
}

export const getColumnWidth = ({ widgetSearchThumbnailSize, widgetContainerWidth, isMobile }) => {
  if (isMobile) {
    return widgetContainerWidth / 2 - 24
  }
  if (widgetSearchThumbnailSize === 'MEDIUM') {
    return 325
  }
  if (widgetSearchThumbnailSize === 'LARGE') {
    return 400
  }
  return 250
}

export const getGridResults = (results, numberOfColumns) => {
  if (numberOfColumns === 0) {
    return []
  }
  const a = Array.apply(null, Array(numberOfColumns)).map(() => [])
  results.forEach((video) => {
    let shortest = 0
    let shortestHeight = 9999999999
    a.forEach((column, i) => {
      const columnHeight = column.reduce((acc, b) => {
        return acc + 10 / b.aspectRatioCalc
      }, 0)
      if (columnHeight < shortestHeight) {
        shortest = i
        shortestHeight = columnHeight
      }
    })
    a[shortest] = a[shortest].concat(video)
  })
  return a
}

export const updateQueryString = (searchParams) => {
  const newParams = { ...searchParams }
  delete newParams.organisationId
  const params = new URLSearchParams(newParams)
  const path = window.location.pathname
  window.history.pushState({}, '', `${path}?${params.toString()}`)
  return
}

export const getStateFromQuery = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  return { ...params, tags: params?.tags ? params.tags?.split(',') : [], page: params.page || 1 }
}

const ButtonComp = styled.button`
  cursor: pointer !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border: ${(props) => props.border} !important;
  font-family: 'Inter';
  height: 42px !important;
  ${(props) => (props.widgetSearchBorderRadius === 'ROUND' ? 'border-radius: 25px !important;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SOFT' ? 'border-radius: 8px !important;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SHARP' ? 'border-radius: 0px !important;' : '')}}
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  margin-right: ${(props) => (props.isMobile ? '6px' : '8px')};
  padding-left: 17px !important;
  padding-right: 17px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit !important;
  margin-bottom: 8px !important;
  &&:focus {
    outline: inherit !important;

  }
  p {
    color: ${(props) => props.color};
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 500 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const Button = ({ label, ...rest }) => {
  return (
    <ButtonComp {...rest}>
      <p>{label}</p>
    </ButtonComp>
  )
}

const DurationButtonComp = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  ${(props) =>
    props.bottomLeft
      ? `z-index:1;
      top: unset;
      right: unset;
      bottom: 10px;
      left: 10px;`
      : ''}
  cursor: pointer !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: none !important;
  height: 27px !important;
  border-radius: 27px !important;
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  background: none;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  p {
    color: black !important;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-weight: 500 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const DurationButton = ({ duration, bottomLeft }) => {
  return (
    <DurationButtonComp bottomLeft={bottomLeft}>
      <p>{formatSeconds(duration)}</p>
    </DurationButtonComp>
  )
}

const PopularButtonComp = styled.button`
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border: none !important;
  height: 29px !important;
  border-radius: 27px !important;
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  background: none;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  p {
    color: ${(props) => props.color} !important;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 500 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const PopularButton = ({ campaign, rank }) => {
  if (rank < 95 || !campaign.widgetSearchShowPopular) {
    return null
  }
  return (
    <PopularButtonComp backgroundColor={campaign.widgetSearchPopularButtonColor} color={campaign.widgetSearchPopularTextColor}>
      <p>{campaign.widgetSearchPopularText}</p>
    </PopularButtonComp>
  )
}
const PlayButton = styled.svg`
  display: block;
  -webkit-transform: translateZ(0);
  fill: none !important;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  position: relative;
  z-index: 2;
  border-radius: 60px;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PlaybuttonContainer = styled.div`
  border-radius: 60px;
  display: block;
  -webkit-transform: translateZ(0);
  fill: none !important;
  position: absolute;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  bottom: 10px;
  left: 10px;
  z-index: 2;
`

export const PlayButtonComponent = ({ campaign, pbWidth }) => {
  return (
    <PlaybuttonContainer
      animate={campaign.playButtonAnimation}
      playButtonColor={campaign.playButtonColor || '#333333'}
      width={pbWidth}
      height={pbWidth}
    >
      <PlayButton
        animate={campaign.playButtonAnimation}
        playButtonColor={campaign.playButtonColor || '#333333'}
        width={pbWidth}
        height={pbWidth}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {campaign.widgetPlayButtonStyle === 'FILLED' ? (
          <>
            <circle cx="30" cy="30" r="30" fill={campaign.playButtonColor || '#333333'} />
            <path
              d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
              fill="white"
            />
          </>
        ) : (
          <>
            <circle cx="30" cy="30" r="28.5" stroke={campaign.playButtonColor || '#333333'} stroke-width="3" />
            <path
              d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
              fill={campaign.playButtonColor || '#333333'}
            />
          </>
        )}
      </PlayButton>
    </PlaybuttonContainer>
  )
}

const ChevronImage = styled(Flex)`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor, props.isStories)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isMobile
      ? `border: none;
    background-color: rgba(91, 91, 91, 0.4);
    svg {
      fill: white;
    }`
      : ''}
`

const RightChevron = styled(Chevron)`
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const ChevronContainer = styled(Flex)`
  position: absolute;
  ${(props) => (props.left ? 'left: 30px' : 'right: 30px')};
  top: ${(props) => props.videoHeight / 2 + 28 - 22.5}px;
  justify-content: center;
  min-width: 45px;
  width: 45px;
  display: flex !important;
  ${(props) => (props.isMobile ? (props.left ? 'left: 16px;' : 'right: 16px;') : '')}
`

export const FullScreenChevrons = ({ campaign, rightChevronOnClick, leftChevronOnClick, videoHeight, isMobile }) => {
  return (
    <>
      <ChevronContainer videoHeight={videoHeight} left isMobile={isMobile}>
        <Chevron
          isMobile={isMobile}
          widgetChevronSecondaryColor={campaign.widgetChevronSecondaryColor}
          widgetButtonStyle={campaign.widgetButtonStyle}
          onClick={leftChevronOnClick}
        >
          <ChevronImage
            widgetChevronPrimaryColor={campaign.widgetChevronPrimaryColor}
            widgetButtonStyle={campaign.widgetButtonStyle}
            alt="chevron icon"
            width="18px"
            height="18px"
          >
            <svg width="25" height="17" viewBox="0 0 25 17" fill={'black'} xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.5 8.5C24.5 8.99092 24.1162 9.38889 23.6429 9.38889L3.42647 9.38889L8.82038 14.9826C9.15511 15.3297 9.15511 15.8925 8.82038 16.2397C8.48564 16.5868 7.94293 16.5868 7.60819 16.2397L0.751051 9.12854C0.416317 8.78141 0.416317 8.21859 0.751051 7.87146L7.60819 0.76035C7.94293 0.413217 8.48564 0.413217 8.82038 0.76035C9.15511 1.10748 9.15511 1.6703 8.82038 2.01743L3.42647 7.61111L23.6429 7.61111C24.1162 7.61111 24.5 8.00908 24.5 8.5Z"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ChevronImage>
        </Chevron>
      </ChevronContainer>
      <ChevronContainer videoHeight={videoHeight} isMobile={isMobile}>
        <RightChevron
          isMobile={isMobile}
          widgetChevronSecondaryColor={campaign.widgetChevronSecondaryColor}
          widgetButtonStyle={campaign.widgetButtonStyle}
          onClick={rightChevronOnClick}
        >
          <ChevronImage
            widgetChevronPrimaryColor={campaign.widgetChevronPrimaryColor}
            widgetButtonStyle={campaign.widgetButtonStyle}
            alt="chevron icon"
            width="18px"
            height="18px"
          >
            <svg width="25" height="17" viewBox="0 0 25 17" fill={'black'} xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.5 8.5C24.5 8.99092 24.1162 9.38889 23.6429 9.38889L3.42647 9.38889L8.82038 14.9826C9.15511 15.3297 9.15511 15.8925 8.82038 16.2397C8.48564 16.5868 7.94293 16.5868 7.60819 16.2397L0.751051 9.12854C0.416317 8.78141 0.416317 8.21859 0.751051 7.87146L7.60819 0.76035C7.94293 0.413217 8.48564 0.413217 8.82038 0.76035C9.15511 1.10748 9.15511 1.6703 8.82038 2.01743L3.42647 7.61111L23.6429 7.61111C24.1162 7.61111 24.5 8.00908 24.5 8.5Z"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ChevronImage>
        </RightChevron>
      </ChevronContainer>
    </>
  )
}

const NextImage = styled(VideoImage)`
  width: 1px !important;
  height: 1px !important;
`

const NextImageImage = styled.img`
  width: 1px;
  height: 1px;
`

export const NextImages = ({ allResults, fullScreenIndex, videoHeight, widgetContainerWidth, isMobile }) => {
  const nextActiveResult = allResults[fullScreenIndex === allResults.length - 1 ? 0 : fullScreenIndex + 1]
  let nextVideoWidth = nextActiveResult.aspectRatioCalc * videoHeight
  let nextVideoHeight = videoHeight
  if (isMobile) {
    nextVideoWidth = widgetContainerWidth - 16
    const maxVideoHeight = (widgetContainerWidth - 16) / nextActiveResult.aspectRatioCalc
    nextVideoHeight = maxVideoHeight
  }
  const nextImageUrl = `https://thumbnails.getclipara.com/${
    nextActiveResult.playbackId
  }/thumbnail.png?time=0.0&width=${Math.round(nextVideoWidth)}&height=${Math.round(nextVideoHeight)}&fit_mode=crop`
  const nextWebpUrl = nextImageUrl.replace('.png', '.webp')
  const nextImageSrc = `https://clipara.b-cdn.net/${nextActiveResult.path}?width=${Math.round(nextVideoWidth * 2)}`

  const previousActiveResult = allResults[fullScreenIndex === 0 ? allResults.length - 1 : fullScreenIndex - 1]
  let previousVideoWidth = previousActiveResult.aspectRatioCalc * videoHeight
  let previousVideoHeight = videoHeight
  if (isMobile) {
    previousVideoWidth = widgetContainerWidth - 16
    const maxVideoHeight = (widgetContainerWidth - 16) / previousActiveResult.aspectRatioCalc
    previousVideoHeight = maxVideoHeight
  }
  const previousImageUrl = `https://thumbnails.getclipara.com/${
    previousActiveResult.playbackId
  }/thumbnail.png?time=0.0&width=${Math.round(previousVideoWidth)}&height=${Math.round(previousVideoHeight)}&fit_mode=crop`
  const previousWebpUrl = previousImageUrl.replace('.png', '.webp')
  const previousImageSrc = `https://clipara.b-cdn.net/${previousActiveResult.path}?width=${Math.round(previousVideoWidth * 2)}`

  return (
    <>
      {nextActiveResult && nextActiveResult.type === 'VIDEO' ? (
        <StyledPicture>
          <source srcset={nextWebpUrl} type="image/webp" />
          <source srcset={nextImageUrl} type="image/png" />
          <NextImage src={nextImageUrl} alt={nextActiveResult.name} />
        </StyledPicture>
      ) : (
        <NextImageImage src={nextImageSrc} />
      )}
      {previousActiveResult && previousActiveResult.type === 'VIDEO' ? (
        <StyledPicture>
          <source srcset={previousWebpUrl} type="image/webp" />
          <source srcset={previousImageUrl} type="image/png" />
          <NextImage src={previousImageUrl} alt={previousActiveResult.name} />
        </StyledPicture>
      ) : (
        <NextImageImage src={previousImageSrc} />
      )}
    </>
  )
}

const MuteContainer = styled(Flex)`
  width: 34px;
  height: 34px;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 110001;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  ${(props) =>
    props.isMobile
      ? `right: 8px;
    top: 8px;
    width: 40px;
    height: 40px;
    background-color: rgba(91, 91, 91, 0.4);`
      : ''}
`

const Volume = styled.img`
  width: 22px;
  height: 24px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: none;
`

export const MuteControls = ({ isMuted, setIsMuted, isMobile }) => {
  return (
    <MuteContainer isMobile={isMobile} onClick={() => setIsMuted(!isMuted)}>
      <Volume
        width="24px"
        height="21px"
        alt="volume"
        src={!isMuted ? `${baseUrl}/__clipara-volume-white.svg` : `${baseUrl}/__clipara-mute-white.svg`}
      />
    </MuteContainer>
  )
}
