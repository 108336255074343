import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import { loadVideoMP4, loadVideoLite, formatSeconds, getContainerElement } from '../helpers'
import { trackEvent } from '../tracking'
import SlideItem from './SlideItem'

const Alignment = styled(Flex)`
  flex-direction: column;
  align-items: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
  position: relative;
  margin-bottom: 24px;
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  word-break: break-word;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: ${(props) => props.widgetGapWidth}px;
  ${(props) =>
    props.isMobile
      ? `grid-template-columns: repeat(${props.mobileVideos}, 1fr);`
      : `grid-template-columns: repeat(${props.desktopVideos}, 1fr);`}
`

const GridAlign = styled(Flex)`
  justify-content: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
`

const getMaxVideoWidth = (dimensions) => {
  if (dimensions === 'LANDSCAPE') {
    return 640
  }
  if (dimensions === 'SQUARE') {
    return 489
  }
  if (dimensions === 'SQUISHED_PORTRAIT') {
    return 338
  }
  if (dimensions === 'CIRCLE') {
    return 489
  }
  // PORTRAIT
  return 400
}

const getVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth / 640) * 370
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth / 338) * 500
  }
  if (dimensions === 'CIRCLE') {
    return videoWidth
  }
  // PORTRAIT
  return (videoWidth / 338) * 600
}

const getDimensionsForResponsive = (campaign, isMobileInput) => {
  const {
    videos,
    desktopVideos,
    widgetMobileThumbnailDimensions,
    widgetThumbnailDimensions,
    marginX,
    widgetGapWidth,
    mobileVideos,
    widgetAutoplay
  } = campaign
  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const containerElement = getContainerElement(campaign.id)

  const isMobile = isMobileInput || w < 700
  const videosToShow = isMobile ? mobileVideos : desktopVideos
  const arrowsWidth = 0
  const containerWidth = containerElement && containerElement.clientWidth - (marginX ? marginX * 2 : 0)
  const maxVideoWidth = isMobile ? containerWidth : getMaxVideoWidth(widgetThumbnailDimensions)
  const gapWidth = widgetGapWidth
  const maxContainerWidth = (maxVideoWidth + gapWidth) * videosToShow - gapWidth // Minus one gap width for start/end
  const containerHeight = containerElement && containerElement.clientHeight
  const containerWidthWithoutArrows = containerWidth
  const containerCanFitFullWidth = containerWidthWithoutArrows - maxContainerWidth > 0
  const videoWidth = containerCanFitFullWidth
    ? maxVideoWidth
    : (containerWidthWithoutArrows - gapWidth * (videosToShow - 1)) / videosToShow
  const videoHeight = getVideoHeight(isMobile ? widgetMobileThumbnailDimensions : widgetThumbnailDimensions, videoWidth)
  const maxContainerWidthFactoringNoArrows = maxContainerWidth - arrowsWidth
  const chevronContainerHeight = videoHeight
  const carouselContainerWidth = containerCanFitFullWidth ? `${maxContainerWidth}px` : `calc(100% - ${arrowsWidth}px)`
  const gridWidth = isMobile ? '100%' : gapWidth * (desktopVideos - 1) + videoWidth * videosToShow
  return {
    gridWidth,
    videosToShow,
    maxVideoWidth,
    arrowsWidth,
    containerWidth,
    gapWidth,
    maxContainerWidth,
    containerHeight,
    containerWidthWithoutArrows,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    borderRadius: campaign.borderRadius,
    isMobile
  }
}

const GridComponent = ({
  campaign,
  isMobile: isMobileInput,
  isNavigation = false,
  setShowFullscreenPlayer,
  setActiveFullScreenVideo
}) => {
  const [resize, setResize] = useState()
  const {
    videos,
    position,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    videoObjects,
    widgetGapWidth,
    desktopVideos,
    mobileVideos,
    widgetAutoplay
  } = campaign

  const {
    videosToShow,
    arrowsWidth,
    maxContainerWidth,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    borderRadius,
    isCircle,
    isStories,
    gridWidth,
    isMobile
  } = getDimensionsForResponsive(campaign, isMobileInput)

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const [activeRow, setActiveRow] = useState(0)
  const gridRef = useRef()

  const handleScroll = (event) => {
    const gapTop = gridRef.current.getBoundingClientRect().top
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    const onPageHeight = (gapTop - screenHeight) * -1
    const newActiveRow = Math.ceil((onPageHeight - 300) / (videoHeight + 16))
    if (newActiveRow < 1 || newActiveRow > videos.length / mobileVideos + 0.9) {
      setActiveRow(0)
      return
    }
    if (newActiveRow !== activeRow) {
      setActiveRow(newActiveRow)
    }
  }

  const eventListenerRef = useRef(handleScroll)

  useEffect(() => {
    window.addEventListener('scroll', eventListenerRef.current)
  }, [])

  const isFlashpack = campaign.organisationId === 250 || campaign.organisationId === 666

  let popularIds = []
  if (campaign.isDownloadCampaign && campaign.widgetSearchShowPopular) {
    const sortedVideos = campaign.videos.map((el) => el).sort((a, b) => (a.rank < b.rank ? 1 : -1))
    popularIds = sortedVideos.map((v) => v.id).slice(0, Math.round(sortedVideos.length * 0.2))
  }

  useEffect(() => {
    if (activeRow < 1 || activeRow > 3) {
      return
    }
    if (!widgetAutoplay) {
      return
    }

    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    ;(w < 700 ? videos.slice(0, 6) : videos).forEach(async (video, i) => {
      const element = document.getElementById(`__clipara-navigation-carousel-${video.id}-${campaign.id}`)
      if (!element || video.type !== 'VIDEO') {
        return
      }

      if (w < 700) {
        if (Math.round((i + 1) / mobileVideos) !== activeRow) {
          element.currentTime = 0
          element.style.opacity = '0'
          return element.pause()
        }
      } else {
        if (Math.ceil((i + 1) / desktopVideos) !== activeRow || i % desktopVideos > 0) {
          element.currentTime = 0
          element.style.opacity = '0'
          return element.pause()
        }
      }
      if (!element.hasAttribute('src') && !element.innerHTML?.includes('source')) {
        await (campaign.useAdaptiveBitrate || campaign.useAdaptiveBitrateCampaign ? loadVideoLite : loadVideoMP4)({
          id: `__clipara-navigation-carousel-${video.id}-${campaign.id}`,
          video,
          campaign,
          useClip: !isFlashpack,
          videoWidth,
          playerName: isNavigation ? 'navigation-grid' : 'grid'
        })
        element.style.opacity = '1'
        element.setAttribute('oncontextmenu', 'return false;')
      } else {
        try {
          element.play()
          element.style.opacity = '1'
        } catch (e) {}
      }
    })
  }, [activeRow])

  return (
    <>
      <Alignment position={position} ref={gridRef}>
        {text ? (
          <Title
            isStories={isStories}
            color={titleColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            fontFamily={widgetFontFamily}
            textAlign={textAlign}
            width={containerCanFitFullWidth ? `${gridWidth}px` : '100%'}
          >
            {text}
          </Title>
        ) : null}
        <GridAlign
          position={position}
          width={containerCanFitFullWidth ? `${maxContainerWidthFactoringNoArrows + arrowsWidth}px` : '100%'}
        >
          <Grid widgetGapWidth={widgetGapWidth} isMobile={isMobile} desktopVideos={desktopVideos} mobileVideos={mobileVideos}>
            {videos.map((video, i) => {
              return (
                <SlideItem
                  isNavigation={isNavigation}
                  playerName={isNavigation ? 'navigation-grid' : 'grid'}
                  noNextImage
                  campaign={campaign}
                  key={video.id}
                  videoHeight={videoHeight}
                  videoWidth={videoWidth}
                  videosToShow={videosToShow}
                  i={i}
                  video={video}
                  videoObjects={videoObjects}
                  borderRadius={borderRadius}
                  isStories={isStories}
                  isCircle={isCircle}
                  isMobile={isMobile}
                  setShowFullscreenPlayer={setShowFullscreenPlayer}
                  setActiveFullScreenVideo={setActiveFullScreenVideo}
                  isPopular={popularIds.includes(video.id)}
                />
              )
            })}
          </Grid>
        </GridAlign>
      </Alignment>
    </>
  )
}

export default GridComponent
