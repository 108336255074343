import { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { trackEvent } from '../../tracking.js'
import axios from 'axios'
import {
  columnGap,
  getGridResults,
  getNumberOfColumns,
  searchUrl,
  tagsFetchUrl,
  Button,
  DurationButton,
  PlayButtonComponent,
  updateQueryString,
  getStateFromQuery,
  getColumnWidth
} from './SearchComponents.js'
import { baseUrl, loadVideoMP4 } from '../../helpers.js'
import { SearchInput } from './Input.js'
import VideoResult from './VideoResult.js'
import ImageResult from './ImageResult.js'
import FullScreen from './FullScreen.js'
import { Portal } from '../../Portal.js'

const Title = styled.p`
  font-weight: 500 !important;
  line-height: 22px !important;
  font-size: 18px !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  color: #425466;
`

const InputContainer = styled(Flex)`
  box-sizing: border-box !important;
  justify-content: center !important;
  ${(props) =>
    props.isMobile
      ? `padding-left: 16px;
    padding-right: 16px;`
      : ''}
`

const TagsContainer = styled(Flex)`
  margin-bottom: 32px;
  max-width: ${(props) => props.maxWidth}px;
  flex-wrap: wrap;
  justify-content: center;
  ${(props) =>
    props.isMobile
      ? `box-sizing: border-box !important;
    max-width: 100vw;
    padding-left: 16px;
    justify-content: flex-start;
    flex-wrap: nowrap !important;
    margin-bottom: 20px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &&::-webkit-scrollbar {
      display: none;
    }`
      : ''}
`

const Alignment = styled(Flex)`
  @media (max-width: 700px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }
`

const Search = ({ campaign, isMobile: isMobileInput }) => {
  const [resize, setResize] = useState()
  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const { organisationId } = campaign
  const isMobile = isMobileInput || (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 700
  const widgetContainer = useRef()
  const widgetContainerWidth = widgetContainer.current?.offsetWidth
  const columnWidth = getColumnWidth({
    isMobile,
    widgetContainerWidth,
    widgetSearchThumbnailSize: campaign.widgetSearchThumbnailSize
  })
  const columnGap = isMobile ? 16 : 24
  const numberOfColumns = getNumberOfColumns({ widgetContainerWidth, columnWidth, columnGap })

  const [initialLoad, setInitalLoad] = useState(true)
  const [tags, setTags] = useState([])
  const [popularTags, setPopularTags] = useState([])
  const [results, setResults] = useState([])
  const [extraResults, setExtraResults] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [searchParams, setSearchParams] = useState({ organisationId: campaign.organisationId, page: 1, query: '', tags: [] })
  const [searchValue, setSearchValue] = useState()
  const [searchableContent, setSearchableContent] = useState(campaign.widgetSearchSearchableContent)

  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false)
  const [fullScreenIndex, setFullScreenIndex] = useState(0)

  const gridResults = getGridResults(results, numberOfColumns)
  const extraGridResults = getGridResults(extraResults || [], numberOfColumns)
  const allResults = [...results, ...(extraResults || [])]

  const init = async (justTags) => {
    const res = await axios.post(tagsFetchUrl, {
      organisationId,
      searchableContent: searchableContent || campaign.widgetSearchSearchableContent,
      filterTags: campaign.searchUseFilterTags ? campaign.searchFilterTags : undefined
    })
    setTags(res.data.payload.tags)
    setPopularTags(res.data.payload.popularTags)
    if (!justTags) {
      window.addEventListener(
        'popstate',
        function (event) {
          if (!window.location.search.includes('modal=true')) {
            setIsFullScreenOpen(false)
          }
          // search()
        },
        false
      )
    }
  }

  useEffect(() => {
    init()
  }, [searchableContent || campaign.widgetSearchSearchableContent])

  const search = async () => {
    if (initialLoad) {
      setSearchParams({ ...getStateFromQuery(), page: 1, organisationId: campaign.organisationId })
      setInitalLoad(false)
      return
    }
    const res = await axios.post(searchUrl, {
      ...searchParams,
      useName: campaign.widgetSearchSearchVideoNames,
      searchableContent: searchableContent || campaign.widgetSearchSearchableContent,
      filterTags: campaign.searchUseFilterTags ? campaign.searchFilterTags : undefined
    })
    if (searchParams.page !== 1) {
      setResults(results.concat(res.data.payload.results))
    } else {
      setResults(res.data.payload.results)
    }
    if (res.data.payload.extraResults) {
      setExtraResults(res.data.payload.extraResults)
    } else {
      setExtraResults()
    }
    setIsMore(res.data.pagination.isMore)
    // updateQueryString(searchParams)
  }

  useEffect(() => {
    search()
  }, [searchParams])

  const updateLocalStorage = (tag) => {
    const oldSearches = JSON.parse(localStorage.getItem('searched_tags') || '[]')
    localStorage.setItem('searched_tags', JSON.stringify([tag, ...oldSearches.filter((s) => s !== tag)]))
  }

  const maxWidth = (columnWidth + columnGap) * numberOfColumns - columnGap

  const resultOnClick = (id) => {
    const i = allResults.findIndex((r) => r.id === id)
    setFullScreenIndex(i)
    // window.history.pushState({}, '', `${window.location.pathname}${window.location.search}&modal=true`)
    setIsFullScreenOpen(true)
  }

  const tagOnClick = (tag) => {
    const isSelected = searchParams.tags.includes(tag)
    setSearchValue('')
    setSearchParams({
      ...searchParams,
      query: '',
      page: 1,
      tags: isSelected ? [] : [tag]
    })
    updateLocalStorage(tag)
  }

  return (
    <Alignment
      position="relative"
      flexDirection="column"
      ref={widgetContainer}
      alignItems="center"
      width={`calc(100% - ${(campaign.marginX || 0) * 2}px)`}
      mx={`${campaign.marginX}px` || 0}
      my={`${campaign.marginY}px` || 0}
      minHeight="100vh"
      mb="100px"
    >
      {campaign.widgetSearchShowSearchBar ? (
        <InputContainer isMobile={isMobile}>
          <SearchInput
            campaign={campaign}
            tags={tags}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            updateLocalStorage={updateLocalStorage}
            popularTags={popularTags}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isMobile={isMobile}
            searchableContent={searchableContent}
            setSearchableContent={setSearchableContent}
            init={init}
          />
        </InputContainer>
      ) : null}
      <TagsContainer maxWidth={maxWidth} isMobile={isMobile}>
        {(campaign.searchPromotedTags?.length ? campaign.searchPromotedTags : popularTags || []).map((tag) => {
          const isSelected = searchParams.tags.includes(tag)
          if (tag === 'High Quality Download') {
            return null
          }
          return (
            <Button
              isMobile={isMobile}
              label={tag}
              onClick={() => tagOnClick(tag)}
              widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
              backgroundColor={isSelected ? campaign.buttonFontColor : campaign.buttonColor}
              color={isSelected ? campaign.buttonColor : campaign.buttonFontColor}
              border={`1px solid ${isSelected ? campaign.buttonColor : campaign.buttonFontColor}`}
              key={tag}
            />
          )
        })}
      </TagsContainer>
      <Flex justifyContent="center">
        {gridResults.map((column, i) => {
          const isOffset = !isMobile && campaign.widgetSearchGridOffset && i % 2 === 1
          return (
            <Flex
              key={i}
              mt={isOffset ? '30px' : '0px'}
              flexDirection="column"
              mr={i === gridResults.length - 1 ? '0px' : `${columnGap}px`}
              width={columnWidth}
            >
              {column.map((videoOrImage) => {
                if (videoOrImage.type === 'IMAGE') {
                  return (
                    <ImageResult
                      key={videoOrImage.id}
                      columnWidth={columnWidth}
                      columnGap={columnGap}
                      onClick={() => resultOnClick(videoOrImage.id)}
                      campaign={campaign}
                      image={videoOrImage}
                      isMobile={isMobile}
                    />
                  )
                }
                return (
                  <VideoResult
                    key={videoOrImage.id}
                    columnWidth={columnWidth}
                    columnGap={columnGap}
                    onClick={() => resultOnClick(videoOrImage.id)}
                    campaign={campaign}
                    video={videoOrImage}
                    isMobile={isMobile}
                  />
                )
              })}
            </Flex>
          )
        })}
      </Flex>
      {extraResults?.length ? (
        <>
          {results?.length ? (
            <Flex mt="100px" maxWidth={maxWidth} justifyContent="center">
              <Title>More popular content</Title>
            </Flex>
          ) : (
            <Flex maxWidth={maxWidth}>
              <Title fontSize="16px">
                No results for “{searchParams.query || searchParams.tags.join(' and ')}”. Here’s some other popular content:
              </Title>
            </Flex>
          )}
          <Flex justifyContent="center">
            {extraGridResults.map((column, i) => {
              const isOffset = !isMobile && campaign.widgetSearchGridOffset && i % 2 === 1

              return (
                <Flex
                  key={i}
                  mt={isOffset ? '30px' : '0px'}
                  flexDirection="column"
                  mr={i === gridResults.length - 1 ? '0px' : `${columnGap}px`}
                  width={columnWidth}
                >
                  {column.map((videoOrImage) => {
                    if (videoOrImage.type === 'IMAGE') {
                      return (
                        <ImageResult
                          key={videoOrImage.id}
                          columnWidth={columnWidth}
                          columnGap={columnGap}
                          onClick={() => resultOnClick(videoOrImage.id)}
                          campaign={campaign}
                          image={videoOrImage}
                          isMobile={isMobile}
                        />
                      )
                    }
                    return (
                      <VideoResult
                        key={videoOrImage.id}
                        columnWidth={columnWidth}
                        columnGap={columnGap}
                        onClick={() => resultOnClick(videoOrImage.id)}
                        campaign={campaign}
                        video={videoOrImage}
                        isMobile={isMobile}
                      />
                    )
                  })}
                </Flex>
              )
            })}
          </Flex>
        </>
      ) : null}
      {isMore ? (
        <Box mt="32px" mb="60px">
          <Button
            label={'Load more'}
            onClick={() => {
              setSearchParams({
                ...searchParams,
                page: searchParams.page + 1
              })
            }}
            widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
            backgroundColor={campaign.buttonColor}
            color={campaign.buttonFontColor}
            border={`1px solid ${campaign.buttonFontColor}`}
          />
        </Box>
      ) : null}
      {campaign && campaign.isFreeUser && campaign.organisationId !== 747 && campaign.organisationId !== 2 ? (
        <Box position="absolute" bottom="-50px" left="calc(50% - 161/2)">
          <a href="https://www.getclipara.com/?utm_source=search-widget" target="_blank" rel="noreferrer">
            <img width={120} height={30} src={`${baseUrl}/__clipara-powered-by-dark.svg`} alt="powered by clipara" />
          </a>
        </Box>
      ) : null}
      {ReactDOM.createPortal(
        <FullScreen
          campaign={campaign}
          columnWidth={columnWidth}
          columnGap={columnGap}
          widgetContainerWidth={widgetContainerWidth}
          maxWidth={maxWidth}
          isMobile={isMobile}
          numberOfColumns={numberOfColumns}
          isFullScreenOpen={isFullScreenOpen}
          setIsFullScreenOpen={setIsFullScreenOpen}
          fullScreenIndex={fullScreenIndex}
          setFullScreenIndex={setFullScreenIndex}
          allResults={allResults}
          tagOnClick={tagOnClick}
        />,
        document.getElementById(`__clipara-portal-${campaign.id}`) || document.getElementById(`__clipara-portal`)
      )}
    </Alignment>
  )
}

export default Search
