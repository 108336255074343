import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import FullScreenPlayer from './FullScreenPlayer'
import { baseUrl, usePreventScroll } from '../helpers'
import ShoppableMobile from './ShoppableMobile'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import MobileShare from './MobileShare'

const FullscreenContainer = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  background-color: black;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overscroll-behavior-y: none;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`

const Close = styled.img`
  width: 12px;
  height: 12px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: none;
`

const Volume = styled.img`
  width: 22px;
  height: 24px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: none;
`

const Share = styled.img`
  width: 18px;
  height: 24px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: none;
`

const CloseTargetBox = styled(Flex)`
  width: 34px;
  height: 34px;
  z-index: 110001;
  margin-bottom: 12px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(78, 78, 78, 0.4) !important;
`

const MuteContainer = styled(CloseTargetBox)``

const ShareContainer = styled(CloseTargetBox)``

const Logo = styled.img`
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 120px;
  z-index: 110001;
`

const CustomerLogo = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
  max-height: 40px;
  max-width: 140px;
  object-fit: contain;
  object-position: left;
  z-index: 110001;
  transform: translate(0, 0);
`

const CarouselChild = styled(Box)`
  background-color: black;
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SwipeIcon = styled.img`
  position: absolute;
  left: calc(50% - 20px);
  z-index: 1;
  bottom: 204px;
  z-index: 110001;
  @keyframes bounceUp {
    0%,
    20%,
    40%,
    60%,
    80% {
      transform: translateY(-10px);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateY(-30px);
    }
    100% {
      visibility: hidden;
      display: none;
    }
  }
  @keyframes bounceLeft {
    0%,
    20%,
    40%,
    60%,
    80% {
      transform: translateX(10px);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-10px);
    }
    100% {
      visibility: hidden;
      display: none;
    }
  }
  -webkit-animation: ${(props) => (props.isHorizontal ? 'bounceLeft' : 'bounceUp')} 3s;
  animation: ${(props) => (props.isHorizontal ? 'bounceLeft' : 'bounceUp')} 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  width: 40px !important;
  height: 40px !important;
`

const SwipeUpText = styled.p`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-family: Inter, sans-serif;
  margin-bottom: 2px;
  word-break: break-word;
  z-index: 1;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 600;
  position: absolute;
  bottom: 180px;
  color: white;
  text-align: center;
  width: 100%;
  -webkit-animation: ${(props) => (props.isHorizontal ? 'bounceLeft' : 'bounceUp')} 3s;
  animation: ${(props) => (props.isHorizontal ? 'bounceLeft' : 'bounceUp')} 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
`

const NumVids = styled.p`
  background-color: rgba(78, 78, 78, 0.4) !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter, sans-serif;
  margin: 0px !important;
  position: absolute;
  top: 16px;
  right: 58px;
  color: white !important;
  font-size: 14px !important;
  z-index: 2;
  backdrop-filter: blur(10px);
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: 700 !important;
  font-size: ${(props) => props.titleFontSize || '16'}px !important;
  line-height: ${(props) => (props.titleFontSize ? props.titleFontSize + 2 : '18')}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.hasSubtitle ? '6px' : '16px')} !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: white;
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: 500 !important;
  font-size: ${(props) => props.subtitleFontSize || '14'}px !important;
  line-height: ${(props) => (props.subtitleFontSize ? props.subtitleFontSize + 2 : '16')}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: white;
  }
`

const Titles = ({
  hasNonShoppableButton,
  hasShoppableButton,
  titleActive,
  titleFontFamily,
  titleColor,
  titleAlignment,
  titleFontSize,
  subtitleColor,
  subtitleFontSize,
  subtitleActive,
  titleVideoObject,
  noCliparaLogo,
  close
}) => {
  let paddingBottom = '0px'
  if (hasNonShoppableButton) {
    if (!noCliparaLogo) {
      paddingBottom = '76px'
    } else {
      paddingBottom = '60px'
    }
  } else if (hasShoppableButton) {
    if (!noCliparaLogo) {
      paddingBottom = '104px'
    } else {
      paddingBottom = '100px'
    }
  } else if (!noCliparaLogo) {
    paddingBottom = '16px'
  }
  return (
    <Flex flexDirection="column" width="auto" pb={paddingBottom}>
      {titleVideoObject.title && titleActive && (
        <VideoTitle
          titleFontSize={titleFontSize}
          titleAlignment={titleAlignment}
          titleColor={titleColor}
          titleFontFamily={titleFontFamily}
          hasSubtitle={titleVideoObject.subtitle && subtitleActive}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && subtitleActive && (
        <VideoSubtitle
          titleAlignment={titleAlignment}
          subtitleFontSize={subtitleFontSize}
          subtitleColor={subtitleColor}
          titleFontFamily={titleFontFamily}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 50px;
  flex-direction: column-reverse;
  width: 100% !important;
  box-sizing: border-box !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`

const FullHeight = styled.div`
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`

const TestButton = styled.button`
  border-radius: 16px;
  padding: 16px;
  padding-top: 9px;
  padding-bottom: 8px;
  outline: none;
  margin-left: 8px;
  margin-right: 8px;
`

const Carousel = ({
  videos,
  campaign,
  startScreen,
  videoProducts,
  currency,
  isMuted,
  setIsMuted,
  close,
  fullScreenProduct,
  setFullScreenProduct,
  showProductSelect,
  setShowProductSelect,
  activeVideo,
  setActiveVideo
}) => {
  const screenHeight = window.innerHeight || document.documentElement.clientHeight
  const blockAutoPlay = window && window.location.href.includes('block-clipara-auto-play')

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: !showProductSelect && !fullScreenProduct,
    loop: true,
    initial: startScreen,
    vertical: campaign.mobileVerticalScroll,
    size: screenHeight,
    renderMode: 'performance',
    slideChanged(s) {
      setActiveVideo(s.track.details.rel)
    },
    animationEnded(s) {}
  })

  useEffect(() => {
    if (window.mux) {
      videos[activeVideo].id
      if (videos[activeVideo].type === 'VIDEO') {
        const element = document.getElementById(`__clipara-player-${videos[activeVideo].id}`)
        if (!element) {
          return
        }
        if (element.currentTime > 3) {
          element.currentTime = 0
        }
        if (!isMuted) {
          element.muted = false
        }
        if (element.src) {
          try {
            element.play()
          } catch (e) {}
        }
      }

      try {
        const element1 = document.getElementById(`__clipara-player-${videos[activeVideo + 1].id}`)
        if (videos[activeVideo + 1].type === 'VIDEO') {
          element1.pause()
          if (element1.currentTime > 3) {
            element1.currentTime = 0
          }
          if (!isMuted) {
            element1.muted = false
          }
        }
      } catch (e) {}
      try {
        const element2 = document.getElementById(`__clipara-player-${videos[activeVideo - 1].id}`)
        if (videos[activeVideo - 1].type === 'VIDEO') {
          element2.pause()
          if (element2.currentTime > 3) {
            element2.currentTime = 0
          }
          if (!isMuted) {
            element2.muted = false
          }
        }
      } catch (e) {}
    }
  }, [activeVideo])

  return (
    <FullHeight ref={sliderRef} className="keen-slider keen-slider--vertical">
      {videos.map((video, index) => {
        const buttonVideoObject = campaign.videoObjects.find(
          (el) => el.videoId.toString() === video.id.toString() && el.type === 'BUTTON'
        )
        const videoSpecificProducts = videoProducts.filter((el) => el.videoId.toString() === video.id.toString())
        const playerId = `__clipara-player-${video.id}`
        const isActive = activeVideo + 2 > index && activeVideo - 2 < index
        const titleVideoObject =
          campaign.videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}
        const hasNonShoppableButton = buttonVideoObject && buttonVideoObject.type
        return (
          <CarouselChild className="keen-slider__slide" key={video.id}>
            <FullScreenPlayer
              videoWidth={'100vw'}
              moveToNextVideo={() => {
                instanceRef && instanceRef.current && instanceRef.current.next()
              }}
              isMuted={isMuted}
              fillPortrait
              buttonVideoObject={buttonVideoObject}
              isActive
              isMobile
              optimiseLoad={!isActive}
              noAutoplay={index !== startScreen || blockAutoPlay}
              playerId={playerId}
              video={video}
              campaign={campaign}
              noCliparaLogo={campaign.noCliparaLogo}
            />
            {campaign.titleActive && titleVideoObject && titleVideoObject.id ? (
              <InnerTitleContainer>
                <Titles
                  hasNonShoppableButton={hasNonShoppableButton}
                  hasShoppableButton={videoSpecificProducts && videoSpecificProducts.length > 0}
                  titleFontSize={campaign.titleFontSize}
                  titleActive={campaign.titleActive}
                  titleFontFamily={campaign.titleFontFamily}
                  titleColor={campaign.titleColor}
                  titleAlignment={campaign.titleAlignment}
                  subtitleColor={campaign.subtitleColor}
                  subtitleFontSize={campaign.subtitleFontSize}
                  subtitleActive={campaign.subtitleActive}
                  titleVideoObject={titleVideoObject}
                  noCliparaLogo={campaign.noCliparaLogo}
                />
              </InnerTitleContainer>
            ) : null}

            {campaign.id === 4207 && index === activeVideo ? (
              <Flex position="fixed" top="30%" zIndex="1" justifyContent="center">
                <TestButton
                  id="test-button-1"
                  onClick={() => {
                    document.getElementById('test-button-1').style.backgroundColor = 'grey'
                    if (window.ReactNativeWebView) {
                      window.ReactNativeWebView?.postMessage('CLOSE')
                    }
                    setTimeout(() => {
                      document.getElementById('test-button-1').style.backgroundColor = 'white'
                    }, 200)
                  }}
                >
                  Press for close
                </TestButton>
                <TestButton
                  id="test-button-2"
                  onClick={() => {
                    document.getElementById('test-button-2').style.backgroundColor = 'grey'
                    if (window.ReactNativeWebView) {
                      window.ReactNativeWebView?.postMessage('ADD_TO_CART_629')
                    }
                    setTimeout(() => {
                      document.getElementById('test-button-2').style.backgroundColor = 'white'
                    }, 200)
                  }}
                >
                  Press for add to cart
                </TestButton>
              </Flex>
            ) : null}
            {index === startScreen && videos.length > 1 ? (
              <>
                <SwipeIcon
                  isHorizontal={!campaign.mobileVerticalScroll}
                  width="40px"
                  height="40px"
                  src={
                    !campaign.mobileVerticalScroll
                      ? `${baseUrl}/__clipara-swipe-white.svg`
                      : `${baseUrl}/__clipara-arrow-up-circle-white.svg`
                  }
                />
                <SwipeUpText isHorizontal={!campaign.mobileVerticalScroll}>
                  {!campaign.mobileVerticalScroll
                    ? [(5, 6, 7, 899)].includes(campaign.organisationId)
                      ? 'Desliza hacia la izquierda para ver más'
                      : 'Swipe to see more'
                    : [(5, 6, 7, 899)].includes(campaign.organisationId)
                    ? 'Desliza para más'
                    : 'Swipe up to see more'}
                </SwipeUpText>
              </>
            ) : null}
            {index === activeVideo ? (
              <div>
                <ShoppableMobile
                  activeVideo={activeVideo}
                  videoProducts={videoSpecificProducts}
                  campaign={campaign}
                  playerId={playerId}
                  currency={currency}
                  fullScreenProduct={fullScreenProduct}
                  setFullScreenProduct={setFullScreenProduct}
                  showProductSelect={showProductSelect}
                  setShowProductSelect={setShowProductSelect}
                />
              </div>
            ) : null}
          </CarouselChild>
        )
      })}
    </FullHeight>
  )
}

const MobileFullscreen = ({ campaign, close, activeFullScreenVideo, videoProducts, currency }) => {
  const [startScreen, setStartScreen] = useState(activeFullScreenVideo)
  const [isMuted, setIsMuted] = useState(false)
  const [showProductSelect, setShowProductSelect] = useState(false)
  const [fullScreenProduct, setFullScreenProduct] = useState()
  const [showMobileShare, setShowMobileShare] = useState(false)
  const mobileCarousel = useRef()
  const [activeVideo, setActiveVideo] = useState(activeFullScreenVideo)
  usePreventScroll()

  let isInStandaloneMode = false
  try {
    isInStandaloneMode =
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone ||
      document.referrer.includes('android-app://')
  } catch (e) {}

  const screenHeight = window.innerHeight || document.documentElement.clientHeight
  const [resize, setResize] = useState(false)

  function handleResize() {
    if (resize !== true) {
      setResize(true)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <FullscreenContainer id="__clipara-fullscreen-player">
      <Flex height={screenHeight} alignItems="center" justifyContent="center" onClick={() => setShowMobileShare(false)}>
        <Carousel
          mobileCarousel={mobileCarousel}
          startScreen={startScreen}
          activeVideo={activeVideo}
          setActiveVideo={setActiveVideo}
          campaign={campaign}
          videos={campaign.videos}
          currency={currency}
          videoProducts={videoProducts}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          close={close}
          fullScreenProduct={fullScreenProduct}
          setFullScreenProduct={setFullScreenProduct}
          showProductSelect={showProductSelect}
          setShowProductSelect={setShowProductSelect}
        />
      </Flex>
      {!fullScreenProduct ? (
        <>
          {!campaign.noCliparaLogo && (
            <a target="_blank" href="https://www.getclipara.com/?utm_source=widget" alt="clipara">
              <Logo width="90px" src={`${baseUrl}/__clipara-powered-by-inline.svg`} />
            </a>
          )}
          {campaign.logoUrl ? <CustomerLogo width="80px" alt="logo" src={campaign.logoUrl} /> : null}
          {campaign ? (
            <Flex
              width="auto"
              position="fixed"
              top={!isInStandaloneMode ? '16px !important' : '64px !important'}
              right="12px !important"
              flexDirection="column"
            >
              {!campaign.autoOpen ? (
                <CloseTargetBox
                  onClick={() => {
                    close()
                  }}
                >
                  <Close width="12px" height="12px" alt="close preview" src={`${baseUrl}/__clipara-cross-white.svg`} />
                </CloseTargetBox>
              ) : null}
              {campaign.videos[activeVideo]?.type === 'VIDEO' ? (
                <MuteContainer autoOpen={campaign.autoOpen} onClick={() => setIsMuted(!isMuted)}>
                  <Volume
                    width="24px"
                    height="21px"
                    alt="volume"
                    src={!isMuted ? `${baseUrl}/__clipara-volume-white.svg` : `${baseUrl}/__clipara-mute-white.svg`}
                  />
                </MuteContainer>
              ) : null}
              {campaign.organisationId === 747 ||
              campaign.organisationId === 2 ||
              campaign.organisationId === 664 ||
              campaign.organisationId === 1354 ? null : (
                <ShareContainer
                  autoOpen={campaign.autoOpen}
                  onClick={() => {
                    if (showMobileShare) {
                      return setShowMobileShare(false)
                    }
                    setShowMobileShare(true)
                    campaign.videos.map((video, i) => {
                      const element = document.getElementById(`__clipara-player-${video.id}`)
                      if (!element) {
                        return
                      }
                      element.pause()
                    })
                  }}
                >
                  <Share width="16px" height="21px" alt="share" src={`${baseUrl}/__clipara-share-arrow-white.svg`} />
                </ShareContainer>
              )}
            </Flex>
          ) : null}
          <NumVids>
            {activeVideo + 1} of {campaign.videos.length}
          </NumVids>
        </>
      ) : null}
      <MobileShare showMobileShare={showMobileShare} setShowMobileShare={setShowMobileShare} campaign={campaign} />
    </FullscreenContainer>
  )
}

export default MobileFullscreen
