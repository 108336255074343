import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../ui/Layout'
import { baseUrl, formatCurrency } from '../helpers'

const Container = styled(Flex)`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 2147483647;
  flex-direction: column;
  transform: ${(props) => (props.show ? 'translate(0%, 0%);' : 'translate(0%, 100%);')};
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border-radius: 16px 16px 0px 0px;
`

const InnerContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  p {
    text-decoration: none;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 24px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: rgba(16, 18, 37, 1) !important;
    font-family: 'Inter';
    text-align: left !important;
  }
`

const LogoContainer = styled(Flex)`
  width: 64px;
  height: 64px;
  min-width: 64px;
  border-radius: 64px;
  align-items: center;
  justify-content: center;
  background-color: rgba(235, 242, 250, 1);
  margin-bottom: 8px;
`

const ShareText = styled.p`
  font-size: 12px !important;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgba(66, 84, 102, 1);
`

const MobileShare = ({ showMobileShare, setShowMobileShare, campaign }) => {
  const [isCopied, setIsCopied] = useState(false)
  const url = `https://${campaign.customShareDomain || 'player'}.getclipara.com/share/${campaign.id}`
  return (
    <>
      <Container show={showMobileShare} id="__clipara-shoppable-container">
        <InnerContainer>
          <Flex alignItems="center" mb="20px">
            <Flex
              flexDirection="column"
              alignItems="center"
              onClick={() => {
                navigator && navigator.clipboard && navigator.clipboard.writeText(url)
                setIsCopied(true)
              }}
            >
              <LogoContainer>
                <img
                  width={30}
                  height={30}
                  src={isCopied ? `${baseUrl}/__clipara-share-tick-grey.svg` : `${baseUrl}/__clipara-copy-link-grey.svg`}
                />
              </LogoContainer>
              <ShareText>{isCopied ? 'Copied' : 'Copy link'}</ShareText>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="center"
              onClick={() => {
                if (navigator && navigator.share) {
                  const sharingText = campaign.sharingTitle
                    ? `I think you'll enjoy these clips: ${campaign.sharingTitle}`
                    : `https://${campaign.customShareDomain || 'player'}.getclipara.com/share/${campaign.id}`
                  navigator
                    .share({
                      title: sharingText,
                      text: sharingText,
                      url: `https://${campaign.customShareDomain || 'player'}.getclipara.com/share/${campaign.id}`
                    })
                    .then(() => {
                      console.log('Share successfull')
                    })
                } else {
                  console.log('Navigator.share not supported')
                }
              }}
            >
              <LogoContainer>
                <img alt="share via" width={30} height={30} src={`${baseUrl}/__clipara-share-via-grey.svg`} />
              </LogoContainer>
              <ShareText>Share via</ShareText>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="center"
              onClick={() => {
                window.open(`https://api.whatsapp.com/send/?text=${encodeURIComponent(url)}`, '_blank').focus()
              }}
            >
              <LogoContainer>
                <img alt="whatsapp logo" width={30} height={30} src={`${baseUrl}/__clipara-whatsapp-grey.svg`} />
              </LogoContainer>
              <ShareText>WhatsApp</ShareText>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="center"
              onClick={() => {
                window.open(`fb-messenger://share/?link=${encodeURIComponent(url)}`, '_blank')
              }}
            >
              <LogoContainer>
                <img alt="messenger logo" width={30} height={30} src={`${baseUrl}/__clipara-messenger-grey.svg`} />
              </LogoContainer>
              <ShareText>Messenger</ShareText>
            </Flex>
          </Flex>
        </InnerContainer>
        <Flex justifyContent="center" mb="4px">
          <a target="_blank" href="https://www.getclipara.com/?utm_source=playershare">
            <img alt="powered by clipara" width={102} height={24} src={`${baseUrl}/__clipara-powered-by-inline-grey.svg`} />
          </a>
        </Flex>
      </Container>
    </>
  )
}

export default MobileShare
