import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import { loadVideoMP4, formatSeconds, getContainerElement } from '../helpers'
import { trackEvent } from '../tracking'
import { getChevronStyling, getChevronImg, getChevronImgSize } from './chevronStyles.js'
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import SlideItem from './SlideItem'

const Alignment = styled(Flex)`
  flex-direction: column;
  align-items: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
  position: relative;
  margin-bottom: 24px;
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor, props.isStories)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
  ${(props) => (props.isSmallControls ? `width: 32px; height: 32px; min-width: 32px;` : '')}
`

const ChevronImage = styled(Flex)`
  ${(props) =>
    props.isStories
      ? `svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }`
      : ''}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }
  ${(props) => (props.isSmallControls ? `width: 16px !important; height: 16px !important; min-width: 16px !important;` : '')}
`

const LeftChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-left: 8px;'}
  ${(props) => props.hide && 'display:none;'}
`

const RightChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-right: 8px;'}
  ${(props) => props.hide && 'display:none;'}
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  box-sizing: border-box;
  word-break: break-word;
`

const getVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth * 9) / 16
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth * 3) / 2
  }
  // PORTRAIT
  return (videoWidth * 16) / 9
}

const getNumberVideoOnScreen = (widgetMobileThumbnailSize) => {
  if (widgetMobileThumbnailSize === 'MEDIUM') {
    return 2.5
  }
  if (widgetMobileThumbnailSize === 'SMALL') {
    return 3.5
  }
  return 1.2
}

const getOffsetForSize = (widgetMobileThumbnailSize, videoWidth) => {
  if (widgetMobileThumbnailSize === 'MEDIUM') {
    return (1 / videoWidth) * 5
  }
  if (widgetMobileThumbnailSize === 'SMALL') {
    return (1 / videoWidth) * 3.6
  }
  return (1 / videoWidth) * 12
}

const NavigationMobileCarousel = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo }) => {
  const [resize, setResize] = useState()
  const {
    videos,
    position,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    videoObjects,
    widgetArrowPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetMobileThumbnailDimensions,
    widgetThumbnailDimensions,
    borderRadius: inputBorderRadius,
    widgetIsPartialStart,
    type,
    widgetAutoplay,
    widgetMobileChevrons,
    widgetMobileThumbnailSize
  } = campaign
  const [inView, setInView] = useState(false)
  const [activeVideo, setActiveVideo] = useState(0)
  const isStories = type === 'STORIES'
  const containerElement = getContainerElement(campaign.id)
  const dimensions = isStories ? widgetThumbnailDimensions : widgetMobileThumbnailDimensions || widgetThumbnailDimensions
  const containerWidth = containerElement && containerElement.clientWidth
  const videoWidth =
    (containerWidth - (getNumberVideoOnScreen(widgetMobileThumbnailSize) - 1) * 16) /
    getNumberVideoOnScreen(widgetMobileThumbnailSize)
  const videoHeight = getVideoHeight(dimensions, videoWidth)
  const isCircle = dimensions === 'CIRCLE'
  const borderRadius = isCircle ? videoWidth / 2 : inputBorderRadius
  const noArrows = videos.length === 1 || !widgetMobileChevrons

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const leftChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.prev()
  const rightChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.next()
  const sliderComponentRef = useRef()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: true,
    loop: true,
    initial: 0,
    renderMode: 'performance',
    slideChanged(s) {
      setActiveVideo(s.track.details.rel)
    },
    animationEnded(s) {
      setActiveVideo(s.track.details.rel)
    },
    slides: {
      perView: getNumberVideoOnScreen(widgetMobileThumbnailSize),
      spacing: 16,
      origin: widgetIsPartialStart ? 'center' : getOffsetForSize(widgetMobileThumbnailSize, videoWidth)
    }
  })

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
      rootMargin: '400px'
    }
    if (widgetAutoplay) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          setInView(true)
        } else {
          setInView(false)
        }
      }, options)
      observer.observe(sliderComponentRef.current)
    }
  }, [campaign && campaign.id])

  useEffect(() => {
    const asyncFunc = async () => {
      if (isStories || !widgetAutoplay) {
        return
      }
      videos.forEach(async (video, i) => {
        const element = document.getElementById(`__clipara-navigation-carousel-${video.id}-${campaign.id}`)
        if (!element || video.type !== 'VIDEO') {
          return
        }
        if (i !== activeVideo || !inView) {
          element.pause()
          element.currentTime = 0
          element.style.opacity = '0'
          return
        }

        if (!element.hasAttribute('src')) {
          await loadVideoMP4({
            id: `__clipara-navigation-carousel-${video.id}-${campaign.id}`,
            video,
            campaign,
            useClip: true,
            videoWidth,
            playerName: 'navigation-carousel'
          })
          element.style.opacity = '1'
        } else {
          try {
            element.play()
            element.style.opacity = '1'
          } catch (e) {}
        }
      })
    }
    asyncFunc()
  }, [activeVideo, inView])

  return (
    <>
      <Alignment position={position}>
        {text ? (
          <Title
            isStories={isStories}
            color={titleColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            fontFamily={widgetFontFamily}
            textAlign={textAlign}
            width={'100%'}
          >
            {text}
          </Title>
        ) : null}
        <Flex width="100%" ref={sliderComponentRef}>
          <Flex
            justifyContent={videos.length === 1 ? 'center' : 'flex-start'}
            width="auto"
            ref={sliderRef}
            className="keen-slider"
          >
            {videos.map((video, i) => {
              return (
                <SlideItem
                  playerName={'navigation-carousel'}
                  isMobile
                  isNavigation
                  campaign={campaign}
                  key={video.id}
                  videoHeight={videoHeight}
                  videoWidth={videoWidth}
                  activeVideoIndex={activeVideo}
                  videosToShow={getNumberVideoOnScreen(widgetMobileThumbnailSize)}
                  i={i}
                  video={video}
                  videoObjects={videoObjects}
                  borderRadius={borderRadius}
                  isStories={isStories}
                  isCircle={isCircle}
                />
              )
            })}
          </Flex>
        </Flex>
        {!noArrows ? (
          <Flex mr="16px" mt="16px" justifyContent="flex-end" alignItems="center" display="flex !important" width="100%">
            <LeftChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              widgetArrowPosition={widgetArrowPosition}
              onClick={leftChevronOnClick}
              mr="8px"
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={false}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </LeftChevron>
            <RightChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              widgetArrowPosition={widgetArrowPosition}
              onClick={rightChevronOnClick}
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={false}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </RightChevron>
          </Flex>
        ) : null}
      </Alignment>
    </>
  )
}

export default NavigationMobileCarousel
