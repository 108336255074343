import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import { loadVideoLite, formatSeconds, loadVideoMP4 } from '../helpers'
import { trackEvent } from '../tracking'
import { getChevronStyling, getChevronImg, getChevronImgSize } from './chevronStyles.js'
import { PopularButton } from './ui/PopularButton.js'
import { getAspectRatio } from './EmbedCarousel.js'
import { ImageExpandButton } from './ui/ImageExpandButton.js'

const SlideItem = styled(Box)`
  @keyframes loading-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: -100% 50%;
    }
    100% {
      background-position: -200% 50%;
    }
  }
  cursor: pointer;
  border-radius: ${(props) => `${props.borderRadius}px !important`};
  box-sizing: border-box;
  @media (min-width: 700px) {
    &&:hover {
      .hover {
        display: flex;
      }
    }
  }
`

const VideoImage = styled.img`
  box-sizing: border-box;
  border-radius: ${(props) => `${props.borderRadius}px !important`};
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'} !important;`
      : ''}
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
`

const VideoContainer = styled(Flex)`
  transition: opacity 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

const Video = styled.video`
  transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out;
  box-sizing: border-box;
  border-radius: ${(props) => `${props.borderRadius}px !important`};
  width: ${(props) => props.videoWidth}px !important;
  height: ${(props) => props.videoHeight}px !important;
  object-fit: cover;
  z-index: 1;
  ${(props) =>
    props.isCircle && props.widgetThumbnailBorder && `border: 2.5px solid white; box-sizing: border-box; border-radius: 100%;`}
  &&::-webkit-media-controls {
    display: none !important;
  }
  /* Could Use thise as well for Individual Controls */
  &&::-webkit-media-controls-play-button {
    display: none !important;
  }
  &&::-webkit-media-controls-volume-slider {
    display: none !important;
  }
  &&::-webkit-media-controls-mute-button {
    display: none !important;
  }
  &&::-webkit-media-controls-timeline {
    display: none !important;
  }
  &&::-webkit-media-controls-current-time-display {
    display: none !important;
  }
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'} !important;`
      : ''}
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.widgetTitleFontFamily ? props.widgetTitleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.widgetTitleFontWeight || '700'} !important;
  font-size: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetTitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetTitleFontColor || 'black'};
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) =>
    props.widgetSubtitleFontFamily ? props.widgetSubtitleFontFamily.replace(';') : 'inherit'} !important;
  font-weight: ${(props) => props.widgetSubtitleFontWeight || '500'} !important;
  font-size: ${(props) => props.widgetDesktopSubtitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopSubtitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  }
`

const TitleContainer = styled(Flex)`
  flex-direction: column;
`

const Titles = ({
  isStories,
  videoWidth,
  gapWidth,
  titleVideoObject,
  widgetTitleAlignment,
  widgetDesktopTitleFontSize,
  widgetTitleFontColor,
  widgetTitleFontFamily,
  widgetTitleFontWeight,
  widgetSubtitleFontWeight,
  widgetSubtitleFontFamily,
  widgetSubtitleFontColor,
  widgetDesktopSubtitleFontSize,
  hideMarginLeft,
  hideMarginRight,
  isMobile,
  widgetMobileTitleFontSize,
  widgetMobileSubtitleFontSize
}) => {
  return (
    <TitleContainer
      maxWidth={videoWidth}
      mr={hideMarginRight ? 0 : `${gapWidth / 2}px`}
      ml={hideMarginLeft ? 0 : `${gapWidth / 2}px`}
      width="auto"
    >
      {titleVideoObject.title && (
        <VideoTitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetDesktopTitleFontSize={isMobile ? widgetMobileTitleFontSize : widgetDesktopTitleFontSize}
          widgetTitleFontColor={widgetTitleFontColor}
          widgetTitleFontFamily={widgetTitleFontFamily}
          widgetTitleFontWeight={widgetTitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && !isStories && (
        <VideoSubtitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetDesktopSubtitleFontSize={isMobile ? widgetMobileSubtitleFontSize : widgetDesktopSubtitleFontSize}
          widgetSubtitleFontColor={widgetSubtitleFontColor}
          widgetSubtitleFontFamily={widgetSubtitleFontFamily}
          widgetSubtitleFontWeight={widgetSubtitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </TitleContainer>
  )
}

const InnerTitleContainer = styled(Flex)`
  box-sizing: border-box !important;
  z-index: 1;
  position: absolute;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  height: 150px;

  width: 100%;
  ${(props) =>
    props.widgetTitlePosition === 'INSIDE' &&
    `border-radius: 0 0 ${props.borderRadius}px ${props.borderRadius}px;background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    bottom: 0px;flex-direction: column-reverse;`}
  ${(props) =>
    props.widgetTitlePosition === 'BOTTOM' &&
    `border-radius: 0 0 ${props.borderRadius}px ${props.borderRadius}px;background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    bottom: 0px;flex-direction: column-reverse;`}
  ${(props) =>
    props.widgetTitlePosition === 'TOP' &&
    `border-radius: ${props.borderRadius}px ${props.borderRadius}px 0 0;background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    top:0px;flex-direction: column;padding-top: 16px;`}
   ${(props) =>
    props.widgetTitlePosition === 'MIDDLE' &&
    `border-radius:${props.borderRadius}px;background-color: rgba(10,18,0,0.2);top:0px;flex-direction: column;padding-top: 16px;height: 100%;justify-content:center;h`}
`

const StyledPicture = styled.picture`
  display: block;
  height: 100% !important;
`

const StyledNextPicture = styled.picture`
  z-index: -1;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
`

const PlayButton = styled.svg`
  fill: none !important;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  position: absolute;
  z-index: 2;

  @keyframes cliparapulse4 {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  border-radius: 60px;
  cursor: pointer;
  ${(props) => props.animate && `animation: cliparapulse4 1500ms infinite;`}
  @media(max-width: 1000px) {
    width: 40px !important;
    height: 40px !important;
  }
  ${(props) => {
    if (props.widgetPlayButtonPosition === 'BOTTOM_LEFT') {
      return `bottom: 16px; left: 16px;`
    }
    if (props.widgetPlayButtonPosition === 'BOTTOM_RIGHT') {
      return `bottom: 16px; right: 16px;`
    }
    return ` top: 50%; left: 50%; transform: translate(-50%, -50%);`
  }}
`

const CursorA = styled.a`
  cursor: pointer !important;
`
const PlayButtonComponent = ({ campaign, pbWidth }) => {
  if (campaign.type !== 'GRID') {
    return null
  }
  return (
    <PlayButton
      widgetPlayButtonPosition={campaign.widgetPlayButtonPosition}
      animate={campaign.playButtonAnimation}
      playButtonColor={campaign.playButtonColor || '#333333'}
      width={pbWidth}
      height={pbWidth}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {campaign.widgetPlayButtonStyle === 'FILLED' ? (
        <>
          <circle cx="30" cy="30" r="30" fill={campaign.playButtonColor || '#333333'} />
          <path
            d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <circle cx="30" cy="30" r="28.5" stroke={campaign.playButtonColor || '#333333'} stroke-width="3" />
          <path
            d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
            fill={campaign.playButtonColor || '#333333'}
          />
        </>
      )}
    </PlayButton>
  )
}

const SlideItemComp = ({
  campaign,
  videoHeight,
  videoWidth,
  activeVideoIndex,
  videosToShow,
  i,
  video,
  videoObjects,
  borderRadius,
  isStories,
  isCircle,
  isMobile,
  isNavigation,
  setShowFullscreenPlayer,
  setActiveFullScreenVideo,
  noNextImage,
  playerName,
  isPopular
}) => {
  const {
    widgetTitleActive,
    widgetTitlePosition,
    widgetGapWidth,
    widgetThumbnailBorderSize,
    widgetThumbnailBorder,
    widgetThumbnailBorderColor,
    widgetAutoplay,
    videos
  } = campaign
  const [videoPlaying, setVideoPlaying] = useState(false)
  const isVideo = video.type === 'VIDEO'
  const showNextImage = i === Math.ceil(videosToShow + activeVideoIndex - 1) && !noNextImage
  const imageBoostFactor = 1.5
  const customThumbnailUrl =
    video.customThumbnail || video.customMobileThumbnail
      ? (isMobile && video.customMobileThumbnail ? video.customMobileThumbnail : video.customThumbnail).replace(
          'https://ucarecdn.com/',
          'https://customthumbnail.getclipara.com/'
        )
      : undefined
  const imageUrl = customThumbnailUrl
    ? customThumbnailUrl +
      `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(videoHeight * imageBoostFactor)}/center/`
    : `https://thumbnails.getclipara.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime || 0.0}&width=${Math.round(
        videoWidth * imageBoostFactor
      )}&height=${Math.round(videoHeight * imageBoostFactor)}&fit_mode=crop`
  const webpUrl = customThumbnailUrl
    ? customThumbnailUrl +
      `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(
        videoHeight * imageBoostFactor
      )}/center/-/format/webp/`
    : imageUrl.replace('.png', '.webp')

  // get next image url for lazy load
  let nextImageUrl, nextWebpUrl, nextImageIsVideo, nextImageImageUrl
  if (videos[i + 1]) {
    nextImageIsVideo = videos[i + 1].type === 'VIDEO'
    if (!nextImageIsVideo) {
      nextImageImageUrl = `https://clipara.b-cdn.net/${videos[i + 1].path}?aspect_ratio=${getAspectRatio(
        campaign.widgetThumbnailDimensions
      )}&width=${Math.round(videoWidth * 2)}`
    }
    const nextCustomThumbnailUrl =
      videos[i + 1].customThumbnail || videos[i + 1].customMobileThumbnail
        ? (isMobile && videos[i + 1].customMobileThumbnail
            ? videos[i + 1].customMobileThumbnail
            : videos[i + 1].customThumbnail
          ).replace('https://ucarecdn.com/', 'https://customthumbnail.getclipara.com/')
        : undefined
    nextImageUrl = nextCustomThumbnailUrl
      ? nextCustomThumbnailUrl +
        `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(videoHeight * imageBoostFactor)}/center/`
      : `https://thumbnails.getclipara.com/${videos[i + 1].playbackId}/thumbnail.png?time=${
          videos[i + 1].thumbnailTime || 0.0
        }&width=${Math.round(videoWidth * imageBoostFactor)}&height=${Math.round(videoHeight * imageBoostFactor)}&fit_mode=crop`
    nextWebpUrl = nextCustomThumbnailUrl
      ? nextCustomThumbnailUrl +
        `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(
          videoHeight * imageBoostFactor
        )}/center/-/format/webp/`
      : nextImageUrl.replace('.png', '.webp')
  }

  const titleVideoObject = videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}
  const buttonVideoObject = videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'BUTTON') || {}
  const isFlashpack = campaign.organisationId === 250 || campaign.organisationId === 666
  return (
    <SlideItem
      key={video.id}
      className="keen-slider__slide"
      borderRadius={borderRadius}
      onMouseEnter={async () => {
        if (widgetAutoplay && !isStories && video.type === 'VIDEO') {
          videos.forEach(async (elVideo, i) => {
            if (video.id === elVideo.id) {
              return
            }
            const element = document.getElementById(`__clipara-navigation-carousel-${elVideo.id}-${campaign.id}`)
            if (!element) {
              return
            }
            element.style.opacity = '0'
            return element.pause()
          })
          const element = document.getElementById(`__clipara-navigation-carousel-${video.id}-${campaign.id}`)
          if (element.src || element?.innerHTML?.includes('source')) {
            try {
              setVideoPlaying(true)
              element.play()
              element.style.opacity = '1'
            } catch (e) {
              console.log(e)
            }
          } else {
            await (campaign.useAdaptiveBitrate || campaign.useAdaptiveBitrateCampaign ? loadVideoLite : loadVideoMP4)({
              id: `__clipara-navigation-carousel-${video.id}-${campaign.id}`,
              video,
              campaign,
              useClip: !isFlashpack,
              playerName,
              videoWidth
            })
            element.setAttribute('oncontextmenu', 'return false;')
            element.style.opacity = '1'
            setVideoPlaying(true)
          }
        }
      }}
      onMouseLeave={() => {
        if (widgetAutoplay && !isStories && video.type === 'VIDEO') {
          try {
            const element = document.getElementById(`__clipara-navigation-carousel-${video.id}-${campaign.id}`)
            element.pause()
            element.currentTime = 0
            element.style.opacity = '0'
            setVideoPlaying(false)
          } catch (e) {}
        }
      }}
    >
      <CursorA
        href={isNavigation ? buttonVideoObject.buttonLink : undefined}
        onClick={() => {
          if (!isNavigation) {
            setActiveFullScreenVideo && setActiveFullScreenVideo(i)
            setShowFullscreenPlayer && setShowFullscreenPlayer(true)
          } else {
            trackEvent({
              type: 'CLICK',
              campaignId: campaign.id,
              videoId: video.id,
              playerType: 'WIDGET',
              widgetId: campaign.widgetId
            })
          }
        }}
      >
        <Box position="relative" height={videoHeight}>
          <VideoContainer isMobile={isMobile} isStories={isStories} videoPlaying={videoPlaying}>
            <Video
              widgetThumbnailBorderSize={widgetThumbnailBorderSize}
              widgetThumbnailBorderColor={widgetThumbnailBorderColor}
              widgetThumbnailBorder={widgetThumbnailBorder}
              borderRadius={borderRadius}
              videoWidth={videoWidth}
              videoHeight={videoHeight}
              loop
              isCircle={isCircle}
              muted
              id={`__clipara-navigation-carousel-${video.id}-${campaign.id}`}
              preload="metadata"
              playsInline
              poster={navigator.userAgent.includes('wv') ? imageUrl : undefined}
              webkit-playsInline
              x-webkit-airplay="allow"
            />
          </VideoContainer>
          {isVideo ? (
            <StyledPicture>
              <source srcset={webpUrl} type="image/webp" />
              <source srcset={imageUrl} type="image/png" />
              <VideoImage
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
                borderRadius={borderRadius}
                isCircle={isCircle}
                loading="lazy"
                src={imageUrl}
                alt={video.name}
              />
            </StyledPicture>
          ) : (
            <VideoImage
              widgetThumbnailBorderSize={widgetThumbnailBorderSize}
              widgetThumbnailBorderColor={widgetThumbnailBorderColor}
              widgetThumbnailBorder={widgetThumbnailBorder}
              borderRadius={borderRadius}
              isCircle={isCircle}
              loading="lazy"
              src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(
                campaign.widgetThumbnailDimensions
              )}&width=${Math.round(videoWidth * 2)}`}
              alt={video.name}
            />
          )}
          {showNextImage && nextImageUrl && nextWebpUrl ? (
            nextImageIsVideo ? (
              <StyledNextPicture>
                <source srcset={nextWebpUrl} type="image/webp" />
                <source srcset={nextImageUrl} type="image/png" />
                <VideoImage
                  widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                  widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                  widgetThumbnailBorder={widgetThumbnailBorder}
                  borderRadius={borderRadius}
                  isCircle={isCircle}
                  loading="lazy"
                  src={nextImageUrl}
                  alt={video.name}
                />
              </StyledNextPicture>
            ) : (
              <VideoImage
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
                borderRadius={borderRadius}
                isCircle={isCircle}
                loading="lazy"
                src={nextImageImageUrl}
                alt={video.name}
              />
            )
          ) : null}
          {isVideo ? <PlayButtonComponent pbWidth={60} campaign={campaign} /> : <ImageExpandButton />}

          {titleVideoObject &&
          widgetTitleActive &&
          (widgetTitlePosition === 'INSIDE' ||
            widgetTitlePosition === 'BOTTOM' ||
            widgetTitlePosition === 'MIDDLE' ||
            widgetTitlePosition === 'TOP') ? (
            <InnerTitleContainer borderRadius={borderRadius} widgetTitlePosition={widgetTitlePosition}>
              <Titles
                {...campaign}
                isMobile={isMobile}
                isStories={isStories}
                videoWidth={videoWidth}
                titleVideoObject={titleVideoObject}
              />
            </InnerTitleContainer>
          ) : null}

          {isPopular && campaign.isDownloadCampaign ? <PopularButton campaign={campaign} isPopular={isPopular} /> : null}
        </Box>
        {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'BELOW' ? (
          <Box mt="16px">
            <Titles
              {...campaign}
              isMobile={isMobile}
              isStories={isStories}
              videoWidth={videoWidth}
              gapWidth={widgetGapWidth}
              titleVideoObject={titleVideoObject}
              hideMarginLeft={i === 0}
              hideMarginRight={i === videos.length - 1}
            />
          </Box>
        ) : null}
      </CursorA>
    </SlideItem>
  )
}

export default SlideItemComp
