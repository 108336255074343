export const BUTTON_STYLES = {
  CIRCLE_CHEVRON: 'CIRCLE_CHEVRON',
  CIRCLE_ARROW: 'CIRCLE_ARROW',
  SQUARE_CHEVRON: 'SQUARE_CHEVRON',
  SQUARE_ARROW: 'SQUARE_ARROW',
  NONE_CHEVRON: 'NONE_CHEVRON',
  NONE_ARROW: 'NONE_ARROW'
}
export const getChevronStyling = (style, color, isStories) => {
  if (isStories) {
    return `svg {
      stroke: none !important; fill: none !important;
    }`
  }
  if (style === BUTTON_STYLES.SQUARE_CHEVRON || style === BUTTON_STYLES.SQUARE_ARROW) {
    return `box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.15);
        border-radius: 4px;
        background-color: ${color || 'white'};
          ${
            color === 'white' || !color || color === '#ffffff' || color === '#FFFFFF'
              ? 'border: 1px solid rgba(203, 213, 224, 1);'
              : ''
          }
        `
  }
  if (style === BUTTON_STYLES.NONE_CHEVRON || style === BUTTON_STYLES.NONE_ARROW) {
    return ``
  }
  return `box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.15);
        border-radius: 25px;
        background-color: ${color || 'white'};
          ${
            color === 'white' || !color || color === '#ffffff' || color === '#FFFFFF'
              ? 'border: 1px solid rgba(203, 213, 224, 1);'
              : ''
          }
        `
}

export const getChevronImg = (style, widgetChevronPrimaryColor, isStories) => {
  if (isStories) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM12.7163 4.28831C12.3367 3.90768 11.7191 3.90581 11.3376 4.28479L6.28731 9.3112C5.90583 9.68995 5.90395 10.3061 6.28378 10.687L11.3376 15.7169C11.7191 16.0959 12.3367 16.094 12.7163 15.7134C13.0961 15.3325 13.0942 14.7164 12.7127 14.3376L8.35536 10.0007L12.7127 5.66409C13.0942 5.28534 13.0961 4.66917 12.7163 4.28831Z"
          fill="#FAFAFA"
        />
      </svg>
    )
  }
  if (style && style.includes('ARROW')) {
    return (
      <svg
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill={widgetChevronPrimaryColor || 'black'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5 8.5C24.5 8.99092 24.1162 9.38889 23.6429 9.38889L3.42647 9.38889L8.82038 14.9826C9.15511 15.3297 9.15511 15.8925 8.82038 16.2397C8.48564 16.5868 7.94293 16.5868 7.60819 16.2397L0.751051 9.12854C0.416317 8.78141 0.416317 8.21859 0.751051 7.87146L7.60819 0.76035C7.94293 0.413217 8.48564 0.413217 8.82038 0.76035C9.15511 1.10748 9.15511 1.6703 8.82038 2.01743L3.42647 7.61111L23.6429 7.61111C24.1162 7.61111 24.5 8.00908 24.5 8.5Z"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )
  }
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill={widgetChevronPrimaryColor || 'black'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0303 19.5303C10.7374 19.8232 10.2626 19.8232 9.96967 19.5303L0.96967 10.5303C0.676777 10.2374 0.676777 9.76256 0.96967 9.46967L9.96967 0.469669C10.2626 0.176777 10.7374 0.176777 11.0303 0.469669C11.3232 0.762564 11.3232 1.23744 11.0303 1.53033L2.56066 10L11.0303 18.4697C11.3232 18.7626 11.3232 19.2374 11.0303 19.5303Z"
      />
    </svg>
  )
}

export const getChevronImgSize = (style, isStories) => {
  if (isStories) {
    return '18px'
  }
  if (style === BUTTON_STYLES.NONE_CHEVRON || style === BUTTON_STYLES.NONE_ARROW) {
    return '24px'
  }
  return '18px'
}
