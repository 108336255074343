import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Preview from './Preview'
import InlinePlayer from './InlinePlayer'
import Fullscreen from './Fullscreen'
import { Portal } from './Portal'
import { Box, Flex } from './ui/Layout'
import Embed from './Embed'
import './App.css'
import { getDisplaySize, loadVideo } from './helpers'
import { trackEvent, setGetCookie, gaEvent } from './tracking'
import Search from './Components/Search/Search'
import ReactDOM from 'react-dom'

const fetchUrl =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/widget/get' : 'https://api.getclipara.com/widget/get'

const Container = styled.div`
  *:empty {
    display: block !important;
  }
  position: fixed;
  bottom: 16px;
  z-index: 2147483647;
  transition: width 0.3s, height 0.3s, left 0.3s, right 0.3s;
  box-sizing: border-box !important;
  p {
    font-family: ${(props) => props.titleFontFamily || 'Inter'} !important;
    line-height: 20px;
    font-size: unset;
    font-weight: 400;
    margin: unset;
    padding: unset;
    text-decoration: unset;
  }
  button {
    p {
      font-family: ${(props) => props.fontFamily || 'Inter'} !important;
    }
    font-family: ${(props) => props.fontFamily || 'Inter'} !important;
  }
  span {
    font-family: ${(props) => props.titleFontFamily || 'Inter'} !important;
  }
  select {
    font-family: ${(props) => props.fontFamily || 'Inter'} !important;
  }
  p {
    box-sizing: border-box !important;
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`

const SlideInContainer = styled(Container)`
  ${(props) => (!props.slideIn ? `${props.LR}: -500px;` : `${props.LR}: 16px;`)}
  p {
    font-family: ${(props) => props.titleFontFamily || 'Inter'} !important;
  }
  button {
    font-family: ${(props) => props.fontFamily || 'Inter'} !important;
  }
  span {
    font-family: ${(props) => props.titleFontFamily || 'Inter'} !important;
  }
  select {
    font-family: ${(props) => props.fontFamily || 'Inter'} !important;
  }
`

const initFloater = (type, setSlideIn) => {
  if (type === 'FLOATING') {
    setTimeout(() => {
      setSlideIn(true)
    }, 2000)
  }
}

const initCustomLauncher = (isCustomLauncher, campaignId, setShowFullscreenPlayer) => {
  if (isCustomLauncher) {
    const element = document.querySelector(`[data-campaign-id="${campaignId}"]`)
    if (element) {
      element.onclick = () => {
        setShowFullscreenPlayer(true)
      }
    }
  }
}

const App = ({ campaignId }) => {
  const [campaign, setCampaign] = useState()
  const [slideIn, setSlideIn] = useState(false)
  const [showPreview, setShowPreview] = useState(true)
  const [previewMinimised, setPreviewMinimised] = useState(false)
  const [showInlinePlayer, setShowInlinePlayer] = useState(false)
  const [showFullscreenPlayer, setShowFullscreenPlayer] = useState(false)
  const [hasLoadedScripts, setHasLoadedScripts] = useState(false)
  const [hasEnded, setHasEnded] = useState(false)
  const [activeFullScreenVideo, setActiveFullScreenVideo] = useState(0)
  useEffect(() => {
    if (!campaignId && window.__clipara && window.__clipara.campaign) {
      // console.log('Single Campaign')
      setCampaign(window.__clipara.campaign)
      initFloater(window.__clipara.campaign.type, setSlideIn)
    } else if (campaignId && window.__clipara && window.__clipara.campaigns) {
      // console.log('Multi Campaign')
      const newCampaign = window.__clipara.campaigns.find((el) => el.id === Number(campaignId))
      setCampaign(newCampaign)
      initFloater(newCampaign.type, setSlideIn)
      initCustomLauncher(newCampaign.isCustomLauncher, campaignId, setShowFullscreenPlayer)
    } else {
      // console.log('No script campaign')
      fetch(
        fetchUrl +
          '?' +
          new URLSearchParams({
            url: window.location.href,
            organisationId: window.__clipara.organisationId,
            campaignId: window.__clipara.campaignId,
            isShopify: false,
            campaignIds: Array.from(document.querySelectorAll('[data-campaign-id]')).map((el) =>
              el.getAttribute('data-campaign-id')
            )
          }),
        {
          credentials: 'same-origin'
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            console.log('No clipara match for url')
            return
          }
          setCampaign(data.payload)
          initFloater(data.payload.type, setSlideIn)
          initCustomLauncher(data.payload.isCustomLauncher, data.payload.id, setShowFullscreenPlayer)
        })
    }
  }, [])

  // GA TRACKING
  useEffect(() => {
    if (showFullscreenPlayer) {
      gaEvent({
        isGaEnabled: campaign.gaTracking,
        name: 'clipara_widget_open',
        data: {
          campaign_name: campaign.name
        }
      })
    }
  }, [showFullscreenPlayer])

  useEffect(() => {
    if (campaign && campaign.id) {
      try {
        document.body.insertAdjacentHTML('afterend', `<!-- CLIPARA VIDEO PLAYER WWW.GETCLIPARA.COM -->`)
      } catch (e) {}
      console.log({ campaign })
      console.log('CLIPARA VIDEO PLAYER LOADED - WWW.GETCLIPARA.COM')

      if (campaign.noCookies) {
        try {
          window.__clipara.noCookies = true
        } catch (e) {}
      }
      trackEvent({
        type: 'LOAD',
        campaignId: campaign.id,
        playerType: campaign.autoOpen ? 'SHARE' : 'WIDGET',
        widgetId: campaign.widgetId
      })
      if (window.__clipara.autoOpen) {
        if (campaign.sharingTitle) {
          window.document.title = campaign.sharingTitle
        }
        setShowFullscreenPlayer(true)
      }
    }
  }, [campaign && campaign.id])

  if (!campaign || !campaign.id) {
    return null
  }

  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  const expandSizes = getDisplaySize(w < 800 ? w - 70 : 800, h < 600 ? h - 70 : 600, campaign.video?.aspectRatioCalc)
  let previewWidth = expandSizes.isLandscape ? 230 : 142
  let previewHeight = expandSizes.isLandscape ? 140 : 200

  const config = {
    w,
    h,
    LR: campaign.position === 'BOTTOM_LEFT' ? 'left' : 'right',
    LRO: campaign.position !== 'BOTTOM_LEFT' ? 'left' : 'right',
    expandWidth: expandSizes.displayWidth,
    expandHeight: expandSizes.displayHeight,
    isLandscape: expandSizes.isLandscape,
    previewWidth,
    previewHeight,
    isMobile: w < 700,
    gifUrl: `https://image.mux.com/${campaign.video?.playbackId}/animated.gif?${
      expandSizes.isLandscape ? `height=${previewHeight}` : `width=${previewWidth}`
    }&start=${campaign.video?.thumbnailTime}&end=${campaign.video?.thumbnailTime + 3}&fps=8`
  }

  const previewClick = async () => {
    setShowPreview(false)
    if (campaign.videos.length > 1 || campaign.isShoppable) {
      setShowFullscreenPlayer(true)
    } else {
      setShowInlinePlayer(true)
      if (!hasLoadedScripts) {
        setTimeout(() => loadVideo({ campaign, setHasEnded }), 300)
        setHasLoadedScripts(true)
      } else {
        if (campaign.videos[0].type === 'VIDEO') {
          document.getElementById('__clipara-player').play()
        }
      }
    }
  }

  const closeInlinePlayer = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (campaign.videos[0].type === 'VIDEO') {
      document.getElementById('__clipara-player').pause()
    }
    setShowInlinePlayer(false)
    setTimeout(() => setShowPreview(true), 299)
  }

  return (
    <>
      {campaign.type === 'FLOATING' ? (
        <SlideInContainer id="__clipara-video" slideIn={slideIn} LR={config.LR} fontFamily={campaign.fontFamily}>
          <Preview
            previewClick={previewClick}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            setSlideIn={setSlideIn}
            campaign={campaign}
            config={config}
            previewMinimised={previewMinimised}
            setPreviewMinimised={setPreviewMinimised}
          />
          <InlinePlayer
            setShowFullscreenPlayer={setShowFullscreenPlayer}
            closeInlinePlayer={closeInlinePlayer}
            hasLoadedScripts={hasLoadedScripts}
            setShowInlinePlayer={setShowInlinePlayer}
            showInlinePlayer={showInlinePlayer}
            campaign={campaign}
            config={config}
            hasEnded={hasEnded}
            previewMinimised={previewMinimised}
            setPreviewMinimised={setPreviewMinimised}
          />
        </SlideInContainer>
      ) : null}
      {campaign.type === 'SEARCH' ? <Search campaign={campaign} /> : null}
      {campaign.type === 'CAROUSEL' ||
      campaign.type === 'SINGLE_EMBED' ||
      campaign.type === 'GRID' ||
      campaign.type === 'STORIES' ||
      campaign.type === 'NAVIGATION_CAROUSEL' ||
      campaign.type === 'NAVIGATION_GRID' ||
      campaign.type === 'INLINE_CAROUSEL' ||
      campaign.type === 'INLINE_SINGLE' ? (
        <Embed
          w={w}
          showFullscreenPlayer={showFullscreenPlayer}
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          campaign={campaign}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      ) : null}
      {ReactDOM.createPortal(
        <Container fontFamily={campaign.fontFamily} titleFontFamily={campaign.titleFontFamily}>
          <Fullscreen
            hasLoadedScripts={hasLoadedScripts}
            showFullscreenPlayer={showFullscreenPlayer}
            setShowFullscreenPlayer={setShowFullscreenPlayer}
            setShowPreview={setShowPreview}
            campaign={campaign}
            config={config}
            hasEnded={hasEnded}
            activeFullScreenVideo={activeFullScreenVideo}
            setActiveFullScreenVideo={setActiveFullScreenVideo}
          />
        </Container>,
        document.getElementById(`__clipara-portal-${campaign.id}`) || document.getElementById(`__clipara-portal`)
      )}
    </>
  )
}

export default App
