import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'

const Label = styled.p`
  margin-bottom: 8px !important;
  text-decoration: none;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #425466 !important;
  font-family: 'Inter';
  font-size: 14px !important;
  text-align: left !important;
`

const Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  height: 40px !important;

  &&::-ms-expand {
    display: none;
  }

  outline: none;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  cursor: pointer;

  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  height: 36px;
  padding-left: 10px !important;
  padding-right: 20px !important;

  text-decoration: none;
  font-weight: 600;
  color: #27272e !important;
  font-family: 'Inter';
  font-size: 14px !important;

  option {
    white-space: normal;

    outline-color: ;
  }
`

const SelectDiv = styled(Flex)`
  position: relative;
  margin-bottom: 16px;
  // Custom arrow
  &&:after {
    grid-area: select;
    box-sizing: border-box;
    content: '';
    z-index: 1;
    background-color: grey;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    width: 10px;
    margin-left: -10px;
    height: 6px;
    position: absolute;
    right: 10px;
    top: 18px;
  }
`

const SelectComponent = ({ value, values, name, onChange }) => {
  return (
    <>
      <Label>{name}</Label>
      <SelectDiv>
        <Select value={value} onChange={(e) => onChange(e.target.value)} key={name} name={name}>
          {values.map((value) => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            )
          })}
        </Select>
      </SelectDiv>
    </>
  )
}

export default SelectComponent
