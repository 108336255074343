import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { trackEvent } from '../../tracking.js'
import axios from 'axios'
import {
  getGridResults,
  getNumberOfColumns,
  searchUrl,
  tagsFetchUrl,
  Button,
  DurationButton,
  PlayButtonComponent,
  updateQueryString,
  getStateFromQuery,
  PopularButton
} from './SearchComponents.js'
import { baseUrl, loadVideo, loadVideoLite, loadVideoMP4 } from '../../helpers.js'
import { SearchInput } from './Input.js'

const Image = styled.img`
  box-sizing: border-box !important;
  border-radius: ${(props) => `${props.borderRadius}px`} !important;
  object-fit: cover;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'} !important;`
      : ''}
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
`

const ImageResult = ({ image, isMobile, campaign, onClick, columnWidth, columnGap }) => {
  const src = `https://clipara.b-cdn.net/${image.path}?width=${Math.round(columnWidth * 2)}`
  const height = Math.round(columnWidth) / image.aspectRatioCalc
  return (
    <Flex
      onClick={onClick}
      cursor="pointer"
      key={image.id}
      mb={columnGap}
      bg="#dcdedc"
      width={columnWidth}
      height={height}
      position="relative"
      borderRadius={campaign.borderRadius}
    >
      <PopularButton campaign={campaign} rank={image.rank} />

      <Image
        width={columnWidth}
        height={height}
        borderRadius={campaign.borderRadius}
        widgetThumbnailBorder={campaign.widgetThumbnailBorder}
        widgetThumbnailBorderSize={campaign.widgetThumbnailBorderSize}
        widgetThumbnailBorderColor={campaign.widgetThumbnailBorderColor}
        loading="lazy"
        src={src}
        alt={image.name}
      />
    </Flex>
  )
}

export default ImageResult
