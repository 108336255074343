import { useEffect, useState, useRef, memo } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { gaEvent, trackEvent } from '../../tracking.js'
import axios from 'axios'
import {
  columnWidth,
  getGridResults,
  getNumberOfColumns,
  searchUrl,
  tagsFetchUrl,
  Button,
  DurationButton,
  PlayButtonComponent,
  updateQueryString,
  getStateFromQuery,
  FullScreenChevrons,
  NextImages,
  MuteControls,
  getColumnWidth
} from './SearchComponents.js'
import { baseUrl, loadSpecificVideo, loadSpecificVideoMP4, loadVideoMP4, usePreventScroll } from '../../helpers.js'
import VideoResult from './VideoResult.js'
import Spinner from '../../ui/Spinner.js'
import { Portal } from '../../Portal.js'
import ImageResult from './ImageResult.js'
import { DownloadButton } from './DownloadButton.js'
import { MobileVideoDetailsPopover, VideoDetailsPopover } from './VideoDetailsPopover.js'

const Image = styled.img`
  width: ${(props) => props.videoWidth}px;
  height: ${(props) => props.videoHeight}px;
  min-height: ${(props) => props.videoHeight}px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
`

const Title = styled.p`
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #27272e !important;
`

const CloseContainer = styled(Flex)`
  img {
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 30px;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0px 0px 2px 0px #00000040;
  background-color: white;
  border-radius: 20px;
  z-index: 2147483647;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(203, 213, 224, 1);
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isMobile
      ? `left: 16px;
    top: 16px;
    border: none;
    background-color: rgba(91, 91, 91, 0.4);
    path {
      fill: white !important;
    }`
      : ''}
`

const Video = styled.video`
  ${(props) => props.hide && 'position: absolute; left: 0; top: 0;'}
  height: ${(props) => props.videoHeight}px;
  width: ${(props) => props.videoWidth}px;
  z-index: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(0%, -50%, 0);
  -webkit-transform: translate3d(0%, -50%, 0);
  object-fit: unset !important;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  isolation: isolate;
`

export const StyledPicture = styled.picture`
  height: 100% !important;
`

export const VideoImage = styled.img`
  width: ${(props) => props.videoWidth}px;
  height: ${(props) => props.videoHeight}px;
  min-height: ${(props) => props.videoHeight}px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(0%, -50%, 0);
  -webkit-transform: translate3d(0%, -50%, 0);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  isolation: isolate;
`

const VideoContainer = styled(Flex)`
  margin-top: 28px;
  justify-content: center;
  position: relative;
  width: ${(props) => props.videoWidth}px;
  height: ${(props) => props.videoHeight}px;
  min-height: ${(props) => props.videoHeight}px;
  ${(props) =>
    props.isMobile
      ? `margin-top: 8px;
    margin-right: 8px;
    margin-left: 8px;`
      : ''}
  background-color: #dcdedc;
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  isolation: isolate;
`

const SimilarContainer = styled(Flex)`
  box-sizing: border-box;
  @media (max-width: 700px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`

const CliparaLogo = styled.img`
  margin-top: 12px;
`

const CustomerLogo = styled.img`
  position: absolute;
  top: 11px;
  right: 16px;
  max-height: 70px;
  max-width: 120px;
  object-fit: contain;
  z-index: 100002;
  ${(props) => (props.isMobile ? 'display: none;' : '')}
`

const ExtraResults = memo(
  ({ campaign, isMobile, extraGridResults, columnGap, columnWidth, activeResult, resultOnClick }) => {
    return (
      <Flex justifyContent="center">
        {extraGridResults.map((column, i) => {
          const isOffset = !isMobile && campaign.widgetSearchGridOffset && i % 2 === 1
          return (
            <Flex
              key={i}
              mt={isOffset ? '30px' : '0px'}
              flexDirection="column"
              mr={i === extraGridResults.length - 1 ? '0px' : `${columnGap}px`}
              width={columnWidth}
            >
              {column
                .filter((el) => activeResult.id !== el.id)
                .map((videoOrImage) => {
                  if (videoOrImage.type === 'IMAGE') {
                    return (
                      <ImageResult
                        key={videoOrImage.id}
                        columnWidth={columnWidth}
                        columnGap={columnGap}
                        onClick={() => resultOnClick(videoOrImage.id)}
                        campaign={campaign}
                        image={videoOrImage}
                        isMobile={isMobile}
                      />
                    )
                  }
                  return (
                    <VideoResult
                      key={videoOrImage.id}
                      columnWidth={columnWidth}
                      columnGap={columnGap}
                      onClick={() => resultOnClick(videoOrImage.id)}
                      campaign={campaign}
                      video={videoOrImage}
                      isMobile={isMobile}
                    />
                  )
                })}
            </Flex>
          )
        })}
      </Flex>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.isFullScreenOpen === nextProps.isFullScreenOpen && nextProps.activeResult?.id === prevProps.activeResult?.id
  }
)

const FullScreen = ({
  isMobile,
  campaign,
  isFullScreenOpen,
  setIsFullScreenOpen,
  fullScreenIndex,
  setFullScreenIndex,
  allResults,
  numberOfColumns,
  tagOnClick,
  columnGap
}) => {
  usePreventScroll()

  const fullScreenWidgetContainerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const fullScreenColumnWidth = getColumnWidth({
    isMobile,
    widgetContainerWidth: fullScreenWidgetContainerWidth,
    widgetSearchThumbnailSize: campaign.widgetSearchThumbnailSize
  })
  const fullScreenMaxWidth = (fullScreenColumnWidth + columnGap) * numberOfColumns - columnGap

  const [hasEnded, setHasEnded] = useState(false)
  const [hasPlayed, setHasPlayed] = useState('NOT_YET')
  const isWaitingRef = useRef()
  const [isWaiting, setIsWaiting] = useState(false)
  const [showWaitingSpinner, setShowWaitingSpinner] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [showDetailsPopover, setShowDetailsPopover] = useState(false)

  const player = useRef()

  const activeResult = allResults[fullScreenIndex]
  let videoHeight = 600
  let videoWidth = activeResult.aspectRatioCalc * videoHeight

  if (isMobile) {
    videoWidth = fullScreenWidgetContainerWidth - 16
    const maxVideoHeight = (fullScreenWidgetContainerWidth - 16) / activeResult.aspectRatioCalc
    if (maxVideoHeight > 600) {
      videoHeight = 600
    } else {
      videoHeight = maxVideoHeight
    }
  }

  useEffect(() => {
    isWaitingRef.current = isWaiting
    if (isWaiting) {
      setTimeout(() => {
        if (isWaitingRef.current) {
          setShowWaitingSpinner(true)
        }
      }, 1500)
    } else {
      setShowWaitingSpinner(false)
    }
  }, [isWaiting])

  useEffect(() => {
    const player = document.getElementById(`__clipara-search-main-video-${activeResult.id}`)

    try {
      gaEvent({
        isGaEnabled: campaign?.gaTracking,
        name: 'clipara_video_play',
        data: {
          campaign_name: campaign?.name,
          video_name: activeResult?.name
        }
      })
      trackEvent({
        type: 'PLAY',
        campaignId: campaign.id,
        videoId: activeResult.id,
        playerType: 'WIDGET',
        widgetId: campaign.widgetId
      })
    } catch (e) {
      console.log(e)
    }
    if (!player) {
      return
    }
    loadSpecificVideoMP4({
      campaign: campaign,
      playerName: 'search',
      campaignId: campaign.id,
      organisationId: campaign.organisationId,
      video: activeResult,
      playerId: `__clipara-search-main-video-${activeResult.id}`,
      setHasEnded,
      setHasPlayed,
      widgetId: campaign.widgetId,
      maxResolution: '1080p',
      useHigh: true
    })
    player.setAttribute('oncontextmenu', 'return false;')
    player.addEventListener('waiting', (e) => {
      setIsWaiting(true)
    })
    player.addEventListener('playing', (e) => {
      setIsWaiting(false)
    })
    player.addEventListener('play', () => {
      setHasPlayed(true)
    })
  }, [fullScreenIndex])

  useEffect(() => {
    const player = document.getElementById(`__clipara-search-main-video-${activeResult.id}`)
    if (!player || !fullScreenIndex) {
      return
    }
  }, [])

  const resultOnClick = (id) => {
    const i = allResults.findIndex((v) => v.id === id)
    setFullScreenIndex(i)
    document.getElementById('__clipara-fullscreen-container').scrollTop = 0
  }

  const extraGridResults = getGridResults(
    allResults.filter((el) => el.id !== activeResult.id),
    numberOfColumns
  )

  const leftChevronOnClick = () => {
    if (fullScreenIndex === 0) {
      setFullScreenIndex(allResults.length - 1)
    } else {
      setFullScreenIndex(fullScreenIndex - 1)
    }
  }
  const rightChevronOnClick = () => {
    if (fullScreenIndex === allResults.length - 1) {
      setFullScreenIndex(0)
    } else {
      setFullScreenIndex(fullScreenIndex + 1)
    }
  }

  const close = () => {
    const player = document.getElementById(`__clipara-search-main-video-${activeResult.id}`)
    if (player) {
      player.removeAttribute('src')
    }
    // window.history.replaceState({}, '', `${window.location.pathname}${window.location.search.replace(/&modal=true/g, '')}`)
    setIsFullScreenOpen(false)
    document.getElementById('__clipara-embed').scrollIntoView(true)
  }

  return (
    <Flex
      position="fixed"
      left="0"
      top="0"
      height="100%"
      width="100%"
      backgroundColor="white"
      flexDirection="column"
      alignItems="center"
      bg={campaign.widgetSearchOverlayBackgroundColor}
      zIndex="2147483647"
      overflowY="scroll"
      id="__clipara-fullscreen-container"
    >
      <CloseContainer onClick={close} isMobile={isMobile}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 11L11 1M11 11L1 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.410704 0.410582C0.736141 0.0851447 1.26378 0.0851447 1.58921 0.410582L5.99996 4.82133L10.4107 0.410582C10.7361 0.0851447 11.2638 0.0851447 11.5892 0.410582C11.9147 0.736018 11.9147 1.26366 11.5892 1.58909L7.17847 5.99984L11.5892 10.4106C11.9147 10.736 11.9147 11.2637 11.5892 11.5891C11.2638 11.9145 10.7361 11.9145 10.4107 11.5891L5.99996 7.17835L1.58921 11.5891C1.26378 11.9145 0.736141 11.9145 0.410704 11.5891C0.0852667 11.2637 0.0852667 10.736 0.410704 10.4106L4.82145 5.99984L0.410704 1.58909C0.0852667 1.26366 0.0852667 0.736018 0.410704 0.410582Z"
            fill="black"
          />
        </svg>
      </CloseContainer>
      {campaign.logoUrl ? <CustomerLogo isMobile={isMobile} width="160px" alt="logo" src={campaign.logoUrl} /> : null}

      <VideoContainer videoHeight={videoHeight} videoWidth={videoWidth} borderRadius={campaign.borderRadius} isMobile={isMobile}>
        {activeResult.type === 'VIDEO' ? <MuteControls isMobile={isMobile} isMuted={isMuted} setIsMuted={setIsMuted} /> : null}
        <NextImages
          allResults={allResults}
          fullScreenIndex={fullScreenIndex}
          videoHeight={videoHeight}
          widgetContainerWidth={fullScreenWidgetContainerWidth}
          isMobile={isMobile}
        />
        {allResults.map((videoOrImage, i) => {
          if (i !== fullScreenIndex) {
            return null
          }

          let videoHeight = 600
          let videoWidth = activeResult.aspectRatioCalc * videoHeight

          if (isMobile) {
            videoWidth = fullScreenWidgetContainerWidth - 16
            const maxVideoHeight = (fullScreenWidgetContainerWidth - 16) / activeResult.aspectRatioCalc
            videoHeight = maxVideoHeight
          }
          const imageUrl = `https://thumbnails.getclipara.com/${
            videoOrImage.playbackId
          }/thumbnail.png?time=0.0&width=${Math.round(videoWidth)}&height=${Math.round(videoHeight)}&fit_mode=crop`
          const webpUrl = imageUrl.replace('.png', '.webp')

          if (videoOrImage.type === 'IMAGE') {
            const src = `https://clipara.b-cdn.net/${videoOrImage.path}?width=${Math.round(videoWidth * 2)}`
            return (
              <Image
                borderRadius={campaign.borderRadius}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                src={src}
                alt={activeResult.name}
              />
            )
          }

          return (
            <>
              <StyledPicture>
                <source srcset={webpUrl} type="image/webp" />
                <source srcset={imageUrl} type="image/png" />
                <VideoImage
                  borderRadius={campaign.borderRadius}
                  videoWidth={videoWidth}
                  videoHeight={videoHeight}
                  src={webpUrl}
                  alt={activeResult.name}
                />
              </StyledPicture>
              <Video
                key={videoOrImage.id}
                id={`__clipara-search-main-video-${videoOrImage.id}`}
                ref={player}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                preload="metadata"
                playsInline
                webkit-playsInline
                autoPlay
                muted={isMuted}
                x-webkit-airplay="allow"
                loop
                borderRadius={campaign.borderRadius}
              />
            </>
          )
        })}
        <DownloadButton activeResult={activeResult} campaign={campaign} setShowDetailsPopover={setShowDetailsPopover} />
        {activeResult.type === 'VIDEO' ? <DurationButton duration={activeResult.duration} bottomLeft /> : null}

        {showWaitingSpinner && <Spinner zIndex={100} />}
      </VideoContainer>

      <FullScreenChevrons
        isMobile={isMobile}
        campaign={campaign}
        rightChevronOnClick={rightChevronOnClick}
        leftChevronOnClick={leftChevronOnClick}
        videoHeight={videoHeight}
      />

      {activeResult?.tagsArray?.length ? (
        <SimilarContainer flexDirection="column" mt="24px">
          <Flex flexWrap="wrap" justifyContent="center">
            {activeResult.tagsArray.map((tag) => {
              if (tag === 'High Quality Download') {
                return null
              }
              return (
                <Button
                  label={tag}
                  onClick={() => {
                    tagOnClick(tag)
                    close()
                  }}
                  widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
                  backgroundColor={campaign.buttonColor}
                  color={campaign.buttonFontColor}
                  border={`1px solid ${campaign.buttonFontColor}`}
                  key={tag}
                />
              )
            })}
          </Flex>
        </SimilarContainer>
      ) : null}

      <Flex mt="50px" maxWidth={fullScreenMaxWidth} justifyContent="space-between" alignItems="center">
        <Title>More to explore</Title>
      </Flex>

      <ExtraResults
        campaign={campaign}
        extraGridResults={extraGridResults}
        columnWidth={fullScreenColumnWidth}
        columnGap={columnGap}
        resultOnClick={resultOnClick}
        isMobile={isMobile}
        activeResult={activeResult}
        isFullScreenOpen={isFullScreenOpen}
      />
      {campaign && campaign.organisationId !== 747 && campaign.organisationId !== 2 ? (
        <Flex justifyContent="flex-end" paddingRight="24px" boxSizing="border-box">
          <a href="https://www.getclipara.com/?utm_source=search-widget" target="_blank" rel="noreferrer">
            <img width={120} height={30} src={`${baseUrl}/__clipara-powered-by-dark.svg`} alt="powered by clipara" />
          </a>
        </Flex>
      ) : null}
      <VideoDetailsPopover
        activeResult={activeResult}
        campaign={campaign}
        showDetailsPopover={showDetailsPopover}
        setShowDetailsPopover={setShowDetailsPopover}
      />
    </Flex>
  )
}

const FullscreenWithPortal = (props) => {
  if (!props.isFullScreenOpen) {
    return null
  }
  return (
    // <Portal id={props.campaign.id}>
    <FullScreen {...props} />
    // </Portal>
  )
}

export default FullscreenWithPortal
