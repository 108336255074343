import { setGetCookie, deviceType, gaEvent } from './tracking'

export const getDisplaySize = (maxWidth, maxHeight, aspectRatioCalc) => {
  const isLandscape = aspectRatioCalc > 1
  const displayWidth = Math.round(isLandscape ? maxWidth : maxHeight * aspectRatioCalc)
  const displayHeight = Math.round(isLandscape ? maxWidth / aspectRatioCalc : maxHeight)
  return { isLandscape, displayWidth, displayHeight }
}

export const getContainerElement = (campaignId) => {
  const containerElements = Array.from(document.querySelectorAll('#__clipara-embed'))
  if (containerElements && containerElements.length === 1) {
    return containerElements[0]
  }

  const campaignSpecificElement = document.querySelector(`#__clipara-embed[data-campaign-id="${campaignId}"]`)
  if (campaignSpecificElement) {
    return campaignSpecificElement
  }

  return document.getElementById('__clipara-embed')
}

export const loadScript = (url) => {
  try {
    return new Promise(function (resolve, reject) {
      try {
        let script = document.createElement('script')
        script.src = url
        script.async = false
        script.onload = function () {
          resolve(url)
        }
        script.onerror = function () {
          resolve(url)
        }
        document.body.appendChild(script)
      } catch (e) {
        console.log(e)
        resolve()
      }
    })
  } catch (e) {
    console.log(e)
    return
  }
}

export const loadScripts = async () => {
  let promises = [loadScript('https://cdn.jsdelivr.net/npm/hls.js@latest'), loadScript('https://src.litix.io/core/4/mux.js')]
  try {
    await Promise.all(promises)
  } catch (e) {
    await loadScript('https://widget-v2.getclipara.com/mux.js')
  }
  return
}

export const loadVideo = async ({ campaign, setHasEnded, id }) => {
  try {
    const player = document.getElementById(id || '__clipara-player')

    player.addEventListener('ended', () => {
      setHasEnded(true)
    })
    player.addEventListener('play', () => {
      setHasEnded(false)
    })
    if (!window.Hls) {
      await loadScripts()
    }
    var src = `https://stream.mux.com/${campaign.video.playbackId}.m3u8`

    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      // Some browsers (safari and ie edge) support HLS natively
      player.src = src
    } else if (window.Hls && window.Hls.isSupported()) {
      var hls = new window.Hls()
      hls.loadSource(src)
      hls.attachMedia(player)
    } else {
      console.error("This is a legacy browser that doesn't support MSE")
    }

    if (typeof window.mux !== 'undefined') {
      window.mux.monitor('#__clipara-player', {
        debug: false,
        data: {
          env_key: '1tcqfb7f7cklfcohmo6ce3ndg',
          player_init_time: window.muxPlayerInitTime,
          player_name: 'widget',
          player_version: '1.0.0',
          video_id: campaign.video.id,
          video_title: campaign.video.name,
          video_series: campaign.id,
          video_duration: campaign.video.duration * 1000,
          video_stream_type: 'on-demand',
          device_name: deviceType,
          custom_1: '',
          custom_2: campaign.widgetId,
          custom_3: deviceType,
          sub_property_id: campaign.organisationId
        }
      })

      setTimeout(() => player.play(), 200)
    }
  } catch (e) {
    console.log(e)
  }
}

export const loadVideoLite = async ({ id, video, campaign, playerName, useClip, setHasPlayed }) => {
  try {
    const player = document.getElementById(id)
    if (!window.Hls) {
      await loadScripts()
    }
    var src = `https://stream.mux.com/${useClip && video.clipPlaybackId ? video.clipPlaybackId : video.playbackId}.m3u8${
      useClip ? '?max_resolution=720p' : ''
    }`

    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      player.src = src
    } else if (window.Hls && window.Hls.isSupported()) {
      var hls = new window.Hls()
      hls.loadSource(src)
      hls.attachMedia(player)
    } else {
      console.error("This is a legacy browser that doesn't support MSE")
    }
    if (typeof window.mux !== 'undefined') {
      window.mux.monitor(`#${id}`, {
        debug: false,
        data: {
          env_key: '1tcqfb7f7cklfcohmo6ce3ndg',
          player_init_time: window.muxPlayerInitTime,
          player_name: playerName || 'carousel',
          player_version: '1.0.0',
          video_id: useClip && video.clipPlaybackId ? video.clip_asset_id : video.id,
          video_title: video.name,
          video_series: campaign.id,
          video_duration: video.duration * 1000,
          video_stream_type: 'on-demand',
          device_name: deviceType,
          viewer_user_id: setGetCookie(),
          custom_2: campaign.widgetId,
          custom_3: deviceType,
          sub_property_id: campaign.organisationId
        }
      })
    }
    player
      .play()
      .then(() => {
        setHasPlayed && setHasPlayed(true)
      })
      .catch((e) => {
        console.log(e)
        setHasPlayed && setHasPlayed(false)
      })
  } catch (e) {
    console.log(e)
  }
}

export const loadVideoMP4 = async ({ id, video, campaign, playerName, videoWidth, useClip, setHasPlayed, useHigh }) => {
  try {
    const player = document.getElementById(id)
    try {
      player.setAttribute('oncontextmenu', 'return false;')
    } catch (e) {}
    let mediumOrLow = videoWidth > 250 ? 'medium' : 'low'

    if (videoWidth > 450 || useHigh) {
      mediumOrLow = 'high'
    }
    if (video.maxHeight && video.maxHeight < 1000) {
      mediumOrLow = 'medium'
    }
    if (video.maxHeight && video.maxHeight < 500) {
      mediumOrLow = 'low'
    }

    if (!window.mux) {
      await loadScript('https://src.litix.io/core/4/mux.js')
    }
    var src = `https://stream.mux.com/${
      video.clipPlaybackId && useClip ? video.clipPlaybackId : video.playbackId
    }/${mediumOrLow}.mp4`
    player.src = src

    if (typeof window.mux !== 'undefined') {
      window.mux.monitor(`#${id}`, {
        debug: false,
        data: {
          env_key: '1tcqfb7f7cklfcohmo6ce3ndg',
          player_init_time: window.muxPlayerInitTime,
          player_name: playerName || 'carousel',
          player_version: '1.0.0',
          video_id: useClip && video.clipPlaybackId ? video.clip_asset_id : video.id,
          video_title: video.name,
          video_series: campaign.id,
          video_duration: video.duration * 1000,
          video_stream_type: 'on-demand',
          device_name: deviceType,
          viewer_user_id: setGetCookie(),
          custom_2: campaign.widgetId,
          custom_3: deviceType,
          sub_property_id: campaign.organisationId
        }
      })
    }

    player
      .play()
      .then(() => {
        setHasPlayed && setHasPlayed(true)
      })
      .catch((e) => {
        console.log(e)
        setHasPlayed && setHasPlayed(false)
      })
  } catch (e) {
    console.log(e)
  }
}

export const playPause = (playerId, player) => {
  if (!playerId && !player) {
    return
  }
  const element = player || document.getElementById(playerId || '__clipara-player')
  if (!element.paused && !element.ended) {
    element.pause()
  } else if (element.paused) {
    element.play()
  }
}

export const loadSpecificVideo = async ({
  campaign,
  playerName,
  campaignId,
  organisationId,
  video,
  setHasEnded,
  setHasPlayed,
  playerId,
  noAutoplay,
  inputPlayer,
  widgetId,
  productIds,
  maxResolution
}) => {
  const player = inputPlayer || document.getElementById(playerId)
  player.addEventListener('ended', () => {
    setHasEnded(true)
  })
  player.addEventListener('play', () => {
    setHasEnded(false)
  })

  if (!window.Hls || !window.mux) {
    await loadScripts()
  }
  if (!player.src) {
    var src = `https://stream.mux.com/${video.playbackId}.m3u8${maxResolution ? `?max_resolution=${maxResolution}` : ''}`
    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      // Some browsers (safari and ie edge) support HLS natively
      player.src = src
    } else if (window.Hls && window.Hls.isSupported()) {
      var hls = new window.Hls()
      hls.loadSource(src)
      hls.attachMedia(player)
    } else {
      console.error("This is a legacy browser that doesn't support MSE")
    }

    if (typeof window.mux !== 'undefined') {
      setTimeout(
        () =>
          window.mux.monitor(`#${player.id || playerId}`, {
            debug: false,
            data: {
              env_key: '1tcqfb7f7cklfcohmo6ce3ndg',
              player_init_time: window.muxPlayerInitTime,
              player_name: playerName || 'widget',
              player_version: '1.0.0',
              sub_property_id: organisationId,
              video_id: video.id,
              video_title: video.name,
              video_series: campaignId,
              video_duration: video.duration * 1000,
              video_stream_type: 'on-demand',
              viewer_user_id: setGetCookie(),
              custom_1: productIds && productIds.join(','),
              custom_2: widgetId,
              custom_3: deviceType
            }
          }),
        1
      )
    }
    if (!noAutoplay) {
      try {
        player
          .play()
          .then(() => setHasPlayed(true))
          .catch((e) => {
            console.log(e)
            setHasPlayed(false)
          })
      } catch (e) {
        console.log(e)
      }
    }

    const blockAutoPlay = window && window.location.href.includes('block-clipara-auto-play')
    if (blockAutoPlay) {
      setHasPlayed(false)
    }
  }
}

export const loadSpecificVideoMP4 = async ({
  campaign,
  playerName,
  campaignId,
  organisationId,
  video,
  setHasEnded,
  setHasPlayed,
  playerId,
  noAutoplay,
  inputPlayer,
  widgetId,
  productIds,
  useHigh
}) => {
  const player = inputPlayer || document.getElementById(playerId)
  player.addEventListener('ended', () => {
    setHasEnded(true)
  })
  player.addEventListener('play', () => {
    setHasEnded(false)
  })

  try {
    player.setAttribute('oncontextmenu', 'return false;')
  } catch (e) {}

  let mediumOrLow = 'medium'

  if (useHigh) {
    mediumOrLow = 'high'
  }
  if (video.maxHeight && video.maxHeight < 1000) {
    mediumOrLow = 'medium'
  }
  if (video.maxHeight && video.maxHeight < 500) {
    mediumOrLow = 'low'
  }

  if (!window.mux) {
    await loadScript('https://src.litix.io/core/4/mux.js')
  }

  if (!player.src) {
    var src = `https://stream.mux.com/${video.playbackId}/${mediumOrLow}.mp4`
    player.src = src

    if (typeof window.mux !== 'undefined') {
      setTimeout(
        () =>
          window.mux.monitor(`#${player.id || playerId}`, {
            debug: false,
            data: {
              env_key: '1tcqfb7f7cklfcohmo6ce3ndg',
              player_init_time: window.muxPlayerInitTime,
              player_name: playerName || 'widget',
              player_version: '1.0.0',
              sub_property_id: organisationId,
              video_id: video.id,
              video_title: video.name,
              video_series: campaignId,
              video_duration: video.duration * 1000,
              video_stream_type: 'on-demand',
              viewer_user_id: setGetCookie(),
              custom_1: productIds && productIds.join(','),
              custom_2: widgetId,
              custom_3: deviceType
            }
          }),
        1
      )
    }

    if (!noAutoplay) {
      try {
        player
          .play()
          .then(() => setHasPlayed(true))
          .catch((e) => {
            console.log(e)
            setHasPlayed(false)
          })
      } catch (e) {}
    }

    const blockAutoPlay = window && window.location.href.includes('block-clipara-auto-play')
    if (blockAutoPlay) {
      setHasPlayed(false)
    }
  }
}

export const baseUrl = process.env.NODE_ENV === 'production' ? 'https://widget.getclipara.com' : ''
export const serverUrl = process.env.NODE_ENV === 'production' ? 'https://api.getclipara.com' : 'http://localhost:8080'

const toCamel = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isObject = function (obj) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

export const camelObj = function (obj) {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = camelObj(obj[k])
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return camelObj(i)
    })
  }

  return obj
}

export const formatCurrency = ({ price, currency, campaign }) => {
  if (!price || price === 0) {
    return price
  }
  if (!currency) {
    const formatted =
      campaign.shop &&
      campaign.shop.moneyFormat &&
      campaign.shop.moneyFormat
        .replace('{{amount}}', price.toFixed(2))
        .replace('{{amount_no_decimals}}', price.toFixed(2))
        .replace('{{amount_with_comma_separator}}', price.toFixed(2))
        .replace('{{amount_no_decimals_with_comma_separator}}', price.toFixed(2))
        .replace('{{amount_with_apostrophe_separator}}', price.toFixed(2))
    try {
      return formatted.split('>')[1].split('<')[0]
    } catch (e) {}
    return formatted
  }
  return new Intl.NumberFormat(undefined, { style: 'currency', currency: currency }).format(price).replace('US$', '$')
}

export const formatSeconds = (seconds) => {
  // Hours, minutes and seconds
  var hrs = ~~(seconds / 3600)
  var mins = ~~((seconds % 3600) / 60)
  var secs = ~~seconds % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()]
}

import { chain, getScrollParent, isIOS, useLayoutEffect } from '@react-aria/utils'
const visualViewport = typeof window !== 'undefined' && window.visualViewport

// HTML input types that do not cause the software keyboard to appear.
const nonTextInputTypes = new Set(['checkbox', 'radio', 'range', 'color', 'file', 'image', 'button', 'submit', 'reset'])

// The number of active usePreventScroll calls. Used to determine whether to revert back to the original page style/scroll position
let preventScrollCount = 0
let restore

export function usePreventScroll(options = {}) {
  let { isDisabled } = options

  useLayoutEffect(() => {
    if (isDisabled) {
      return
    }

    preventScrollCount++
    if (preventScrollCount === 1) {
      if (isIOS()) {
        restore = preventScrollMobileSafari()
      } else {
        restore = preventScrollStandard()
      }
    }

    return () => {
      preventScrollCount--
      if (preventScrollCount === 0) {
        restore()
      }
    }
  }, [isDisabled])
}

function preventScrollStandard() {
  return chain(
    setStyle(document.documentElement, 'paddingRight', `${window.innerWidth - document.documentElement.clientWidth}px`),
    setStyle(document.documentElement, 'overflow', 'hidden')
  )
}

function preventScrollMobileSafari() {
  let scrollable
  let lastY = 0
  let lastX = 0
  let onTouchStart = (e) => {
    // Store the nearest scrollable parent element from the element that the user touched.
    scrollable = getScrollParent(e.target)
    if (scrollable === document.documentElement && scrollable === document.body) {
      return
    }

    lastY = e.changedTouches[0].pageY
    lastX = e.changedTouches[0].pageX
  }

  let onTouchMove = (e) => {
    // Prevent scrolling the window.
    if (scrollable === document.documentElement || scrollable === document.body) {
      e.preventDefault()
      return
    }

    // Prevent scrolling up when at the top and scrolling down when at the bottom
    // of a nested scrollable area, otherwise mobile Safari will start scrolling
    // the window instead. Unfortunately, this disables bounce scrolling when at
    // the top but it's the best we can do.
    let y = e.changedTouches[0].pageY
    let x = e.changedTouches[0].pageX
    let scrollTop = scrollable.scrollTop
    let bottom = scrollable.scrollHeight - scrollable.clientHeight
    if (x === lastX) {
      if ((scrollTop <= 0 && y > lastY) || (scrollTop >= bottom && y < lastY && scrollTop != 0 && bottom != 0)) {
        e.preventDefault()
      }
    }

    lastY = y
    lastX = x
  }

  let onTouchEnd = (e) => {
    let target = e.target

    // Apply this change if we're not already focused on the target element
    if (willOpenKeyboard(target) && target !== document.activeElement) {
      e.preventDefault()

      // Apply a transform to trick Safari into thinking the input is at the top of the page
      // so it doesn't try to scroll it into view. When tapping on an input, this needs to
      // be done before the "focus" event, so we have to focus the element ourselves.
      target.style.transform = 'translateY(-2000px)'
      target.focus()
      requestAnimationFrame(() => {
        target.style.transform = ''
      })
    }
  }

  let onFocus = (e) => {
    let target = e.target
    if (willOpenKeyboard(target)) {
      // Transform also needs to be applied in the focus event in cases where focus moves
      // other than tapping on an input directly, e.g. the next/previous buttons in the
      // software keyboard. In these cases, it seems applying the transform in the focus event
      // is good enough, whereas when tapping an input, it must be done before the focus event. 🤷‍♂️
      target.style.transform = 'translateY(-2000px)'
      requestAnimationFrame(() => {
        target.style.transform = ''

        // This will have prevented the browser from scrolling the focused element into view,
        // so we need to do this ourselves in a way that doesn't cause the whole page to scroll.
        if (visualViewport) {
          if (visualViewport.height < window.innerHeight) {
            // If the keyboard is already visible, do this after one additional frame
            // to wait for the transform to be removed.
            requestAnimationFrame(() => {
              scrollIntoView(target)
            })
          } else {
            // Otherwise, wait for the visual viewport to resize before scrolling so we can
            // measure the correct position to scroll to.
            visualViewport.addEventListener('resize', () => scrollIntoView(target), { once: true })
          }
        }
      })
    }
  }

  let onWindowScroll = () => {
    // Last resort. If the window scrolled, scroll it back to the top.
    // It should always be at the top because the body will have a negative margin (see below).
    window.scrollTo(0, -1)
  }

  // Record the original scroll position so we can restore it.
  // Then apply a negative margin to the body to offset it by the scroll position. This will
  // enable us to scroll the window to the top, which is required for the rest of this to work.
  let scrollX = window.pageXOffset
  let scrollY = window.pageYOffset

  let restoreStyles = chain(
    setStyle(document.documentElement, 'paddingRight', `${window.innerWidth - document.documentElement.clientWidth}px`),
    setStyle(document.documentElement, 'overflow', 'hidden'),
    setStyle(document.body, 'marginTop', `-${scrollY}px`)
  )

  // Scroll to the top. The negative margin on the body will make this appear the same.
  window.scrollTo(0, 0)

  let removeEvents = chain(
    addEvent(document, 'touchstart', onTouchStart, { passive: false, capture: true }),
    addEvent(document, 'touchmove', onTouchMove, { passive: false, capture: true }),
    addEvent(document, 'touchend', onTouchEnd, { passive: false, capture: true }),
    addEvent(document, 'focus', onFocus, true),
    addEvent(window, 'scroll', onWindowScroll)
  )

  return () => {
    // Restore styles and scroll the page back to where it was.
    restoreStyles()
    removeEvents()
    window.scrollTo(scrollX, scrollY)
  }
}

// Sets a CSS property on an element, and returns a function to revert it to the previous value.
function setStyle(element, style, value) {
  let cur = element.style[style]
  element.style[style] = value

  return () => {
    element.style[style] = cur
  }
}

// Adds an event listener to an element, and returns a function to remove it.
function addEvent(target, event, handler, options) {
  target.addEventListener(event, handler, options)
  return () => {
    target.removeEventListener(event, handler, options)
  }
}

function scrollIntoView(target) {
  let root = document.scrollingElement || document.documentElement
  while (target && target !== root) {
    // Find the parent scrollable element and adjust the scroll position if the target is not already in view.
    let scrollable = getScrollParent(target)
    if (scrollable !== document.documentElement && scrollable !== document.body && scrollable !== target) {
      let scrollableTop = scrollable.getBoundingClientRect().top
      let targetTop = target.getBoundingClientRect().top
      if (targetTop > scrollableTop + target.clientHeight) {
        scrollable.scrollTop += targetTop - scrollableTop
      }
    }

    target = scrollable.parentElement
  }
}

function willOpenKeyboard(target) {
  return (
    (target instanceof HTMLInputElement && !nonTextInputTypes.has(target.type)) ||
    target instanceof HTMLTextAreaElement ||
    (target instanceof HTMLElement && target.isContentEditable)
  )
}

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

export const DOWNLOAD_URL_URL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:8080/video/get-download-url'
    : 'https://api.getclipara.com/video/get-download-url'
