import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout.js'
import { loadVideoLite, formatSeconds, getContainerElement, loadVideoMP4, baseUrl } from '../helpers.js'
import { trackEvent } from '../tracking.js'
import { getChevronStyling, getChevronImg, getChevronImgSize } from './chevronStyles.js'
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import InlineSlideItem from './InlineSlideItem.js'

export const FreeLink = styled.a`
  position: absolute !important;
  left: 0 !important;
  bottom: -24px !important;
`

const Alignment = styled(Flex)`
  flex-direction: column;
  align-items: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
  position: relative;
  margin-bottom: 24px;
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor, props.isStories)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
  ${(props) => (props.isSmallControls ? `width: 32px; height: 32px; min-width: 32px;` : '')}
`

const ChevronImage = styled(Flex)`
  ${(props) =>
    props.isStories
      ? `svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }`
      : ''}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }
  ${(props) => (props.isSmallControls ? `width: 16px !important; height: 16px !important; min-width: 16px !important;` : '')}
`

const LeftChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-left: 8px;'}
  ${(props) => props.hide && 'display:none;'}
`

const RightChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-right: 8px;'}
  ${(props) => props.hide && 'display:none;'}
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  word-break: break-word;
`

const getVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth / 640) * 370
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth / 338) * 500
  }
  if (dimensions === 'CIRCLE') {
    return videoWidth
  }
  // PORTRAIT
  return (videoWidth / 338) * 600
}

const getArrowWidth = (position, noArrows, isStories) => {
  if (noArrows || isStories) {
    return 0
  }
  if (position === 'BELOW') {
    return 0
  }
  if (position === 'CENTER_BELOW') {
    return 0
  }
  if (position === 'INSIDE') {
    return 0
  }
  if (position === 'OUTSIDE') {
    return 56
  }
  // OVERLAP
  return 20
}

const getDimensionsForResponsive = (campaign) => {
  const {
    videos,
    desktopVideos,
    widgetThumbnailDimensions,
    marginX,
    widgetGapWidth,
    widgetArrowPosition,
    widgetIsPartialStart,
    widgetNoMaxThumbnailWidth,
    widgetMaxThumbnailWidth
  } = campaign
  const containerElement = getContainerElement(campaign.id)
  let videosToShow
  if (desktopVideos < 5 || campaign.videos.length < 5) {
    videosToShow = 3
  } else if (desktopVideos < 7 || campaign.videos.length < 7) {
    videosToShow = 5
  } else {
    videosToShow = 7
  }

  if (widgetIsPartialStart) {
    videosToShow -= 1
  }

  const noArrows = !widgetIsPartialStart && videos.length <= desktopVideos
  const maxVideoWidth = widgetNoMaxThumbnailWidth ? 9999 : widgetMaxThumbnailWidth
  const arrowsWidth = getArrowWidth(widgetArrowPosition, noArrows, false)
  const containerWidth = containerElement && containerElement.clientWidth - (marginX ? marginX * 2 : 0)
  const gapWidth = widgetGapWidth
  const maxContainerWidth = (maxVideoWidth + gapWidth) * videosToShow - gapWidth + arrowsWidth * 2 // Minus one gap width for start/end
  const containerHeight = containerElement && containerElement.clientHeight
  const containerWidthWithoutArrows = containerWidth - arrowsWidth * 2
  const isSmallControls = containerWidth < 700
  const containerCanFitFullWidth = containerWidthWithoutArrows - maxContainerWidth > 0
  const videoWidth = containerCanFitFullWidth
    ? maxVideoWidth
    : (containerWidthWithoutArrows - widgetGapWidth * videosToShow + gapWidth) / videosToShow
  const videoHeight = getVideoHeight(widgetThumbnailDimensions, videoWidth)
  const maxContainerWidthFactoringNoArrows = maxContainerWidth - arrowsWidth
  const chevronContainerHeight = videoHeight
  const carouselContainerWidth = containerCanFitFullWidth ? `${maxContainerWidth}px` : `calc(100% - ${arrowsWidth}px)`
  const slideAdjust = widgetIsPartialStart ? (noArrows ? 0 : videoWidth / 2) : 0
  return {
    videosToShow,
    noArrows,
    maxVideoWidth,
    arrowsWidth,
    containerWidth,
    gapWidth,
    maxContainerWidth,
    containerHeight,
    containerWidthWithoutArrows,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    borderRadius: campaign.borderRadius
  }
}

const InlineCarousel = ({ campaign }) => {
  const [resize, setResize] = useState()
  const {
    videos,
    position,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    videoObjects,
    widgetArrowPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetGapWidth,
    desktopVideos,
    widgetIsPartialStart,
    widgetAutoplay
  } = campaign

  const {
    videosToShow,
    noArrows,
    arrowsWidth,
    maxContainerWidth,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    borderRadius,
    isCircle,
    isStories,
    containerWidth
  } = getDimensionsForResponsive(campaign)

  const [activeVideo, setActiveVideo] = useState(0)
  const middleVideo = (activeVideo + Math.floor(videosToShow / 2)) % campaign.videos.length
  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const [inView, setInView] = useState(false)
  const [clipLoaded, setClipLoaded] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [hasStarted, setHasStarted] = useState(false)

  const leftChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.prev()
  const rightChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.next()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: true,
    loop: true,
    initial: 0,
    renderMode: 'performance',
    slideChanged(s) {
      setActiveVideo(s.track.details.rel)
    },
    animationEnded(s) {},
    slides: {
      perView: videosToShow,
      spacing: widgetGapWidth,
      origin: widgetIsPartialStart ? -1 / videosToShow / 2 : 'auto'
    }
  })

  useEffect(() => {
    campaign.videos.forEach((v, i) => {
      if (!hasStarted && activeVideo === 0) {
        return
      }
      if (v.type !== 'VIDEO') {
        return
      }
      setIsPaused(false)
      setHasStarted(true)
      const playerId = `__clipara-inline-carousel-${v.id}-${campaign.id}`
      const element = document.getElementById(playerId)
      if (!element) {
        return
      }
      if (i === middleVideo) {
        if (element.src) {
          element.play()
          element.currentTime = 0
          element.style.opacity = '1'
          return
        } else {
          loadVideoMP4({
            id: playerId,
            video: v,
            campaign,
            playerName: 'inline-carousel-autoplay',
            useClip: false,
            videoWidth,
            useHigh: true
          })
          element.setAttribute('oncontextmenu', 'return false;')
          element.currentTime = 0
          element.style.opacity = '1'
          return
        }
      } else {
        element.pause()
        element.currentTime = 0
        element.style.opacity = '0'
        return
      }
    })
  }, [activeVideo, hasStarted])

  const slider = useRef()

  useEffect(() => {
    slider.current.addEventListener('scroll', () => {
      const newActiveVideo = Math.round(slider.current.scrollLeft / videoWidth)
      setActiveVideo(newActiveVideo)
    })
    const options = {
      root: null,
      threshold: 0.5,
      rootMargin: '400px'
    }
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setInView(true)
      } else {
        setInView(false)
      }
    }, options)
    observer.observe(slider.current)
  }, [campaign && campaign.id])

  useEffect(() => {
    if (!clipLoaded) {
      const video = campaign.videos[middleVideo]
      if (video.type !== 'VIDEO') {
        return
      }
      const playerId = `__clipara-inline-carousel-clip-${video.id}-${campaign.id}`
      loadVideoMP4({
        id: playerId,
        video,
        campaign,
        playerName: 'inline-carousel-autoplay',
        useClip: true,
        videoWidth,
        useHigh: true
      })
      const element = document.getElementById(playerId)
      element.setAttribute('oncontextmenu', 'return false;')
    }
  }, [inView])

  return (
    <>
      <Alignment position={position} ref={slider}>
        {text ? (
          <Title
            isStories={isStories}
            color={titleColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            fontFamily={widgetFontFamily}
            textAlign={textAlign}
            width={containerCanFitFullWidth ? `${maxContainerWidth + arrowsWidth}px` : '100%'}
          >
            {text}
          </Title>
        ) : null}
        <Flex
          width={containerCanFitFullWidth ? `${maxContainerWidthFactoringNoArrows + arrowsWidth}px` : '100%'}
          position="relative"
        >
          {!noArrows ? (
            <Flex
              height={chevronContainerHeight}
              alignItems="center"
              minWidth={arrowsWidth}
              width={arrowsWidth}
              display="flex !important"
            >
              <LeftChevron
                widgetChevronSecondaryColor={widgetChevronSecondaryColor}
                isStories={isStories}
                widgetButtonStyle={widgetButtonStyle}
                hide={widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW'}
                widgetArrowPosition={widgetArrowPosition}
                isSmallControls={isSmallControls}
                onClick={leftChevronOnClick}
              >
                <ChevronImage
                  isStories={isStories}
                  widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                  widgetButtonStyle={widgetButtonStyle}
                  isSmallControls={isSmallControls}
                  alt="chevron icon"
                  width={getChevronImgSize(widgetButtonStyle, isStories)}
                  height={getChevronImgSize(widgetButtonStyle, isStories)}
                >
                  {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
                </ChevronImage>
              </LeftChevron>
            </Flex>
          ) : null}
          <Box ref={sliderRef} className="keen-slider" width="900000px">
            {videos.map((video, i) => {
              return (
                <InlineSlideItem
                  isPaused={isPaused}
                  setIsPaused={setIsPaused}
                  campaign={campaign}
                  key={video.id}
                  videoHeight={videoHeight}
                  videoWidth={videoWidth}
                  activeVideoIndex={activeVideo}
                  videosToShow={videosToShow}
                  i={i}
                  isActive={middleVideo === i}
                  video={video}
                  videoObjects={videoObjects}
                  borderRadius={borderRadius}
                  isStories={isStories}
                  isCircle={isCircle}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  hasStarted={hasStarted}
                  setHasStarted={setHasStarted}
                  setActive={() => {
                    instanceRef.current?.moveToIdx((i - Math.floor(videosToShow / 2)) % campaign.videos.length)
                  }}
                />
              )
            })}
          </Box>
          {campaign.isFreeUser ? (
            <FreeLink href="https://www.getclipara.com/?utm_source=widget">
              <img width={104} height={12} src={`${baseUrl}/__clipara-powered-by.svg`} alt="powered by clipara" />
            </FreeLink>
          ) : null}
          {!noArrows ? (
            <Flex
              height={chevronContainerHeight}
              justifyContent="flex-end"
              alignItems="center"
              minWidth={arrowsWidth}
              width={arrowsWidth}
              display="flex !important"
            >
              <RightChevron
                widgetChevronSecondaryColor={widgetChevronSecondaryColor}
                isStories={isStories}
                widgetButtonStyle={widgetButtonStyle}
                hide={widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW'}
                widgetArrowPosition={widgetArrowPosition}
                isSmallControls={isSmallControls}
                onClick={rightChevronOnClick}
              >
                <ChevronImage
                  isStories={isStories}
                  widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                  widgetButtonStyle={widgetButtonStyle}
                  isSmallControls={isSmallControls}
                  alt="chevron icon"
                  width={getChevronImgSize(widgetButtonStyle, isStories)}
                  height={getChevronImgSize(widgetButtonStyle, isStories)}
                >
                  {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
                </ChevronImage>
              </RightChevron>
            </Flex>
          ) : null}

          {!noArrows && (widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW') ? (
            <Flex
              justifyContent={widgetArrowPosition === 'CENTER_BELOW' ? 'center' : 'flex-end'}
              alignItems="center"
              display="flex !important"
              position="absolute"
              bottom="-50px"
              width={carouselContainerWidth}
              maxWidth={carouselContainerWidth}
              px="8px"
            >
              <LeftChevron
                widgetChevronSecondaryColor={widgetChevronSecondaryColor}
                isStories={isStories}
                widgetButtonStyle={widgetButtonStyle}
                widgetArrowPosition={widgetArrowPosition}
                onClick={leftChevronOnClick}
                mr="8px"
              >
                <ChevronImage
                  isStories={isStories}
                  widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                  widgetButtonStyle={widgetButtonStyle}
                  isSmallControls={isSmallControls}
                  alt="chevron icon"
                  width={getChevronImgSize(widgetButtonStyle, isStories)}
                  height={getChevronImgSize(widgetButtonStyle, isStories)}
                >
                  {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
                </ChevronImage>
              </LeftChevron>
              <RightChevron
                widgetChevronSecondaryColor={widgetChevronSecondaryColor}
                isStories={isStories}
                widgetButtonStyle={widgetButtonStyle}
                widgetArrowPosition={widgetArrowPosition}
                onClick={rightChevronOnClick}
              >
                <ChevronImage
                  isStories={isStories}
                  widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                  widgetButtonStyle={widgetButtonStyle}
                  isSmallControls={isSmallControls}
                  alt="chevron icon"
                  width={getChevronImgSize(widgetButtonStyle, isStories)}
                  height={getChevronImgSize(widgetButtonStyle, isStories)}
                >
                  {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
                </ChevronImage>
              </RightChevron>
            </Flex>
          ) : null}
        </Flex>
      </Alignment>
    </>
  )
}

export default InlineCarousel
