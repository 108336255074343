import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from './ui/Layout'
import Spinner from './ui/Spinner'
import EmbedCarousel from './Components/EmbedCarousel'
import EmbedSingle from './Components/EmbedSingle'
import EmbedMobileCarousel from './Components/EmbedMobileCarousel'
import NavigationCarousel from './Components/NavigationCarousel'
import Grid from './Components/Grid'
import NavigationMobileCarousel from './Components/NavigationMobileCarousel'
import InlineMobileCarousel from './Components/InlineMobileCarousel'
import InlineCarousel from './Components/InlineCarousel'
import { playPause, baseUrl } from './helpers'
import InlineSingle from './Components/InlineSingle'

const Embed = ({ campaign, setShowFullscreenPlayer, showFullscreenPlayer, setActiveFullScreenVideo, w }) => {
  if (campaign.type === 'INLINE_CAROUSEL') {
    if (w < 700) {
      return (
        <Flex id="__clipara-embed-contents" my={`${campaign.marginY}px` || 0}>
          <InlineMobileCarousel campaign={campaign} />
        </Flex>
      )
    }
    return (
      <Flex
        id="__clipara-embed-contents"
        width={`calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={`${campaign.marginX}px` || 0}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
      >
        <InlineCarousel
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
          campaign={campaign}
        />
      </Flex>
    )
  }
  if (campaign.type === 'INLINE_SINGLE') {
    return (
      <Flex
        id="__clipara-embed-contents"
        width={w < 700 ? '100%' : `calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={w < 700 ? '0px' : `${campaign.marginX}px`}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
        alignItems="center"
      >
        <InlineSingle
          campaign={campaign}
          showFullscreenPlayer={showFullscreenPlayer}
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      </Flex>
    )
  }
  if (campaign.type === 'NAVIGATION_CAROUSEL') {
    if (w < 700) {
      return (
        <Flex id="__clipara-embed-contents" my={`${campaign.marginY}px` || 0}>
          <NavigationMobileCarousel campaign={campaign} />
        </Flex>
      )
    }
    return (
      <Flex
        id="__clipara-embed-contents"
        width={`calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={`${campaign.marginX}px` || 0}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
      >
        <NavigationCarousel
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
          campaign={campaign}
        />
      </Flex>
    )
  }
  if (campaign.type === 'NAVIGATION_GRID') {
    return (
      <Flex
        id="__clipara-embed-contents"
        width={`calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={`${campaign.marginX}px` || 0}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
      >
        <Grid
          campaign={campaign}
          isNavigation={true}
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      </Flex>
    )
  }
  if (campaign.type === 'GRID') {
    return (
      <Flex
        id="__clipara-embed-contents"
        width={`calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={`${campaign.marginX}px` || 0}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
      >
        <Grid
          campaign={campaign}
          isNavigation={false}
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      </Flex>
    )
  }
  if (campaign.type === 'CAROUSEL' || campaign.type === 'STORIES') {
    if (w < 700) {
      return (
        <Flex id="__clipara-embed-contents" my={`${campaign.marginY}px` || 0}>
          <EmbedMobileCarousel
            campaign={campaign}
            showFullscreenPlayer={showFullscreenPlayer}
            setShowFullscreenPlayer={setShowFullscreenPlayer}
            setActiveFullScreenVideo={setActiveFullScreenVideo}
          />
        </Flex>
      )
    }
    return (
      <Flex
        id="__clipara-embed-contents"
        width={`calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={`${campaign.marginX}px` || 0}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
      >
        <EmbedCarousel
          campaign={campaign}
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      </Flex>
    )
  }
  if (campaign.type === 'SINGLE_EMBED') {
    return (
      <Flex
        id="__clipara-embed-contents"
        width={w < 700 ? '100%' : `calc(100% - ${(campaign.marginX || 0) * 2}px)`}
        mx={w < 700 ? '0px' : `${campaign.marginX}px`}
        my={`${campaign.marginY}px` || 0}
        flexDirection="column"
        alignItems="center"
      >
        <EmbedSingle
          campaign={campaign}
          showFullscreenPlayer={showFullscreenPlayer}
          setShowFullscreenPlayer={setShowFullscreenPlayer}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      </Flex>
    )
  }

  return null
}

export default Embed
