import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { gaEvent, trackEvent } from '../../tracking.js'
import axios from 'axios'
import { DOWNLOAD_URL_URL, baseUrl, downloadUrlUrl, formatSeconds, getDownloadUrlUrl } from '../../helpers.js'

import Spinner, { DarkBlockSpinner } from '../../ui/Spinner.js'
import { MobileVideoDetailsPopover } from './VideoDetailsPopover.js'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 58px;
  right: 8px;
  width: 160px !important;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-sizing: border-box !important;
  cursor: pointer;
`

const ButtonComp = styled.button`
  box-shadow: 0px 0px 4px 0px #00000040;
  cursor: pointer !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border: ${(props) => props.border} !important;
  font-family: 'Inter';
  height: 40px !important;
  ${(props) => (props.widgetSearchBorderRadius === 'ROUND' ? 'border-radius: 25px !important;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SOFT' ? 'border-radius: 8px !important;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SHARP' ? 'border-radius: 0px !important;' : '')}}
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  margin-right: ${(props) => (props.isMobile ? '6px' : '8px')};
  padding-left: 17px !important;
  padding-right: 17px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit !important;
  margin-bottom: 8px !important;
  &&:focus {
    outline: inherit !important;

  }
  z-index: 1;
  p {
    color: ${(props) => props.color};
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 500 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const Button = ({ label, ...rest }) => {
  return (
    <ButtonComp {...rest}>
      <p>{label}</p>
    </ButtonComp>
  )
}

const DownloadContainer = styled(Flex)`
  right: 0px;
  bottom: 0px;
`

const PWithMargin = styled.p`
  margin-right: 10px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
`

const PWithMargin2 = styled.p`
  margin-right: 16px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
`

const MenuText = styled.p`
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100%;
`

const Span = styled.span`
  margin-left: 6px !important;
  font-weight: 400;
  color: #7a7a9d !important;
`

function downloadURI(uri, name) {
  const link = document.createElement('a')
  link.download = `${name}`
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function isWithin24Hours(isoDate) {
  const targetDate = new Date(isoDate)
  const differenceInMilliseconds = Math.abs(targetDate - Date.now())
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)
  return differenceInHours < 24
}

export const DownloadButton = ({ activeResult, setShowDetailsPopover, campaign }) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)

  const isOriginal =
    activeResult.maxHeight &&
    activeResult.aspectRatioCalc &&
    activeResult.maxHeight * activeResult.aspectRatioCalc > 1080 &&
    isWithin24Hours(activeResult.masterAccessTimestamp)
  const useMedium = activeResult.maxHeight && activeResult.maxHeight * activeResult.aspectRatioCalc < 1080 ? true : false

  let originalQuality = '2k'
  if (activeResult.maxHeight && activeResult.aspectRatioCalc && activeResult.maxHeight * activeResult.aspectRatioCalc > 2000) {
    originalQuality = '4k'
  }

  if (
    activeResult.type === 'IMAGE' ||
    !isOriginal ||
    (activeResult?.tagsArray?.includes('High Quality Download') && isWithin24Hours(activeResult.masterAccessTimestamp))
  ) {
    let downloadUrl =
      activeResult.type === 'IMAGE'
        ? `https://clipara-original.b-cdn.net${activeResult.path}`
        : `https://stream.mux.com/${activeResult.playbackId}/${useMedium ? 'medium' : 'high'}.mp4?download=${encodeURIComponent(
            activeResult.name
          )}`
    if (activeResult?.tagsArray?.includes('High Quality Download') && isWithin24Hours(activeResult.masterAccessTimestamp)) {
      downloadUrl = activeResult.masterAccessUrl
    }

    return (
      <Box position="absolute" right="0px" bottom="0px">
        <Box cursor="pointer" zIndex={1} position="absolute" left="-37px" top="6px" onClick={() => setShowDetailsPopover(true)}>
          <img width={27} height={27} src={`${baseUrl}/__clipara-info-circle-white.svg`} />
        </Box>
        <DownloadContainer
          onClick={async () => {
            setShowSpinner(true)
            try {
              gaEvent({
                isGaEnabled: campaign?.gaTracking,
                name: 'clipara_click',
                data: {
                  campaign_name: campaign?.name,
                  video_name: activeResult?.name
                }
              })
              trackEvent({
                type: 'CLICK',
                campaignId: campaign.id,
                videoId: activeResult.id,
                playerType: 'WIDGET',
                widgetId: campaign.widgetId
              })
            } catch (e) {}
            downloadURI(downloadUrl, activeResult.name)
            setTimeout(() => {
              setShowSpinner(false)
            }, 1500)
          }}
          width="auto"
          zIndex={2}
        >
          <ButtonComp color={'#425466'} backgroundColor="white" widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}>
            <Flex>
              <PWithMargin>Download</PWithMargin>
              {showSpinner ? <DarkBlockSpinner /> : <img width={17} height={17} src={`${baseUrl}/__clipara-download-grey.svg`} />}
            </Flex>
          </ButtonComp>
        </DownloadContainer>
      </Box>
    )
  }

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  const sdDownloadUrl = `https://stream.mux.com/${activeResult.playbackId}/medium.mp4?download=${encodeURIComponent(
    activeResult.name
  )}`
  const hdDownloadUrl = `https://stream.mux.com/${activeResult.playbackId}/high.mp4?download=${encodeURIComponent(
    activeResult.name
  )}`

  const downloadMp4 = (downloadUrl) => {
    setShowSpinner(true)
    try {
      gaEvent({
        isGaEnabled: campaign?.gaTracking,
        name: 'clipara_click',
        data: {
          campaign_name: campaign?.name,
          video_name: activeResult?.name
        }
      })
      trackEvent({
        type: 'CLICK',
        campaignId: campaign.id,
        videoId: activeResult.id,
        playerType: 'WIDGET',
        widgetId: campaign.widgetId
      })
    } catch (e) {}
    downloadURI(downloadUrl, activeResult.name)
    setTimeout(() => {
      setShowSpinner(false)
    }, 1500)
  }

  const downloadMaster = (downloadUrl) => {
    setShowSpinner(true)
    try {
      gaEvent({
        isGaEnabled: campaign?.gaTracking,
        name: 'clipara_click',
        data: {
          campaign_name: campaign?.name,
          video_name: activeResult?.name
        }
      })
      trackEvent({
        type: 'CLICK',
        campaignId: campaign.id,
        videoId: activeResult.id,
        playerType: 'WIDGET',
        widgetId: campaign.widgetId
      })
    } catch (e) {}
    downloadURI(activeResult.masterAccessUrl, activeResult.name)
    setTimeout(() => {
      setShowSpinner(false)
    }, 1500)
  }

  return (
    <>
      <Box position="absolute" right="0px" bottom="0px">
        <Box cursor="pointer" zIndex={1} position="absolute" left="-37px" top="6px" onClick={() => setShowDetailsPopover(true)}>
          <img width={27} height={27} src={`${baseUrl}/__clipara-info-circle-white.svg`} />
        </Box>
        <DownloadContainer
          onClick={async (e) => {
            if (activeResult.maxStoredResolution === 'SD') {
              return downloadMp4(sdDownloadUrl)
            }
            return downloadMp4(hdDownloadUrl)
          }}
          width="auto"
          zIndex={2}
        >
          <ButtonComp color={'#425466'} backgroundColor="white" widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}>
            <Flex alignItems="center">
              <PWithMargin2>Download</PWithMargin2>
              <Flex
                onClick={(e) => {
                  e.stopPropagation()
                  if (!showPopover) {
                    showPopoverFunc(e)
                  } else {
                    closeMenu(e)
                  }
                }}
                alignItems="center"
                pl="16px"
                mr="-17px"
                pr="17px"
                height="40px !important"
                borderLeft="1px solid #E2E8F0"
              >
                {showSpinner ? (
                  <DarkBlockSpinner />
                ) : (
                  <img width={17} height={17} src={`${baseUrl}/__clipara-chevron-down-grey.svg`} />
                )}
              </Flex>
            </Flex>
          </ButtonComp>
        </DownloadContainer>
        {showPopover ? (
          <Popover ref={popover}>
            <MenuText onClick={() => downloadMp4(sdDownloadUrl)}>
              SD<Span> 480p</Span>
            </MenuText>
            {activeResult.maxStoredResolution !== 'SD' ? (
              <MenuText onClick={() => downloadMp4(hdDownloadUrl)}>
                HD<Span> 1080p</Span>
              </MenuText>
            ) : null}
            {isOriginal ? (
              <MenuText onClick={downloadMaster}>
                Original<Span> {originalQuality}</Span>
              </MenuText>
            ) : null}
          </Popover>
        ) : null}
      </Box>
    </>
  )
}
