import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import FullScreenPlayer from './FullScreenPlayer'
import ShoppableProduct from './ShoppableProduct'
import { baseUrl, formatCurrency } from '../helpers'

const VideoContainer = styled(Flex)`
  width: calc(100% - 32px);
  height: calc(100vh - 32px);
  margin: 16px;
  min-height: calc(100vh - 32px);
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  position: relative;
`

const CarouselContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  overflow: hidden;
  height: auto;
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, top ease-in-out 0.3s, bottom ease-in-out 0.3s,
    left ease-in-out 0.3s, right ease-in-out 0.3s;
  top: ${(props) => props.activeVideoIndex * -100}vh;
`

const Chevron = styled(Flex)`
  width: 40px !important;
  height: 40px !important;
  img {
    width: 24px !important;
    height: 24px !important;
  }
  z-index: 110001;
  background-color: rgba(91, 91, 91, 0.4);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 12px;
  ${(props) => props.disabled && `opacity: 0.4; cursor: default;`}
`

const UpChevron = styled(Chevron)`
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
`

const DownChevron = styled(Chevron)`
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
`

const BackChevron = styled(Chevron)`
  position: absolute;
  left: 24px;
  top: 24px;
  background-color: white;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.4);
`

const ChevronContainer = styled(Flex)`
  width: 40px;
  min-width: 40px;
  margin-right: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ShoppingContainer = styled(Flex)`
  position: relative;
  padding: 16px;
  padding-left: 0px;
  width: 470px;
  min-width: 470px;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  box-sizing: border-box;
`

const ProductImage = styled.div`
  &:empty {
    display: block !important;
  }
  display: block !important;
  border-radius: 4px !important;
  margin-bottom: 8px !important;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fcfcfc;
  border: 1px solid #ebf2fa;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
`

const ItemTitle = styled.p`
  font-size: 14px !important;
  line-height: 18px !important;
  margin-bottom: 8px !important;
  color: #27272e !important;
  font-weight: 600 !important;
`

const ItemPrice = styled.p`
  font-size: 14px !important;
  line-height: 18px !important;
  color: #27272e !important;
  font-weight: 400 !important;
`
const OldPrice = styled.span`
  color: #425466;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-decoration: line-through !important;
`
const Title = styled.p`
  margin-top: 10px !important;
  color: #27272e !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  margin-bottom: 16px !important;
`
const Container = styled(Flex)`
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  background-color: white;
  overflow-y: scroll;
  flex-direction: column;
  height: 100%;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &&::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  div {
    box-sizing: border-box !important;
  }
  border-radius: 20px;
  padding: 24px;
  padding-top: 4px;
  box-sizing: border-box;
`

const Logo = styled.img`
  width: 160px;
  height: 40px;
  object-fit: contain;
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';', '') : 'inherit')} !important;
  font-weight: 700 !important;
  font-size: ${(props) => props.titleFontSize || '16'}px !important;
  line-height: ${(props) => (props.titleFontSize ? props.titleFontSize + 2 : '18')}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: 6px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  @media (max-width: 700px) {
    font-size: ${(props) => props.widgetMobileTitleFontSize || '16'}px !important;
    line-height: ${(props) => props.widgetMobileTitleFontSize || '16'}px !important;
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';', '') : 'inherit')} !important;
  font-weight: 500 !important;
  font-size: ${(props) => props.subtitleFontSize || '14'}px !important;
  line-height: ${(props) => (props.subtitleFontSize ? props.subtitleFontSize + 2 : '16')}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.subtitleColor || 'white'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
`

const Titles = ({
  hasNonShoppableButton,
  hasShoppableButtonSmall,
  hasShoppableButtonLarge,
  titleActive,
  titleFontFamily,
  titleColor,
  titleAlignment,
  subtitleColor,
  subtitleFontSize,
  subtitleActive,
  titleVideoObject
}) => {
  let paddingBottom = '10px'
  if (hasNonShoppableButton) {
    paddingBottom = '80px'
  }
  if (hasShoppableButtonSmall) {
    paddingBottom = '100px'
  }
  if (hasShoppableButtonLarge) {
    paddingBottom = '120px'
  }
  return (
    <Flex flexDirection="column" width="auto" pb={paddingBottom}>
      {titleVideoObject.title && titleActive && (
        <VideoTitle titleAlignment={titleAlignment} titleColor={titleColor} titleFontFamily={titleFontFamily}>
          {titleVideoObject.title}
        </VideoTitle>
      )}
      {titleVideoObject.subtitle && subtitleActive && (
        <VideoSubtitle
          titleAlignment={titleAlignment}
          subtitleFontSize={subtitleFontSize}
          subtitleColor={subtitleColor}
          titleFontFamily={titleFontFamily}
        >
          {titleVideoObject.subtitle}
        </VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 50px;
  flex-direction: column-reverse;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  left: 50% !important;
  transform: translate(-50%);
  border-radius: 0px 0px 8px 8px;
  box-sizing: border-box !important;
`

const ShoppableMultiProduct = ({ videoProducts, campaign, setFullScreenProduct, currency }) => {
  return (
    <Container>
      {campaign.logoUrl ? (
        <Flex pt="6px" pb="10px" justifyContent="center">
          <Logo src={campaign.logoUrl} />
        </Flex>
      ) : (
        <Title>{[5, 6, 7, 899].includes(campaign.organisationId) ? 'Comprar' : 'Shop'}</Title>
      )}
      <Grid>
        {videoProducts.map((product) => {
          const price = formatCurrency({ currency, price: product.product.price / 100, campaign })
          const compareAtPrice =
            product.product.compareAtPrice &&
            product.product.compareAtPrice > 0 &&
            product.product.compareAtPrice !== product.product.price &&
            !campaign.widgetHideCompareAtPrice
              ? formatCurrency({ currency, price: product.product.compareAtPrice / 100, campaign })
              : ''
          const image =
            product.product.media && product.product.media[0] && product.product.media[0].previewImage
              ? product.product.media[0].previewImage.src
              : ''
          return (
            <Flex
              mb="14px"
              key={product.id}
              cursor="pointer"
              flexDirection="column"
              onClick={() => {
                setFullScreenProduct(product)
              }}
            >
              <ProductImage src={image} alt={product.product.title} />
              <ItemTitle>{product.product.title}</ItemTitle>
              <ItemPrice>
                {price} {compareAtPrice ? <OldPrice>{compareAtPrice}</OldPrice> : null}
              </ItemPrice>
            </Flex>
          )
        })}
      </Grid>
    </Container>
  )
}

const VideoElement = ({ index, video, campaign, activeVideoIndex, setActiveVideoIndex, isOnlyOneVideo }) => {
  const isActive = index === activeVideoIndex
  const optimiseLoad = index > activeVideoIndex + 4 || index < activeVideoIndex - 4
  const titleVideoObject =
    campaign.videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}
  const isLandscape = video.aspectRatioCalc > 1
  const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  const videoWidth = !isLandscape ? '53.44vh' : '50vw'
  const marginBottom = isLandscape ? 'calc(50vh - 16px - (50vw * 9/16 * 1/2))' : '0'
  return (
    <VideoContainer
      index={index}
      activeVideoIndex={activeVideoIndex}
      isLandscape={video.aspectRatioCalc > 1}
      isActive={isActive}
      position="relative"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      isOnlyOneVideo={isOnlyOneVideo}
    >
      <Flex
        position="relative"
        width={`${videoWidth}`}
        maxHeight="95vh"
        height={isLandscape ? 'unset' : '95vh'}
        overflow="hidden"
        borderRadius="8px"
      >
        <FullScreenPlayer
          useFixedRatio
          moveToNextVideo={() => {
            if (index < campaign.videos.length - 1) {
              setActiveVideoIndex(index + 1)
            }
          }}
          isActive={isActive}
          playerId={`__clipara-player-carousel-${video.id}`}
          campaign={campaign}
          video={video}
          noInActiveBrightness
          optimiseLoad={optimiseLoad}
        />
        {campaign.titleActive && titleVideoObject && titleVideoObject.id && isActive ? (
          <InnerTitleContainer width={`${videoWidth}`} mb={`${marginBottom}`}>
            <Titles
              titleFontSize={campaign.titleFontSize}
              titleActive={campaign.titleActive}
              titleFontFamily={campaign.titleFontFamily}
              titleColor={campaign.titleColor}
              titleAlignment={campaign.titleAlignment}
              subtitleColor={campaign.subtitleColor}
              subtitleFontSize={campaign.subtitleFontSize}
              subtitleActive={campaign.subtitleActive}
              titleVideoObject={titleVideoObject}
            />
          </InnerTitleContainer>
        ) : null}
      </Flex>
    </VideoContainer>
  )
}

const ShoppableCarousel = ({
  campaign,
  activeFullScreenVideo,
  setActiveFullScreenVideo,
  activeVideoIndex,
  videoProducts,
  currency
}) => {
  const hasLandscapeVideo = campaign.videos.find((video) => video.aspectRatioCalc > 1)
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(activeFullScreenVideo)
  const [fullScreenProduct, setFullScreenProduct] = useState()
  const activeVideoProducts = videoProducts.filter((vp) => {
    const activeVideo = campaign.videos[carouselActiveIndex]
    return activeVideo.id.toString() === vp.videoId.toString()
  })
  const isOnlyOneVideo = campaign.videos.length === 1
  useEffect(() => {
    setCarouselActiveIndex(activeFullScreenVideo)
  }, [activeFullScreenVideo])

  useEffect(() => {
    if (activeVideoProducts.length === 1) {
      setFullScreenProduct(activeVideoProducts[0])
    } else {
      setFullScreenProduct()
    }
    try {
      campaign.videos.map((video, i) => {
        const element = document.getElementById(`__clipara-player-carousel-${video.id}`)
        if (!element || video.type !== 'VIDEO') {
          return
        }
        if (campaign.videos[carouselActiveIndex].id !== video.id) {
          element.pause()
        } else {
          if (element.src) {
            element.play()
          }
        }
        if (element.currentTime > 3) {
          element.currentTime = 0
        }
      })
    } catch (e) {
      console.log(e)
    }
  }, [carouselActiveIndex, activeFullScreenVideo, activeVideoProducts.length])
  return (
    <Flex>
      <Flex height="100vh" position="relative">
        <CarouselContainer id="__clipara-carousel" activeVideoIndex={carouselActiveIndex} isOnlyOneVideo={isOnlyOneVideo}>
          {campaign.videos.map((video, index) => {
            return (
              <VideoElement
                key={index}
                isOnlyOneVideo={isOnlyOneVideo}
                activeVideoIndex={carouselActiveIndex}
                index={index}
                video={video}
                campaign={campaign}
                setActiveVideoIndex={setCarouselActiveIndex}
              />
            )
          })}
        </CarouselContainer>
      </Flex>
      <ChevronContainer>
        <UpChevron
          disabled={activeFullScreenVideo === 0}
          hasLandscapeVideo={hasLandscapeVideo}
          onClick={() => {
            if (activeFullScreenVideo === 0) return
            setActiveFullScreenVideo(activeFullScreenVideo - 1)
          }}
        >
          <img src={`${baseUrl}/__clipara-chevron-left-white.svg`} alt="chevron icon" width="24px" height="24px" />
        </UpChevron>
        <DownChevron
          disabled={activeFullScreenVideo === campaign.videos.length - 1}
          hasLandscapeVideo={hasLandscapeVideo}
          onClick={() => {
            if (activeFullScreenVideo === campaign.videos.length - 1) return
            setActiveFullScreenVideo(activeFullScreenVideo + 1)
          }}
        >
          <img src={`${baseUrl}/__clipara-chevron-left-white.svg`} alt="chevron icon" width="24px" height="24px" />
        </DownChevron>
      </ChevronContainer>
      {activeVideoProducts && activeVideoProducts.length ? (
        <ShoppingContainer>
          {!fullScreenProduct && activeVideoProducts && activeVideoProducts.length > 1 ? (
            <ShoppableMultiProduct
              videoProducts={activeVideoProducts}
              setFullScreenProduct={setFullScreenProduct}
              campaign={campaign}
              currency={currency}
            />
          ) : null}
          {fullScreenProduct ? (
            <ShoppableProduct
              activeVideo={campaign.videos[activeFullScreenVideo]}
              close={activeVideoProducts.length > 1 ? () => setFullScreenProduct() : null}
              br={'20px'}
              product={fullScreenProduct}
              campaign={campaign}
              currency={currency}
              shadow
            />
          ) : null}
        </ShoppingContainer>
      ) : null}
    </Flex>
  )
}

export default ShoppableCarousel
