import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from './ui/Layout'
import Spinner from './ui/Spinner'
import Carousel from './Components/Carousel'
import ShoppableCarousel from './Components/ShoppableCarousel'
import MobileFullscreen from './Components/MobileFullscreenV2'
import FullScreenPlayer from './Components/FullScreenPlayer'
import { playPause, baseUrl, camelObj } from './helpers'
import { Portal } from './Portal'
import SharePopover from './Components/SharePopover'

const FullscreenContainer = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: ${(props) => props.bg || 'black'};
  font-family: ${(props) => props.fontFamily || 'Inter'} !important;
`

const CloseContainer = styled(Flex)`
  img {
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
  margin-right: 12px !important;
  width: 40px !important;
  height: 40px !important;
  background-color: rgba(91, 91, 91, 0.4);
  border-radius: 20px;
  z-index: 2147483647;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
`

export const ShareContainer = styled(CloseContainer)`
  position: relative;
  img {
    width: 106px;
    height: 18px;
  }
`

const MuteContainer = styled(CloseContainer)`
  img {
    width: 22px;
    height: 22px;
  }
`

const Logo = styled.img`
  position: absolute;
  bottom: 11px;
  left: 16px;
  width: 30% !important;
  max-width: 80px !important;
  z-index: 100002;
`

const CustomerLogo = styled.img`
  position: absolute;
  top: 11px;
  right: 16px;
  max-height: 40px;
  object-fit: contain;
  z-index: 100002;
`

const FullscreenPlayer = ({
  setShowPreview,
  setShowFullscreenPlayer,
  showFullscreenPlayer,
  hasEnded,
  closeInlinePlayer,
  showInlinePlayer,
  setShowInlinePlayer,
  campaign,
  config,
  activeFullScreenVideo,
  setActiveFullScreenVideo
}) => {
  if (!showFullscreenPlayer) {
    return null
  }
  const close = () => {
    setShowFullscreenPlayer(false)
    setShowPreview(true)
  }

  const [liveVideoProducts, setLiveVideoProducts] = useState([])
  const [currency, setCurrency] = useState()
  const [isMuted, setIsMuted] = useState(false)

  const mute = () => {
    campaign.videos.forEach((video) => {
      try {
        const element = document.getElementById(`__clipara-player-carousel-${video.id}`)
        isMuted ? (element.muted = false) : (element.muted = true)
      } catch (e) {
        console.log(e)
      }
    })
    setIsMuted(!isMuted)
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const cartRes = await fetch('/cart.json')
          .then((response) => response.json())
          .then((r) => camelObj(r))
          .catch((e) => null)

        if (cartRes && cartRes.currency) {
          setCurrency(cartRes.currency)
        }
      } catch (e) {
        console.log(e)
      }
      const productUrls = campaign.videoProducts.map((vp) => vp.productUrl)
      const res = await Promise.all(
        productUrls.map((url) =>
          fetch(url)
            .then((response) => response.json())
            .then((r) => camelObj(r))
            .catch((e) => null)
        ),
        {
          credentials: 'same-origin'
        }
      )
      const videoProducts = campaign.videoProducts
        .map((vp) => {
          return {
            ...vp,
            product: res.find((p) => p && p.id === Number(vp.productId)) || camelObj(vp.product)
          }
        })
        .filter((vp) => vp.product)
        .filter((vp) => {
          return vp.product.status !== 'draft' && vp.product.status !== 'archived'
        })
      setLiveVideoProducts(videoProducts)
    }
    if (campaign.isShoppable) {
      fetchProducts()
    }
  }, [])
  if (config.w < 700 || config.w / config.h < 0.88) {
    return (
      <MobileFullscreen
        close={close}
        campaign={campaign}
        activeFullScreenVideo={activeFullScreenVideo}
        setActiveFullScreenVideo={setActiveFullScreenVideo}
        videoProducts={liveVideoProducts}
        currency={currency}
      />
    )
  }
  if (campaign.isShoppable) {
    return (
      <FullscreenContainer
        fontFamily={campaign.fontFamily}
        id="__clipara-fullscreen-player"
        bg={campaign.fullscreenBackgroundColor || '#333333'}
      >
        {!campaign.noCliparaLogo && (
          <a target="_blank" href="https://www.getclipara.com/?utm_source=widget" alt="clipara">
            <Logo width="80px" src={`${baseUrl}/__clipara-logo.svg`} />
          </a>
        )}
        <Flex position="fixed !important" left="20px !important" top="20px !important" zIndex="2 !important">
          {campaign && !campaign.autoOpen ? (
            <CloseContainer onClick={close}>
              <img width="16px" height="16px" alt="close preview" src={`${baseUrl}/__clipara-cross-white.svg`} />
            </CloseContainer>
          ) : null}
          {campaign ? <SharePopover campaign={campaign} /> : null}
          {campaign && campaign.videos[activeFullScreenVideo]?.type === 'VIDEO' ? (
            <MuteContainer isCloseButton={!campaign.autoOpen} onClick={mute}>
              <img
                width="22px"
                height="22px"
                alt="close preview"
                src={!isMuted ? `${baseUrl}/__clipara-volume-white.svg` : `${baseUrl}/__clipara-mute-white.svg`}
              />
            </MuteContainer>
          ) : null}
        </Flex>
        <Flex alignItems="center" justifyContent="center" height="100%">
          <ShoppableCarousel
            campaign={campaign}
            currency={currency}
            videoProducts={liveVideoProducts}
            activeFullScreenVideo={activeFullScreenVideo}
            setActiveFullScreenVideo={setActiveFullScreenVideo}
          />
        </Flex>
      </FullscreenContainer>
    )
  }

  return (
    <FullscreenContainer id="__clipara-fullscreen-player" bg={campaign.fullscreenBackgroundColor || '#333333'}>
      {!campaign.noCliparaLogo && (
        <a target="_blank" href="https://www.getclipara.com/?utm_source=widget" alt="clipara">
          <Logo width="80px" alt="logo" src={`${baseUrl}/__clipara-logo.svg`} />
        </a>
      )}
      {campaign.logoUrl ? <CustomerLogo width="160px" alt="logo" src={campaign.logoUrl} /> : null}

      <Flex position="fixed !important" left="20px !important" top="20px !important" zIndex="2 !important">
        {campaign && !campaign.autoOpen ? (
          <CloseContainer onClick={close}>
            <img width="16px" height="16px" alt="close preview" src={`${baseUrl}/__clipara-cross-white.svg`} />
          </CloseContainer>
        ) : null}
        {campaign && campaign.videos[activeFullScreenVideo]?.type === 'VIDEO' ? (
          <MuteContainer isCloseButton={!campaign.autoOpen} onClick={mute}>
            <img
              width="22px"
              height="22px"
              alt="close preview"
              src={!isMuted ? `${baseUrl}/__clipara-volume-white.svg` : `${baseUrl}/__clipara-mute-white.svg`}
            />
          </MuteContainer>
        ) : null}
        {campaign &&
        campaign.organisationId !== 747 &&
        campaign.organisationId !== 2 &&
        campaign.organisationId !== 664 &&
        campaign.organisationId !== 1354 ? (
          <SharePopover campaign={campaign} />
        ) : null}
      </Flex>
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Carousel
          campaign={campaign}
          activeFullScreenVideo={activeFullScreenVideo}
          setActiveFullScreenVideo={setActiveFullScreenVideo}
        />
      </Flex>
    </FullscreenContainer>
  )
}

export default FullscreenPlayer
