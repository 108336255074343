import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Box, Container } from '../ui/Layout.js'
import { ShareContainer } from '../Fullscreen.js'
import { baseUrl } from '../helpers.js'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  width: 186px;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 16px;
  height: 135px;
  width: 504px;
  box-sizing: border-box !important;
`

const ShareTitle = styled.p`
  font-size: 18px !important;
  line-height: 18px !important;
  color: #27272e !important;
  font-weight: 600 !important;
  margin-bottom: 16px !important;
`

const Button = styled.button`
  cursor: pointer !important;
  background-color: black !important;
  border: none !important;
  font-family: 'Inter';
  height: 42px !important;
  border-radius: ${(props) => (typeof props.borderRadius === 'number' ? props.borderRadius : 8)}px !important;
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  outline: inherit;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  min-width: 112px !important;
  p {
    color: white !important;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const ShareUrl = styled.p`
  font-size: 14px !important;
  color: rgba(66, 84, 102, 1) !important;
  font-weight: 500 !important;
`

const UrlContainer = styled(Flex)`
  box-shadow: 0px 0px 2px 0px rgba(50, 50, 71, 0.2);
  border-radius: 4px;
  margin-right: 16px;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
`

const CloseContainer = styled(Flex)`
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 40px !important;
  height: 40px !important;
  align-items: center;
  justify-content: center;
  z-index: 2;
`
const Logo = styled.img`
  position: absolute;
  bottom: 4px;
  right: 16px;
`

const CliparaLink = styled.a`
  border: none !important;
  color: none !important;
`

const SharePopover = ({ campaign }) => {
  const [showPopover, setShowPopover] = useState(false)
  const [linkText, setLinkText] = useState('Copy Link')
  const popover = useRef(null)
  const close = useRef(null)

  const closeMenu = (event, override) => {
    if ((popover && popover.current && !popover.current.contains(event.target)) || override) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }
  const url = `https://${campaign.customShareDomain || 'player'}.getclipara.com/share/${campaign.id}`
  return (
    <ShareContainer isCloseButton={!campaign.autoOpen} onClick={showPopover ? closeMenu : showPopoverFunc}>
      <img width="22px" height="18px" alt="share icon" src={`${baseUrl}/__clipara-share-arrow-white.svg`} />
      {showPopover ? (
        <Popover ref={popover}>
          <CloseContainer
            ref={close}
            onClick={(e) => {
              e.stopPropagation()
              closeMenu(e, true)
            }}
          >
            <img width="12px" height="12px" alt="close preview" src={`${baseUrl}/__clipara-cross-black.svg`} />
          </CloseContainer>
          <Flex
            position="relative"
            flexDirection="column"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ShareTitle>Share</ShareTitle>
            <Flex>
              <UrlContainer>
                <ShareUrl>{url}</ShareUrl>
              </UrlContainer>
              <Button
                orderRadius={campaign.buttonBorderRadius}
                backgroundColor={campaign.buttonRgba || campaign.buttonColor}
                color={campaign.buttonFontColor}
                border={`2px solid ${campaign.buttonBorderColor}`}
                onClick={() => {
                  navigator && navigator.clipboard && navigator.clipboard.writeText(url)
                  setLinkText('Link Copied')
                  setTimeout(() => {
                    setLinkText('Copy Link')
                  }, 500)
                }}
              >
                <ShareUrl>{linkText}</ShareUrl>
              </Button>
            </Flex>
          </Flex>
          <CliparaLink target="_blank" href="https://www.getclipara.com/?utm_source=playershare">
            <Logo width={102} height={24} src={`${baseUrl}/__clipara-powered-by-inline-grey.svg`} />
          </CliparaLink>
        </Popover>
      ) : null}
    </ShareContainer>
  )
}

export default SharePopover
