import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../ui/Layout'
import ShoppableProduct from './ShoppableProduct'
import { baseUrl, formatCurrency } from '../helpers'

const ProductPill = styled(Flex)`
  box-sizing: border-box !important;
  align-items: center !important;
  background-color: rgba(78, 78, 78, 0.4) !important;
  backdrop-filter: blur(10px);
  height: 80px !important;
  border-radius: 16px !important;
  color: rgba(16, 18, 37, 1) !important;
  font-family: 'Inter';
  font-size: 16px !important;
  text-align: left !important;
  cursor: pointer;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  width: ${(props) => (props.isMultiplePills ? 'calc(100vw - 60px)' : 'calc(100vw - 20px)')} !important;
  min-width: ${(props) => (props.isMultiplePills ? 'calc(100vw - 60px)' : 'calc(100vw - 20px)')} !important;
  margin-right: ${(props) => (!props.isMultiplePills ? '0px' : '10px')};
  max-width: 500px !important;
  z-index: 110001;
  overflow: hidden;
  outline: inherit;
  padding-right: 8px !important;
  p {
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 24px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const PillsContainer = styled(Flex)`
  z-index: 110001;
  position: absolute;
  left: 0px;
  bottom: ${(props) => (props.noCliparaLogo ? '24px' : '28px')};
  overflow-x: scroll !important;
  overflow: scroll !important;
  padding-right: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const Image = styled.div`
  display: block !important;
  margin-right: 12px !important;
  width: 80px !important;
  min-width: 80px !important;
  height: 80px !important;
  border-radius: 4px 0px 0px 4px !important;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`

const OldPrice = styled.span`
  color: white;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-decoration: line-through !important;
`

const PillTitle = styled.p`
  white-space: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: white !important;
  font-weight: 600 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const PillPrice = styled.p`
  margin-top: 8px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: white !important;
  font-weight: 400 !important;
`

const ShopButton = styled.div`
  height: 30px;
  width: 60px;
  min-width: 60px;
  background-color: ${(props) => props.backgroundColor} !important;
  border-radius: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  p {
    margin: 0px !important;
    font-size: ${(props) => (props.isSpanish ? '12px' : '14px')} !important;
    line-height: 18px !important;
    color: ${(props) => props.color} !important;
    font-weight: 400 !important;
  }
`

const ShoppableMobileButtons = ({ videoProducts, campaign, setFullScreenProduct, playerId, currency }) => {
  if (!videoProducts || !videoProducts.length) {
    return null
  }
  return (
    <div>
      <PillsContainer noCliparaLogo={campaign.noCliparaLogo}>
        <Box minWidth="10px" />

        {videoProducts.map((product) => {
          const price = formatCurrency({ currency, price: product.product.price / 100, campaign })
          const compareAtPrice =
            product.product.compareAtPrice &&
            product.product.compareAtPrice > 0 &&
            product.product.compareAtPrice !== product.product.price &&
            !campaign.widgetHideCompareAtPrice
              ? formatCurrency({ currency, price: product.product.compareAtPrice / 100, campaign })
              : ''
          const image = product.product.media ? product.product.media[0].previewImage.src : ''
          const isMultiplePills = videoProducts.length > 1
          return (
            <ProductPill
              key={product.id}
              isMultiplePills={isMultiplePills}
              onClick={() => {
                document.getElementById(playerId).pause()
                setFullScreenProduct(product)
              }}
            >
              <Image src={image} alt={product.product.title} />
              <Flex flexDirection="column">
                <PillTitle>{product.product.title}</PillTitle>
                <PillPrice>
                  {price} {compareAtPrice && <OldPrice>{compareAtPrice}</OldPrice>}
                </PillPrice>
              </Flex>
              <ShopButton
                isSpanish={[5, 6, 7, 899].includes(campaign.organisationId)}
                backgroundColor={campaign.buttonRgba || campaign.buttonColor}
                color={campaign.buttonFontColor}
              >
                <p>{[5, 6, 7, 899].includes(campaign.organisationId) ? 'Comprar' : 'Shop'}</p>
              </ShopButton>
            </ProductPill>
          )
        })}
        {videoProducts.length > 1 ? <Box minWidth="10px" /> : null}
      </PillsContainer>
    </div>
  )
}

const ProductContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2147483646;
  flex-direction: column;
  display: ${(props) => (props.show ? 'flex !important;' : 'none !important;')};
  border-radius: 16px 16px 0px 0px;
  &&:empty {
    display: ${(props) => (props.show ? 'flex !important;' : 'none !important;')};
  }
`

const ShoppableFullScreen = ({
  activeVideo,
  fullScreenProduct,
  setFullScreenProduct,
  videoProducts,
  campaign,
  playerId,
  currency
}) => {
  if (!videoProducts || !videoProducts.length) {
    return null
  }
  return (
    <>
      <ProductContainer show={fullScreenProduct} id="__clipara-shoppable-product-container">
        <ShoppableProduct
          activeVideo={activeVideo}
          campaign={campaign}
          currency={currency}
          product={fullScreenProduct}
          close={() => {
            try {
              document.getElementById(playerId).play()
              setFullScreenProduct()
            } catch (e) {
              console.log(e)
            }
          }}
        />
      </ProductContainer>
    </>
  )
}

const ShoppableMobile = ({
  videoProducts,
  campaign,
  playerId,
  activeVideo,
  currency,
  fullScreenProduct,
  setFullScreenProduct,
  showProductSelect,
  setShowProductSelect
}) => {
  return (
    <>
      <ShoppableMobileButtons
        setFullScreenProduct={setFullScreenProduct}
        setShowProductSelect={setShowProductSelect}
        videoProducts={videoProducts}
        campaign={campaign}
        playerId={playerId}
        currency={currency}
      />
      <ShoppableFullScreen
        activeVideo={activeVideo}
        fullScreenProduct={fullScreenProduct}
        setFullScreenProduct={setFullScreenProduct}
        showProductSelect={showProductSelect}
        setShowProductSelect={setShowProductSelect}
        videoProducts={videoProducts}
        campaign={campaign}
        playerId={playerId}
        currency={currency}
      />
    </>
  )
}

export default ShoppableMobile
