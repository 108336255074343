import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from './ui/Layout'
import Spinner from './ui/Spinner'
import { playPause, serverUrl, baseUrl } from './helpers'

const InlineContainer = styled(Box)`
  ${(props) => `${props.LR}:0px;`}
  cursor: pointer;
  transition: width 0.3s, height 0.3s, left 0.5s, right 0.5s;
  position: absolute;
  bottom: 0px;
  background-color: black;
  border-radius: 18px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.4);
  z-index: 100000;
  ${(props) => props.previewMinimised && 'visibility: hidden;'}
`

const End = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100001;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

const Close = styled.img`
  position: absolute;
  z-index: 100002;
  height: 20px;
  width: 15px;
  top: 16px;
  right: 16px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

const FullScreen = styled.img`
  position: absolute;
  z-index: 100002;
  height: 20px;
  width: 20px;
  top: 16px;
  right: 50px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

const Player = styled.video`
  border-radius: 16px !important;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10000;
`

const Logo = styled.img`
  position: absolute;
  top: 11px;
  left: 16px;
  width: 30%;
  max-width: 90px;
  z-index: 100002;
`

const Button = styled.button`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => props.border};
  font-family: 'Inter';
  height: 42px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  width: calc(100% - 32px);
  max-width: 300px;
  z-index: 1;
  position: absolute;
  bottom: 16px;
  left: 0px;
  z-index: 100002;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  outline: inherit;
  p {
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600 !important;
    line-height: 20px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const Image = styled.img`
  border-radius: 16px !important;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10000;
`

const InlinePlayer = ({
  hasEnded,
  closeInlinePlayer,
  showInlinePlayer,
  setShowInlinePlayer,
  setShowFullscreenPlayer,
  campaign,
  config: { poster, LR, isLandscape, previewWidth, previewHeight, expandHeight, expandWidth, w },
  previewMinimised,
  setPreviewMinimised
}) => {
  const video = campaign.videos[0]
  const isVideo = video.type === 'VIDEO'
  const videoObject = campaign.videoObjects.find((el) => el.videoId === video.id && el.type === 'BUTTON')
  const buttonUrl = videoObject
    ? `${serverUrl}/campaign/click?url=${encodeURIComponent(videoObject.buttonLink)}&campaignId=${campaign.id}&type=WIDGET`
    : ''
  return (
    <InlineContainer
      previewMinimised={previewMinimised}
      showInlinePlayer={showInlinePlayer}
      onClick={() => playPause('__clipara-player')}
      id="__clipara-inline-player"
      height={!showInlinePlayer ? previewHeight : expandHeight}
      width={!showInlinePlayer ? previewWidth : expandWidth}
      LR={LR}
    >
      {showInlinePlayer ? (
        <>
          <Close
            onClick={closeInlinePlayer}
            LR={LR}
            width="15px"
            height="20px"
            alt="close preview"
            src={`${baseUrl}/__clipara-cross-white.svg`}
          />
          <FullScreen
            onClick={(e) => {
              if (isVideo) {
                document.getElementById('__clipara-player').pause()
              }
              closeInlinePlayer(e)
              setShowFullscreenPlayer(true)
            }}
            width="20px"
            height="20px"
            alt="fullscreen icon"
            src={`${baseUrl}/__clipara-fullscreen-white.svg`}
          />
          {!campaign.noCliparaLogo && <Logo alt="clipara logo" src={`${baseUrl}/__clipara-logo.svg`} />}
          {videoObject && videoObject.buttonText && videoObject.buttonLink ? (
            <a target="_blank" rel="noreferrer" href={buttonUrl}>
              <Button
                backgroundColor={campaign.buttonRgba || campaign.buttonColor}
                color={campaign.buttonFontColor}
                border={`2px solid ${campaign.buttonBorderColor}`}
              >
                <p>{videoObject.buttonText}</p>
              </Button>
            </a>
          ) : null}
        </>
      ) : null}
      {isVideo ? (
        <Player id="__clipara-player" preload="metadata" playsInline poster={poster} />
      ) : (
        <Image
          src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${Math.round(expandWidth)}x${Math.round(
            expandHeight
          )}&width=${Math.round(expandWidth * 2)}`}
        />
      )}
      {hasEnded && isVideo ? (
        <End>
          <img alt="restart icon" src={`${baseUrl}/__clipara-restart-white.svg`} />
        </End>
      ) : null}
      {isVideo ? <Spinner /> : null}
    </InlineContainer>
  )
}

export default InlinePlayer
